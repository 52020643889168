/*
==========================================================================
   #Links
========================================================================== */
%a,
.edocmanxtdlink,
a:not([class]) {
    position: relative;
    display: inline-block;
    text-decoration: none;
    @include font-size(16px, 24px);
    color: var(--color-link);
    transition: color var(--transition-time);
    z-index: 1;

    &:focus,
    &:hover {
        color: var(--color-link-hover);
        &:before {
            height: calc(100% - 2px);
            background-color: var(--color-link-decoration-hover);
        }
    }

    &:focus-visible {
        outline: var(--focus-border-width) solid var(--color-focus);
        outline-offset: var(--outline-offset);
        z-index: z-index(default);
    }

    &:before {
        content: "";
        background-color: var(--color-link-decoration);
        position: absolute;
        left: -3px;
        bottom: 1px;
        width: calc(100% + 6px);
        height: 1px;
        z-index: -1;
        transition: height 0.3s ease-in-out;
        border-radius: 2px;
    }
}
