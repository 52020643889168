.btn-hero {
    --color-btn-hero-text: #fff;
    --color-btn-hero-bg: transparent;
    --color-btn-hero-border: #fff;
    --color-btn-hero-text-hover: #fff;
    --color-btn-hero-text-disabled: #f0f1fa;
    --color-btn-hero-bg-hover: rgba(38, 9, 80, 0.6);
    --color-btn-hero-bg-focus: transparent;
    --color-btn-hero-bg-active: #260950;
    --color-btn-hero-bg-disabled: transparent;
    @extend %btn;
    position: relative;
    @include font-size(18px, 24px);
    @include font(barlow, medium);
    color: var(--color-btn-hero-text);
    background-color: var(--color-btn-hero-bg);
    padding: 18px 58px 18px 26px;
    transition: all var(--transition-time);

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100%);
        height: 100%;
        border-radius: 100px;
        border: 1.5px solid var(--color-btn-hero-border);
        box-sizing: border-box;
        transition: all var(--transition-time);
    }

    &:after {
        content: "";
        display: inline-block;
        width: 21px;
        height: 13px;
        background-image: url("./../icons/arrow-right.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 21px 13px;
        margin-left: 10px;
        transform: translateX(0);
        transition: transform var(--transition-time);
    }

    &:hover {
        color: var(--color-btn-hero-text-hover);
        background-color: var(--color-btn-hero-bg-hover);
        padding-left: 22px;
        padding-right: 62px;
        background-position: right 23px center;

        &:before {
            border-width: 3px;
        }

        &:after {
            transform: translateX(8px);
        }
    }

    &:focus {
        background-color: var(--color-btn-hero-bg-focus);
        outline-offset: 3px;
    }

    &:active {
        background-color: var(--color-btn-hero-bg-active);
    }

    &:disabled {
        color: var(--color-btn-hero-text-disabled);
        background-color: var(--color-btn-hero-bg-disabled);
        pointer-events: none;
    }
}

.btn-donate {
    --color-btn-donate-text: #fff;
    --color-btn-donate-bg: #411e75;
    --color-btn-donate-border: #411e75;
    --color-btn-donate-border-hover: #babfe2;
    --color-btn-donate-text-hover: #fff;
    --color-btn-donate-text-disabled: #f0f1fa;
    --color-btn-donate-bg-hover: #411e75;
    --color-btn-donate-bg-focus: #411e75;
    --color-btn-donate-bg-active: #260950;
    --color-btn-donate-bg-disabled: #656779;
    @extend %btn;
    position: relative;
    @include font-size(18px, 24px);
    @include font(barlow, medium);
    color: var(--color-btn-donate-text);
    background-color: var(--color-btn-donate-bg);
    padding: 18px 58px 18px 26px;
    transition: all var(--transition-time);
    min-width: 244px;
    max-width: 100%;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100%);
        height: 100%;
        border-radius: 100px;
        border: 1.5px solid var(--color-btn-donate-border);
        box-sizing: border-box;
        transition: all var(--transition-time);
    }

    &:after {
        content: "";
        display: inline-block;
        width: 21px;
        height: 13px;
        background-image: url("./../icons/arrow-right.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 21px 13px;
        margin-left: 10px;
        transform: translateX(0);
        transition: transform var(--transition-time);
    }

    &:hover {
        color: var(--color-btn-donate-text-hover);
        background-color: var(--color-btn-donate-bg-hover);
        padding-left: 22px;
        padding-right: 62px;
        background-position: right 23px center;

        &:before {
            border-width: 3px;
            border-color: var(--color-btn-donate-border-hover);
        }

        &:after {
            transform: translateX(8px);
        }
    }

    &:focus {
        background-color: var(--color-btn-donate-bg-focus);
        outline-offset: 3px;
    }

    &:active {
        background-color: var(--color-btn-donate-bg-active);
    }

    &:disabled {
        color: var(--color-btn-donate-text-disabled);
        background-color: var(--color-btn-donate-bg-disabled);
        pointer-events: none;
    }
}

.btn-back,
.btn-next {
    border: 1.5px solid var(--color-primary-1);
    color: var(--color-primary-1);
    background-color: transparent;
    @include font(barlow, reg);
    @include font-size(16px, 23px);
    background-repeat: no-repeat;
    background-size: 7px 14px;
    transition: all var(--transition-time);

    &:hover {
        background-color: var(--color-neutral-1);
        border-color: var(--color-primary-2);
        color: var(--color-primary-1);
    }

    &:active {
        background-color: var(--color-primary-1);
        border-color: var(--color-primary-1);
        color: var(--color-white);
    }

    &:focus {
        outline: 0;
    }

    &:focus-visible {
        outline: 1px solid var(--color-focus);
        outline-offset: 2px;
    }

    &[aria-disabled="true"],
    &:disabled {
        background-color: transparent;
        border-color: var(--color-neutral-5);
        color: var(--color-neutral-5);
        pointer-events: none;
    }
}

.btn-back {
    border-radius: 50px 4px 4px 50px;
    padding: 11px 16px 11px 45px;
    background-image: url("./../icons/arrow-left-violet.svg");
    background-position: left 22px center;

    &:hover {
        background-position-x: 19px;
        padding-right: 13px;
        padding-left: 48px;
    }

    &:active {
        background-image: url("./../icons/arrow-left-white.svg");
    }

    &[aria-disabled="true"],
    &:disabled {
        background-image: url("./../icons/arrow-left-disabled.svg");
    }
}

.btn-next {
    border-radius: 4px 50px 50px 4px;
    padding: 11px 45px 11px 16px;
    background-image: url("./../icons/arrow-right-violet.svg");
    background-position: right 22px center;

    &:hover {
        background-position: right 19px center;
        padding-right: 48px;
        padding-left: 13px;
    }

    &:active {
        background-image: url("./../icons/arrow-right-white.svg");
    }

    &[aria-disabled="true"],
    &:disabled {
        background-image: url("./../icons/arrow-right-disabled.svg");
    }
}
