blockquote {
    position: relative;
    margin: 24px 0 24px 16px;

    @include font-size(22px, 28px);

    @include breakpoint(mobile) {
        @include font-size(16px, 24px);
    }

    p {
        display: inline;
    }

    &:before {
        content: open-quote;
    }

    &:after {
        content: close-quote;
    }
}
