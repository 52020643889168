.menu-dropdown {
    --color-menu-text-l1: #fff;
    --color-menu-text-l2: #411e75;
    --color-menu-text-l3: #411e75;
    --color-menu-text-l4: #411e75;
    --color-menu-text-l5: #411e75;
    --color-menu-bg-l1: #411e75;
    --color-menu-bg-l2: #fff;
    --color-menu-bg-l3: #f0f1fa;
    --color-menu-bg-l4: #faf0f9;
    --color-menu-bg-l5: #efd2ee;
    --color-menu-line-l1: #d995d7;
    --color-menu-line-l2: #ad3192;
    --color-menu-line-l3: #ad3192;
    --color-menu-line-l4: #ad3192;
    --color-menu-line-l5: #ad3192;
    --color-menu-text-l1-hover: #fff;
    --color-menu-text-l2-hover: #411e75;
    --color-menu-text-l3-hover: #411e75;
    --color-menu-text-l4-hover: #411e75;
    --color-menu-text-l5-hover: #411e75;
    --color-menu-text-l1-active: #fff;
    --color-menu-text-l2-active: #ad3192;
    --color-menu-text-l3-active: #ad3192;
    --color-menu-text-l4-active: #ad3192;
    --color-menu-text-l5-active: #411e75;
    --color-menu-text-l1-focus: #fff;
    --color-menu-bg-l1-hover: #260950;
    --color-menu-bg-l2-hover: #f0f1fa;
    --color-menu-bg-l3-hover: #faf0f9;
    --color-menu-bg-l4-hover: #efd2ee;
    --color-menu-bg-l5-hover: #eac3e8;
    --color-menu-bg-l1-active: #260950;
    --color-menu-bg-l2-active: #fff;
    --color-menu-bg-l3-active: #f0f1fa;
    --color-menu-bg-l4-active: #faf0f9;
    --color-menu-bg-l5-active: #efd2ee;
    --color-menu-bg-l1-focus: #260950;
    --color-menu-bg-l2-focus: #f0f1fa;
    --color-menu-bg-l3-focus: #faf0f9;
    --color-menu-bg-l4-focus: #efd2ee;
    --color-menu-bg-l5-focus: #eac3e8;
    --color-menu-border-l1: #fff;
    --color-menu-border-l2: #411e75;
    --color-menu-border-l3: #411e75;
    --color-menu-border-l4: #ad3192;
    --color-menu-border-l5: #ad3192;
    --color-menu-border-l1-focus: #aa52ff;
    --color-menu-border-l2-focus: #aa52ff;
    --color-menu-border-l3-focus: #aa52ff;
    --color-menu-border-l4-focus: #aa52ff;
    --color-menu-border-l5-focus: #aa52ff;
    --color-menu-underline-l1: #fff;
    --color-menu-underline-l2: #ad3192;
    --color-menu-underline-l3: #ad3192;
    --color-menu-underline-l4: #ad3192;
    --color-menu-underline-l5: #411e75;
    padding-left: 0;
    width: 100%;
    margin-top: 0;
    margin-bottom: 0;

    a {
        display: block;
        text-decoration: none;
        flex: 1 0 0;
        position: relative;

        &:focus,
        &:focus-visible {
            outline: 0;
        }
    }

    ul {
        padding-left: 0;
        width: 100%;
        margin-top: 0;
        margin-bottom: 0;
    }

    li {
        display: flex;
        flex-wrap: wrap;

        &.parent ul {
            display: none;
        }

        &.active > ul {
            display: block;
        }
    }

    button {
        border-radius: 0;
    }

    .level1 {
        > a {
            background: var(--color-menu-bg-l1);
            color: var(--color-menu-text-l1);
            @include font-size(16px, 22px);
            @include font(barlow, medium);
            padding: 16px 5px 16px 16px;
            border-bottom: 1px solid var(--color-menu-border-l1);

            span {
                position: relative;

                &:after {
                    content: "";
                    display: block;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: var(--color-menu-underline-l1);
                    position: absolute;
                    top: 100%;
                    transform: scaleX(0);
                    transition: transform 0.2s;
                    transform-origin: left;
                }
            }

            &:hover {
                background: var(--color-menu-bg-l1-hover);
                color: var(--color-menu-text-l1-hover);
            }

            &:hover,
            &:focus-visible {
                &:before {
                    width: 4px;
                }
            }

            &:focus-visible {
                background-color: var(--color-menu-bg-l1-focus);
                color: var(--color-menu-text-l1-focus);
                &:after {
                    content: "";
                    height: 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -1px;
                    border: 1px solid var(--color-menu-border-l1-focus);
                    z-index: 1;
                }
            }

            &:active {
                &:before {
                    width: 2px;
                }
            }

            &.opened {
                &:before {
                    width: 6px;
                }
                span:after {
                    transform: scaleX(1);
                }
            }

            &:before {
                content: "";
                width: 0;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: var(--color-menu-line-l1);
                transition: width var(--transition-time);
                z-index: 1;
                border-radius: 0;
            }
        }

        &.current > a {
            color: var(--color-menu-text-l1-active);
            background-color: var(--color-menu-bg-l1-active);

            &:before {
                width: 6px;
            }

            span:after {
                transform: scaleX(1);
            }
        }

        .level2.last {
            &:not(.parent) {
                > a {
                    @include breakpoint(tablet) {
                        border-bottom-right-radius: 8px;
                    }
                }
            }

            &.parent {
                > .icon-indicator:not(.open) {
                    @include breakpoint(tablet) {
                        border-bottom-right-radius: 8px;
                    }
                }
            }

            .level3.last {
                &:not(.parent) {
                    > a {
                        @include breakpoint(tablet) {
                            border-bottom-right-radius: 8px;
                        }
                    }
                }

                &.parent {
                    > .icon-indicator:not(.open) {
                        @include breakpoint(tablet) {
                            border-bottom-right-radius: 8px;
                        }
                    }
                }

                .level4.last {
                    &:not(.parent) {
                        > a {
                            @include breakpoint(tablet) {
                                border-bottom-right-radius: 8px;
                            }
                        }
                    }

                    &.parent {
                        > .icon-indicator:not(.open) {
                            @include breakpoint(tablet) {
                                border-bottom-right-radius: 8px;
                            }
                        }
                    }

                    .level5.last {
                        > a {
                            @include breakpoint(tablet) {
                                border-bottom-right-radius: 8px;
                            }
                        }
                    }
                }
            }
        }

        > .icon-indicator {
            width: 54px;
            background-color: var(--color-menu-bg-l1);
            border-bottom: 1px solid var(--color-menu-border-l1);

            &:before {
                background-image: url("./../icons/arrow-right-white.svg");
            }

            &:after {
                background-color: var(--color-menu-border-l1);
            }

            &.open {
                background-color: var(--color-menu-bg-l1-active);
            }

            &:hover {
                background: var(--color-menu-bg-l1-hover);
            }

            &:hover,
            &:focus-visible,
            &:active {
                &:after {
                    width: 4px;
                    background-color: var(--color-menu-line-l1);
                }
            }

            &:active:not(.open) {
                &:before {
                    left: calc(50% + 6px);
                }
            }

            &:focus-visible {
                border: 1px solid var(--color-menu-border-l1-focus);
                outline: 0;
                background-color: var(--color-menu-bg-l1-focus);
            }
        }
    }

    .level2 {
        > a {
            background: var(--color-menu-bg-l2);
            color: var(--color-menu-text-l2);
            @include font-size(16px, 22px);
            @include font(barlow, reg);
            padding: 11px 5px 11px 16px;
            border-bottom: 1px solid var(--color-menu-border-l2);

            span {
                position: relative;

                &:after {
                    content: "";
                    display: block;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: var(--color-menu-underline-l2);
                    position: absolute;
                    top: 100%;
                    transform: scaleX(0);
                    transition: transform 0.2s;
                    transform-origin: left;
                }
            }

            &:hover {
                background: var(--color-menu-bg-l2-hover);
                color: var(--color-menu-text-l2-hover);
            }

            &:hover,
            &:focus-visible {
                &:before {
                    width: 2px;
                }
            }

            &:focus-visible {
                background-color: var(--color-menu-bg-l2-focus);
                color: var(--color-menu-text-l2-focus);

                &:after {
                    content: "";
                    height: 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -1px;
                    border: 1px solid var(--color-menu-border-l2-focus);
                    z-index: 1;
                }
            }

            &:active {
                &:before {
                    width: 4px;
                }
            }

            &.opened {
                &:before {
                    width: 4px;
                }

                span:after {
                    transform: scaleX(1);
                }
            }

            &:before {
                content: "";
                width: 0;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: var(--color-menu-line-l2);
                transition: width var(--transition-time);
                z-index: 1;
                border-radius: 0;
            }
        }

        &.current > a {
            color: var(--color-menu-text-l2-active);
            background-color: var(--color-menu-bg-l2-active);

            span:after {
                transform: scaleX(1);
            }
        }

        > .icon-indicator {
            background-color: var(--color-menu-bg-l2);
            border-bottom: 1px solid var(--color-menu-border-l2);

            &:after {
                background-color: var(--color-menu-border-l2);
            }

            &.open {
                background-color: var(--color-menu-bg-l2-active);

                &:before {
                    background-image: url("./../icons/arrow-right-fandango.svg");
                }
            }

            &:hover {
                background: var(--color-menu-bg-l2-hover);
            }

            &:hover,
            &:focus-visible,
            &:active {
                &:after {
                    width: 2px;
                    background-color: var(--color-menu-line-l2);
                }
            }

            &:active:not(.open) {
                &:before {
                    left: calc(50% + 6px);
                }
            }

            &:focus-visible {
                border: 1px solid var(--color-menu-border-l2-focus);
                outline: 0;
                background-color: var(--color-menu-bg-l2-focus);
            }
        }
    }

    .level3 {
        > a {
            background: var(--color-menu-bg-l3);
            color: var(--color-menu-text-l3);
            @include font-size(16px, 22px);
            @include font(barlow, reg);
            padding: 11px 5px 11px 24px;
            border-bottom: 1px solid var(--color-menu-border-l3);

            span {
                position: relative;

                &:after {
                    content: "";
                    display: block;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: var(--color-menu-underline-l3);
                    position: absolute;
                    top: 100%;
                    transform: scaleX(0);
                    transition: transform 0.2s;
                    transform-origin: left;
                }
            }

            &:hover {
                background: var(--color-menu-bg-l3-hover);
                color: var(--color-menu-text-l3-hover);
            }

            &:hover,
            &:focus-visible {
                &:before {
                    width: 2px;
                }
            }

            &:focus-visible {
                background-color: var(--color-menu-bg-l3-focus);
                color: var(--color-menu-text-l3-focus);

                &:after {
                    content: "";
                    height: 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -1px;
                    border: 1px solid var(--color-menu-border-l3-focus);
                    z-index: 1;
                }
            }

            &:active {
                &:before {
                    width: 4px;
                }
            }

            &.opened {
                &:before {
                    width: 4px;
                }

                span:after {
                    transform: scaleX(1);
                }
            }

            &:before {
                content: "";
                width: 0;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: var(--color-menu-line-l3);
                transition: width var(--transition-time);
                z-index: 1;
                border-radius: 0;
            }
        }

        &.current > a {
            color: var(--color-menu-text-l3-active);
            background-color: var(--color-menu-bg-l3-active);

            span:after {
                transform: scaleX(1);
            }
        }

        > .icon-indicator {
            background-color: var(--color-menu-bg-l3);
            border-bottom: 1px solid var(--color-menu-border-l3);

            &:after {
                background-color: var(--color-menu-border-l3);
            }

            &.open {
                background-color: var(--color-menu-bg-l3-active);

                &:before {
                    background-image: url("./../icons/arrow-right-fandango.svg");
                }
            }

            &:hover {
                background: var(--color-menu-bg-l3-hover);
            }

            &:hover,
            &:focus-visible,
            &:active {
                &:after {
                    width: 2px;
                    background-color: var(--color-menu-line-l3);
                }
            }

            &:active:not(.open) {
                &:before {
                    left: calc(50% + 6px);
                }
            }

            &:focus-visible {
                border: 1px solid var(--color-menu-border-l3-focus);
                outline: 0;
                background-color: var(--color-menu-bg-l3-focus);
            }
        }
    }

    .level4 {
        > a {
            background: var(--color-menu-bg-l4);
            color: var(--color-menu-text-l4);
            @include font-size(16px, 22px);
            @include font(barlow, reg);
            padding: 11px 5px 11px 32px;
            border-bottom: 1px solid var(--color-menu-border-l4);

            span {
                position: relative;

                &:after {
                    content: "";
                    display: block;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: var(--color-menu-underline-l4);
                    position: absolute;
                    top: 100%;
                    transform: scaleX(0);
                    transition: transform 0.2s;
                    transform-origin: left;
                }
            }

            &:hover {
                background: var(--color-menu-bg-l4-hover);
                color: var(--color-menu-text-l4-hover);
            }

            &:hover,
            &:focus-visible {
                &:before {
                    width: 2px;
                }
            }

            &:focus-visible {
                background-color: var(--color-menu-bg-l4-focus);
                color: var(--color-menu-text-l4-focus);

                &:after {
                    content: "";
                    height: 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -1px;
                    border: 1px solid var(--color-menu-border-l4-focus);
                    z-index: 1;
                }
            }

            &:active {
                &:before {
                    width: 4px;
                }
            }

            &.opened {
                &:before {
                    width: 4px;
                }

                span:after {
                    transform: scaleX(1);
                }
            }

            &:before {
                content: "";
                width: 0;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: var(--color-menu-line-l4);
                transition: width var(--transition-time);
                z-index: 1;
                border-radius: 0;
            }
        }

        &.current > a {
            color: var(--color-menu-text-l4-active);
            background-color: var(--color-menu-bg-l4-active);

            span:after {
                transform: scaleX(1);
            }
        }

        > .icon-indicator {
            background-color: var(--color-menu-bg-l4);
            border-bottom: 1px solid var(--color-menu-border-l4);

            &:after {
                background-color: var(--color-menu-border-l4);
            }

            &.open {
                background-color: var(--color-menu-bg-l4-active);

                &:before {
                    background-image: url("./../icons/arrow-right-fandango.svg");
                }
            }

            &:hover {
                background: var(--color-menu-bg-l4-hover);
            }

            &:hover,
            &:focus-visible,
            &:active {
                &:after {
                    width: 2px;
                    background-color: var(--color-menu-line-l4);
                }
            }

            &:active:not(.open) {
                &:before {
                    left: calc(50% + 6px);
                }
            }

            &:focus-visible {
                border: 1px solid var(--color-menu-border-l4-focus);
                outline: 0;
                background-color: var(--color-menu-bg-l4-focus);
            }
        }
    }

    .level5 {
        > a {
            background: var(--color-menu-bg-l5);
            color: var(--color-menu-text-l5);
            @include font-size(16px, 22px);
            @include font(barlow, reg);
            padding: 11px 42px;
            border-bottom: 1px solid var(--color-menu-border-l5);

            span {
                position: relative;

                &:after {
                    content: "";
                    display: block;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: var(--color-menu-underline-l5);
                    position: absolute;
                    top: 100%;
                    transform: scaleX(0);
                    transition: transform 0.2s;
                    transform-origin: left;
                }
            }

            &:hover {
                background: var(--color-menu-bg-l5-hover);
                color: var(--color-menu-text-l5-hover);
            }

            &:hover,
            &:focus-visible {
                &:before {
                    width: 2px;
                }
            }

            &:focus-visible {
                background-color: var(--color-menu-bg-l5-focus);
                color: var(--color-menu-text-l5-focus);

                &:after {
                    content: "";
                    height: 100%;
                    position: absolute;
                    left: 0;
                    right: -1px;
                    top: -1px;
                    border: 1px solid var(--color-menu-border-l5-focus);
                    z-index: 1;
                }
            }

            &:active {
                &:before {
                    width: 4px;
                }
            }

            &.opened {
                &:before {
                    width: 4px;
                }

                span:after {
                    transform: scaleX(1);
                }
            }

            &:before {
                content: "";
                width: 0;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: var(--color-menu-line-l5);
                transition: width var(--transition-time);
                z-index: 1;
                border-radius: 0;
            }
        }

        &.current > a {
            color: var(--color-menu-text-l5-active);
            background-color: var(--color-menu-bg-l5-active);

            span:after {
                transform: scaleX(1);
            }
        }

        > .icon-indicator {
            display: none;
        }

        &.last {
            @include breakpoint(mobile) {
                border-bottom-right-radius: 8px;
            }
        }
    }

    .icon-indicator {
        position: relative;
        width: 44px;
        right: 0;
        top: 0;
        padding: 0;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            width: 7px;
            height: 13px;
            display: inline-block;
            background: url("./../icons/arrow-right-violet.svg");
            background-size: contain;
            background-repeat: no-repeat;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            transition: left var(--transition-time);
        }

        &:after {
            content: "";
            width: 1px;
            height: 100%;
            position: absolute;
            left: 0;
            top: 0;
            transition: width var(--transition-time);
            z-index: 1;
            border-radius: 0;
        }

        &:focus {
            outline: 0;
        }

        &.open:before {
            transform: translateY(-50%) rotate(90deg);
        }
    }
}
