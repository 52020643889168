.block-text-img-cards {
    --spacing-block-top: 32px;
    --spacing-block-bottom: 32px;
    --spacing-block-btn: 90px;
    --gap-column: 16px;
    --spacing-img: 10px;
    --spacing-title: 10px;
    --spacing-heading: 16px;
    --spacing-btn: 20px;
    --gap-row-counter: 45px;
    --color-cfeeds-icon-bg: #fff;
    --color-cfeeds-icon-bg-hover: #dcdff3;
    --color-cfeeds-icon-border: #ad3192;
    --color-cfeeds-icon-title: #411e75;
    --color-cfeeds-icon-text: #000;
    --color-icon-container-bg: #f0f1fa;
    --color-icon-container-focus-outline: #aa52ff;
    --color-icon-container-active-outline: #ad3192;
    padding-top: var(--spacing-block-top);
    padding-bottom: var(--spacing-block-bottom);

    &__heading {
        margin-bottom: var(--spacing-heading);
        margin-top: 0;
    }

    &__btn {
        text-align: center;
        margin-top: var(--spacing-block-btn);
    }

    .cfeeds {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(212px, 1fr));
        gap: var(--gap-column);
    }

    .cfeed-item {
        display: block;
        text-decoration: none;

        &__img {
            margin-bottom: var(--spacing-img);
            width: 100%;
            height: 200px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__title {
            margin-top: 0;
            margin-bottom: var(-space-title);
        }

        &__introtext {
            margin-bottom: 0;
            @include font(barlow, reg);
            @include font-size(18px, 25px);
        }

        &__btn {
            margin-top: var(--spacing-btn);
        }

        .btn-primary {
            padding: var(--btn-padding-v) var(--btn-padding-h);
        }
    }

    &--icon {
        background-color: var(--color-icon-container-bg);
        .cfeeds {
            gap: 0;

            @include breakpoint(not-desktop) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
        .cfeed-item {
            background-color: var(--color-cfeeds-icon-bg);
            padding: 32px 24px;
            border-radius: 0;
            border: 1px solid transparent;

            &:first-child {
                border-radius: 8px 0 0 8px;
            }
            &:last-child {
                border-radius: 0 8px 8px 0;
            }

            &:not(:last-child) {
                border-right: 1px solid var(--color-cfeeds-icon-border);
            }

            @include breakpoint(not-desktop) {
                &:first-child {
                    border-radius: 8px 8px 0 0;
                }
                &:last-child {
                    border-radius: 0 0 8px 8px;
                }

                &:not(:last-child) {
                    border-bottom: 1px solid var(--color-cfeeds-icon-border);
                    border-right: none;
                }
            }

            a {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;

                &:focus {
                    text-decoration: none;
                }

                &:not([class]):focus:before {
                    background-color: transparent;
                }
            }

            &:focus {
                outline-offset: 0;
                outline-width: 2px;
                z-index: 1;
                outline-color: var(--color-icon-container-focus-outline);
                border-right: 1px solid transparent;
            }

            &:active {
                outline-offset: 0;
                z-index: 1;
                outline-color: var(--color-icon-container-active-outline);
                color: var(--color-cfeeds-icon-text);
            }

            &:hover {
                background-color: var(--color-cfeeds-icon-bg-hover);
                border-color: var(--color-cfeeds-icon-border);
                &:not(:last-child) {
                    border-right: 1px solid var(--color-cfeeds-icon-border);
                }

                .icon-title__title {
                    @include breakpoint(not-mobile) {
                        &:before {
                            width: 100%;
                            transform: scaleX(1);
                            transform-origin: bottom left;
                        }
                        &:after {
                            padding-left: 9px;
                        }
                    }
                }

                .icon-title {
                    @include breakpoint(mobile) {
                        &:before {
                            margin-left: 24px;
                            width: calc(100% - 30px);
                            transform: scaleX(1);
                            transform-origin: bottom left;
                        }
                    }
                    &:after {
                        right: 10px;
                    }
                }
            }

            &:not(.non-hover):hover {
                img {
                    clip-path: none;
                }
            }

            .icon-title {
                margin-bottom: 8px;
                &__img {
                    height: 38px;
                    width: 38px;
                    display: inline-block;
                    padding-right: 8px;
                    margin-bottom: 0;

                    @include breakpoint(mobile) {
                        height: 24px;
                        width: 24px;
                        padding-right: 4px;
                    }
                }

                img {
                    width: auto;
                    border-radius: 0;
                }

                &__title {
                    display: inline-block;
                    position: relative;
                    margin-bottom: 0;
                    color: var(--color-cfeeds-icon-title);
                    @include font(barlow, medium);
                    @include font-size(28px, 31px);
                    vertical-align: -webkit-baseline-middle;
                    @include breakpoint(mobile) {
                        @include font-size(20px, 22px);
                        vertical-align: middle;
                    }
                    &:hover {
                        color: var(--color-cfeeds-icon-title);
                    }
                    @include breakpoint(not-mobile) {
                        &:after {
                            content: url("./../icons/arrow-right-blue-violet-curved.svg");
                            vertical-align: -8px;
                            padding-left: 6px;
                        }
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        transform: scaleX(0);
                        height: 1px;
                        bottom: 0;
                        left: 0;
                        top: 100%;
                        background-color: var(--color-cfeeds-icon-title);
                        transform-origin: bottom left;
                        transition: transform 0.25s ease-out;
                    }
                }

                @include breakpoint(mobile) {
                    position: relative;
                    &:after {
                        content: url("./../icons/arrow-right-blue-violet-curved-mobile.svg");
                        position: absolute;
                        vertical-align: -3px;
                        right: 16px;
                    }

                    &:before {
                        content: "";
                        position: absolute;
                        margin-left: 24px;
                        width: calc(100% - 30px);
                        transform: scaleX(0);
                        height: 1px;
                        bottom: 0;
                        left: 0;
                        top: 100%;
                        background-color: var(--color-cfeeds-icon-title);
                        transform-origin: bottom left;
                        transition: transform 0.25s ease-out;
                    }
                }
            }

            &__introtext {
                p {
                    color: var(--color-cfeeds-icon-text);
                    margin-top: 0;
                    margin-bottom: 0;
                }
            }
        }
    }

    &--boxed {
        --spacing-btn: 24px;
        --spacing-box-h: 20px;
        --spacing-box-top: 20px;
        --spacing-box-bottom: 28px;
        --color-border: #d9d9d9;
        --spacing-block-btn: 26px;

        .cfeed-item {
            border: 1px solid var(--color-border);

            &__text-wrapper {
                padding: var(--spacing-box-top) var(--spacing-box-h)
                    var(--spacing-box-bottom);
            }

            &__btn {
                text-decoration: underline;
            }
        }

        &.blocks-4 {
            --spacing-box-h: 12px;
            .cfeed-item {
                &__img {
                    width: 100%;
                    height: 140px;
                }

                &__text-wrapper {
                    padding-left: var(--spacing-box-h);
                    padding-right: var(--spacing-box-h);
                }
            }
        }

        .block-text-img-cards__btn {
            text-align: left;
        }
    }

    &--counter {
        --spacing-block-btn: 62px;
        --spacing-heading: 44px;
        --counter-size: 50px;
        --counter-top: -28px;
        --color-counter-txt: #0c1b29;
        --color-counter-bg: #d9d9d9;

        .block-text-img-cards__desc {
            margin-bottom: 45px;
        }

        .cfeeds {
            row-gap: var(--gap-row-counter);
        }

        .cfeed-item {
            position: relative;
            &__counter {
                position: absolute;
                left: 50%;
                top: var(--counter-top);
                transform: translateX(-50%);
                width: var(--counter-size);
                aspect-ratio: 1;
                line-height: var(--counter-size);
                background-color: var(--color-counter-bg);
                color: var(--color-counter-txt);
                border-radius: 50%;
                text-align: center;
            }
        }
    }

    &--text {
        .cfeed-item {
            text-align: left;

            &__btn {
                text-decoration: underline;
            }
        }

        .block-text-img-cards__btn {
            text-align: left;
        }
    }

    &.centered {
        text-align: center;

        .block-text-img-cards__btn {
            text-align: center;
        }
    }
}
