/*
==========================================================================
   #Buttons
========================================================================== */
:root {
    --btn-border-radius: 100px;
    --btn-padding-v: 12px;
    --btn-padding-h: 31px;

    // Button clolors
    --color-btn-primary-text: #fff;
    --color-btn-primary-bg: #411e75;
    --color-btn-primary-text-hover: #fff;
    --color-btn-primary-text-disabled: #f0f1fa;
    --color-btn-primary-bg-hover: #ad3192;
    --color-btn-primary-bg-focus: #411e75;
    --color-btn-primary-bg-active: #260950;
    --color-btn-primary-bg-disabled: #656779;
    --color-btn-secondary-text: #92278f;
    --color-btn-secondary-bg: transparent;
    --color-btn-secondary-text-hover: #92278f;
    --color-btn-secondary-bg-hover: transparent;
    --color-btn-secondary-text-active: #411e75;
    --color-btn-secondary-border-active: #411e75;
    --color-btn-tertiary-text: #3a3b45;
    --color-btn-tertiary-border: #ad3192;
    --color-btn-tertiary-text-hover: #3a3b45;
    --color-btn-tertiary-border-hover: #d995d7;
}

%button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"],
%btn,
.btn {
    display: inline-block;
    max-width: 100%;
    box-sizing: border-box;
    margin: 0;
    text-align: center;
    text-decoration: none;
    cursor: pointer;
    @include font-size(16px, 20px);
    border-radius: var(--btn-border-radius);

    &:focus,
    &:hover {
        text-decoration: none;
    }

    &:focus {
        outline: var(--focus-border-width) solid var(--color-focus);
    }

    &:active {
        box-shadow: none;
    }

    a {
        display: inline-block;
        text-decoration: none;
        color: inherit;

        &:hover,
        &:focus {
            color: inherit;
        }
    }
}

%button,
%btn,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    padding: var(--btn-padding-v) var(--btn-padding-h);
    transition: none;
    background-color: var(--color-btn-primary-bg);
    color: var(--color-btn-primary-text);

    &:hover {
        color: var(--color-btn-primary-text-hover);
        background-color: var(--color-btn-primary-bg-hover);
        border-color: var(--color-btn-primary-bg-hover);
    }
}

%btn-primary,
.btn-primary {
    @extend %btn;
    @include font-size(18px, 24px);
    @include font(barlow, medium);
    color: var(--color-btn-primary-text);
    background-color: var(--color-btn-primary-bg);
    padding-left: 31px;
    padding-right: 30px;
    transition: all var(--transition-time);

    &:after {
        content: "";
        display: inline-block;
        width: 21px;
        height: 13px;
        background-image: url("./../icons/arrow-right.svg");
        background-position: center;
        background-repeat: no-repeat;
        background-size: 21px 13px;
        margin-left: 10px;
        transform: translateX(0);
        transition: transform var(--transition-time);
    }

    &:hover {
        color: var(--color-btn-primary-text-hover);
        background-color: var(--color-btn-primary-bg-hover);
        border-color: var(--color-btn-primary-bg-hover);
        padding-left: 26px;
        padding-right: 35px;

        &:after {
            transform: translateX(8px);
        }
    }

    &:focus {
        background-color: var(--color-btn-primary-bg-focus);
        outline-offset: 3px;
    }

    &:active {
        background-color: var(--color-btn-primary-bg-active);
    }

    &:disabled {
        color: var(--color-btn-primary-text-disabled);
        background-color: var(--color-btn-primary-bg-disabled);
        pointer-events: none;
    }
}

.btn-secondary {
    @include font-size(16px, 24px);
    @include font(barlow, reg);
    color: var(--color-btn-secondary-text);
    background-color: var(--color-btn-secondary-bg);
    text-decoration: none;
    padding-top: 12px;
    padding-bottom: 8px;
    position: relative;

    &:hover,
    &:focus {
        color: var(--color-btn-secondary-text-hover);

        &:after {
            padding-left: 20px;
        }

        &:before {
            height: auto;
            border-bottom: 2px solid var(--color-btn-secondary-text);
        }
    }

    &:active {
        border-color: var(--color-btn-secondary-border-active);
        color: var(--color-btn-secondary-text-active);
        border-width: 1px;

        &:after {
            content: url("./../icons/arrow-right-black.svg");
            padding-left: 14px;
        }
    }

    &:focus-visible {
        outline: 1px solid var(--color-focus);
        border-radius: 4px;
        outline-offset: 4px;
    }

    &:before {
        content: "";
        position: absolute;
        display: block;
        top: 100%;
        left: 0;
        right: 0;
        width: 100%;
        height: auto;
        border-radius: 0;
        border-bottom: 1px solid var(--color-btn-secondary-text);
        z-index: 1;
    }

    &:after {
        content: url("./../icons/arrow-right-dark-magenta.svg");
        position: relative;
        top: 1px;
        padding-left: 14px;
        transition: padding-left var(--transition-time);

        &:disabled {
            content: url("./../icons/arrow-right-light-blue.svg");
        }
    }
}

.btn-tertiary {
    position: relative;
    display: inline-block;
    text-decoration: none;
    @include font-size(16px, 24px);
    @include font(barlow, medium);
    color: var(--color-btn-tertiary-text);
    transition: color var(--transition-time);
    border-radius: 4px;
    padding-left: 3px;
    padding-right: 3px;

    &:focus,
    &:hover {
        color: var(--color-btn-tertiary-text-hover);
        &:before {
            height: 100%;
            background-color: var(--color-btn-tertiary-border-hover);
        }
    }

    &:active {
        &:before {
            height: calc(100% - 4px);
        }
    }

    &:focus-visible {
        outline: var(--focus-border-width) solid var(--color-focus);
        outline-offset: var(--outline-offset);
        z-index: z-index(default);
    }

    &:before {
        content: "";
        background-color: var(--color-btn-tertiary-border);
        position: absolute;
        left: 0;
        bottom: 1px;
        width: 100%;
        height: 1px;
        z-index: -1;
        transition: height 0.3s ease-in-out;
        border-radius: 4px;
    }
}
