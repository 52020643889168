.page-breadcrumbs {
    --color-breadcrumb: #3a3b45;
    --color-breadcrumb-active: #3a3b45;
    --color-breadcrumb-hover: #3a3b45;
    --color-breadcrumb-decoration: #ad3192;
    --spacing-block-top: 83px;
    --spacing-block-bottom: 56px;
    --font-size: 14px;
    --line-height: 21px;
    --divider-width: 5px;
    --divider-height: 9px;
    --spacing-divider: 13px;

    @include breakpoint(tablet) {
        --spacing-block-top: 72px;
        --spacing-block-bottom: 44px;
    }

    @include breakpoint(mobile) {
        --spacing-block-top: 30px;
        --spacing-block-bottom: 28px;
    }

    &.has-banner {
        --spacing-block-top: 29px;

        @include breakpoint(tablet) {
            --spacing-block-top: 24px;
        }

        @include breakpoint(mobile) {
            --spacing-block-top: 16px;
        }
    }
    margin-top: var(--spacing-block-top);
    margin-bottom: var(--spacing-block-bottom);

    .mod-breadcrumbs {
        display: flex;
        width: auto;
        padding-left: 0;
        list-style: none;
        flex-wrap: wrap;
        background-color: transparent;

        &__divider {
            display: none;
        }
    }

    li {
        &,
        a {
            @include font-size(var(--font-size), var(--line-height));
            color: var(--color-breadcrumb);
            @include font(barlow, medium);
        }

        &.active {
            color: var(--color-breadcrumb-active);
        }

        &:not(:last-of-type) {
            &:after {
                content: "";
                display: inline-block;
                vertical-align: middle;
                background-image: url("./../icons/arrow-right-violet.svg");
                background-size: var(--divider-width) var(--divider-height);
                background-repeat: no-repeat;
                width: var(--divider-width);
                height: var(--divider-height);
                margin-left: var(--spacing-divider);
                margin-right: var(--spacing-divider);
            }
        }

        &:not(:nth-last-of-type(-n + 1)) {
            @include breakpoint(mobile) {
                display: none;
            }
        }

        &:nth-last-of-type(-n + 1) {
            &:before {
                @include breakpoint(mobile) {
                    content: "";
                    display: inline-block;
                    vertical-align: middle;
                    background-image: url("./../icons/arrow-left-fandango.svg");
                    background-size: var(--divider-width) var(--divider-height);
                    background-repeat: no-repeat;
                    width: var(--divider-width);
                    height: var(--divider-height);
                    margin-right: var(--spacing-divider);
                }
            }

            &:after {
                @include breakpoint(mobile) {
                    display: none;
                }
            }
        }

        a,
        > span {
            text-decoration: none;
            position: relative;
            border-radius: 4px;
            @include font-size(16px, 24px);
            display: inline-block;
            padding-left: 3px;
            padding-right: 3px;

            &:after {
                content: "";
                background-color: var(--color-link-decoration);
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 1px;
                z-index: -1;
                transition: height 0.3s ease-in-out;
                border-radius: 4px;
            }
        }

        a {
            &:hover {
                color: var(--color-link-hover);

                &:after {
                    height: 100%;
                    background-color: var(--color-link-decoration-hover);
                }
            }

            &:active {
                &:after {
                    height: calc(100% - 2px);
                }
            }

            &:focus-visible {
                outline: var(--focus-border-width) solid var(--color-focus);
                outline-offset: var(--outline-offset);
                z-index: z-index(default);
            }
        }
    }
}
