/*
==========================================================================
   #Button menu
========================================================================== */
$burger-icon-width: 80px;
$burger-icon-height: 12px;
$burger-icon-bar-height: 2px;
$burger-icon-padding-v: 19px;
$burger-icon-padding-h: 22px;
$burger-icon-transition-time: 0.25s;
$burger-icon-bar-middle-top: (
    ($burger-icon-height - 3 * $burger-icon-bar-height) / 2
) + $burger-icon-bar-height;
$burger-icon-bar-bottom-top: ($burger-icon-height - $burger-icon-bar-height);

// Burger menu icon colors
$burger-icon-default: #fff;
$burger-icon-active: #fff;

// Button menu color

.btn-menu {
    display: none;

    @include breakpoint(not-desktop) {
        display: inline-block;
        z-index: z-index(burger-btn);
        color: var(--color-white);
        margin: 0;
        padding: 0;
        position: relative;
        width: $burger-icon-width;
        height: $burger-icon-height;
        line-height: $burger-icon-height;
        transition: $burger-icon-transition-time ease-in-out;
        cursor: pointer;
        padding-left: 17px;
        border-radius: 4px;

        &:not(.active):hover,
        &:not(.active):focus-visible {
            .icons-toggle span {
                &:nth-child(3),
                &:nth-child(4) {
                    width: 8px;
                }
            }
        }

        &:focus {
            outline: 0;
        }

        &:focus-visible {
            outline: 0;
            .icons-toggle {
                &:after {
                    content: "";
                    position: absolute;
                    display: block;
                    left: -2px;
                    right: -2px;
                    top: -4px;
                    bottom: -4px;
                    border: 1px solid var(--color-focus);
                    border-radius: 4px;
                }
            }
        }

        &:before {
            content: "";
            height: 26px;
            background-color: var(--color-white);
            width: 1px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: -7px;
        }

        .icons-toggle {
            position: absolute;
            right: 0;
            top: 0;
            width: 17px;
            height: 12px;
        }

        span {
            display: block;
            position: absolute;
            right: 0;
            height: $burger-icon-bar-height;
            width: 17px;
            opacity: 1;
            background-color: $burger-icon-default;
            transform: rotate(0);
            transition: $burger-icon-transition-time ease-in-out;
            border-radius: 8px;

            &:nth-child(1) {
                top: 0;
            }

            &:nth-child(2) {
                top: $burger-icon-bar-middle-top;
            }

            &:nth-child(3) {
                top: $burger-icon-bar-bottom-top;
            }

            &:nth-child(4) {
                top: $burger-icon-bar-bottom-top;
            }
        }

        &.active {
            span {
                background-color: $burger-icon-active;
            }

            span:nth-child(1) {
                top: $burger-icon-bar-middle-top;
                width: 0;
                left: 50%;
            }

            span:nth-child(2) {
                transform: rotate(45deg);
            }

            span:nth-child(3) {
                transform: rotate(-45deg);
                top: $burger-icon-bar-middle-top;
            }

            span:nth-child(4) {
                top: $burger-icon-bar-middle-top;
                width: 0;
                left: 50%;
            }
        }
    }
}
