.full-width-img {
    width: 100%;
    height: auto;
    margin-top: 24px;
    margin-bottom: 24px;
}

.image-round-corner {
    border-top-right-radius: 8px;
    border-top-left-radius: 8px;
    margin-top: 24px;
    margin-bottom: 24px;
}

figure.file-image {
    margin: 0;
}
