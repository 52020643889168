.block-quick-links {
    --spacing-block-top: 96px;
    --spacing-block-bottom: 96px;
    --color-link-bg: #fff;
    --color-link-bg-hover: #dcdff3;
    --color-link-bg-active: #babfe2;
    --color-link-border: #260950;
    --color-link-border-hover: #92278f;
    --color-link-outline: #aa52ff;
    --color-heading: #411e75;
    --color-link: #130527;
    //end of variables
    margin-top: var(--spacing-block-top);
    margin-bottom: var(--spacing-block-bottom);
    &__wrapper {
        @include breakpoint(desktop) {
            display: flex;
        }
    }

    &__heading {
        color: var(--color-heading);
        margin-bottom: 30px;

        @include breakpoint(mobile) {
            margin-bottom: 18px;
            max-width: 264px;
        }
    }

    &__container {
        padding-left: 57px;
        padding-right: 20px;
        @include breakpoint(desktop) {
            padding-right: 10px;
            width: calc(100vw - 589px - (100vw - 1200px) / 2);
        }

        @include breakpoint(not-desktop) {
            padding-right: var(--wrapper-padding-h);
            padding-left: var(--wrapper-padding-h);
        }
    }

    &__link {
        border-bottom: 1px solid var(--color-link-border);
        display: flex;
        justify-content: space-between;
        align-items: center;
        text-decoration: none;
        padding: 18px 24px 16px 8px;
        margin-top: 24px;
        @include font-size(22px, 24px);
        color: var(--color-link);
        border-top-left-radius: 8px;
        border-top-right-radius: 30px;
        transition: padding-left var(--transition-time);

        @include breakpoint(mobile) {
            @include font-size(16px, 19px);
            padding: 9px 10px 9px 0;
            margin-top: 20px;
        }

        &:hover,
        &:active,
        &:focus {
            border-width: 2px;
            border-color: var(--color-link-border-hover);
            padding-bottom: 15px;
            padding-left: 24px;

            @include breakpoint(mobile) {
                padding-bottom: 8px;
                padding-left: 5px;
            }
        }

        &:hover,
        &:focus {
            background-color: var(--color-link-bg-hover);

            &:after {
                transform: translateX(9px);

                @include breakpoint(mobile) {
                    transform: translateX(4px);
                }
            }
        }

        &:active {
            background-color: var(--color-link-bg-active);

            &:after {
                transform: translateX(3px);
            }
        }

        &:focus-visible {
            outline: 1px solid var(--color-link-outline);
            outline-offset: 3px;
        }

        &:after {
            content: url("./../icons/arrow-right-magneto-curved.svg");
            display: block;
            transform: translateX(0);
            transition: transform var(--transition-time);
        }
    }

    &__img {
        max-width: 589px;

        @include breakpoint(tablet) {
            max-width: 736px;
            margin-bottom: 32px;
        }

        @include breakpoint(mobile) {
            max-width: 304px;
            margin-bottom: 17px;
        }

        img {
            border-top-right-radius: 13px;
            border-bottom-right-radius: 300px;

            @include breakpoint(tablet) {
                border-bottom-right-radius: 200px;
            }

            @include breakpoint(mobile) {
                border-bottom-right-radius: 100px;
            }
        }
    }
}
