.cfeed-items {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
    margin-bottom: 35px;

    @include breakpoint(tablet) {
        grid-template-columns: repeat(2, 1fr);
    }

    @include breakpoint(mobile) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.cfeed-item {
    --color-bg: #dcdff3;
    --color-bg-hover: #f0f1fa;
    --color-badge: #fff;
    --color-title: #411e75;
    --color-subtitle: #90278e;
    --color-tag: #92278f;
    --color-desc: #000;
    display: block;
    position: relative;
    background-color: var(--color-bg);
    border-radius: 8px 80px 8px 8px;
    transition: background-color var(--transition-time);
    text-decoration: none;

    &:not(.non-hover):hover {
        background-color: var(--color-bg-hover);
        .cfeed-item {
            &__title span {
                background-size: 100% 100%;
            }

            &__bottom {
                &:after {
                    background-image: url("./../icons/arrow-right-hover-curved.svg");
                }
            }
        }
    }

    &:focus {
        outline: 1px solid var(--color-primary-2);
        filter: drop-shadow(0 4px 6px rgba(65, 30, 117, 0.2));
    }

    &__top {
        position: relative;
    }

    &__img {
        padding-right: 0;
        padding-left: 0;
        transform: translateY(0);
        transition: all var(--transition-time-slow);
        img {
            width: 100%;
            height: 260px;
            object-fit: cover;
            border-top-right-radius: 80px;
            border-top-left-radius: 8px;
            clip-path: polygon(
                0 0, 0 100%, 0.06% 100%, 0.4% 100%, 1.01% 100%, 1.84% 100%, 2.3% 100%, 42.24% 100%, 42.59% 100%, 42.93% 100%, 50.11% 100%, 50.83% 100%, 51.52% 100%, 58.71% 100%, 59.05% 100%, 59.4% 100%, 97.7% 100%, 98.16% 100%, 98.99% 100%, 99.6% 100%, 99.94% 100%, 100% 100%, 100% 0
            );
            transition: all var(--transition-time-slow);
        }

        &.small {
            img {
                height: 206px;
            }
        }
    }

    &__badge {
        background: linear-gradient(268.75deg, #411e75 -54%, #90278e 33.08%),
            #90278e;
        border-radius: 0 100px 100px 0;
        position: absolute;
        bottom: 9px;
        left: 0;
        padding: 8px 26px;
        color: var(--color-badge);
        max-width: 280px;
        letter-spacing: -0.1px;
        line-height: 24px;

        &.has-icon {
            padding-left: 57px;
            background-repeat: no-repeat;
            background-position: left 26px center;
        }

        &.clinician {
            background-image: url("./../icons/clinician.svg");
        }
        &.researcher {
            background-image: url("./../icons/researcher.svg");
        }
        &.nurse {
            background-image: url("./../icons/nurse.svg");
        }
        &.allied-health {
            background-image: url("./../icons/allied-health.svg");
        }
        &.executive {
            background-image: url("./../icons/executive.svg");
        }
        &.board {
            background-image: url("./../icons/board.svg");
        }
        &.corporate {
            background-image: url("./../icons/corporate.svg");
        }
        &.clinician-researcher {
            background-image: url("./../icons/clinician-researcher.svg");
        }
        &.committee-member {
            background-image: url("./../icons/committee-member.svg");
        }
    }

    &__play-icon {
        background-image: url("./../icons/play-circle.svg");
        width: 73px;
        height: 73px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: z-index(default);
    }

    &__bottom {
        display: block;
        position: relative;
        padding: 42px 32px 64px;
        text-decoration: none;

        @include breakpoint(tablet) {
            padding-left: 24px;
            padding-right: 24px;
        }

        @include breakpoint(mobile) {
            padding-left: 16px;
            padding-right: 16px;
            padding-bottom: 48px;
        }

        &.small {
            padding-top: 35px;
        }

        &:after {
            display: block;
            content: "";
            background-image: url("./../icons/arrow-right-purple-curved.svg");
            position: absolute;
            bottom: 16px;
            width: 32px;
            height: 32px;
            background-size: contain;
            background-repeat: no-repeat;

            @include breakpoint(not-desktop) {
                width: 24px;
                height: 24px;
            }
        }
    }

    &__title {
        color: var(--color-title);

        span {
            width: 100%;
            background-image: linear-gradient(
                transparent calc(100% - 1px), currentColor 1px
            );
            background-repeat: no-repeat;
            background-size: 0% 100%;
            transition: background-size var(--transition-time);
        }
    }

    &__subtitle {
        color: var(--color-subtitle);
        @include breakpoint(mobile) {
            @include font-size(16px, 24px);
            @include font(barlow, medium);
        }

        &.bold {
            @include breakpoint(mobile) {
                @include font(barlow, medium);
            }
        }
    }

    &__tag {
        @extend .h5;
        color: var(--color-tag);
    }

    &__desc {
        color: var(--color-desc);

        .read-more {
            color: var(--color-primary-1);
        }
    }

    &__readtime {
        margin-top: 16px;
    }
}

a.cfeed-item:not(.non-hover):hover {
    .cfeed-item {
        &__img {
            padding-right: 6px;
            padding-left: 6px;
            transform: translateY(6px);

            img {
                border-top-right-radius: 75px;
            }
        }
    }
    img {
        clip-path: polygon(
            0 0, 0 91.79%, 0.06% 92.55%, 0.4% 93.92%, 1.01% 94.91%, 1.84% 95.47%, 2.3% 95.57%, 42.24% 95.57%, 42.59% 95.61%, 42.93% 95.75%, 50.11% 100.28%, 50.83% 100.47%, 51.52% 100.28%, 58.71% 95.75%, 59.05% 95.61%, 59.4% 95.57%, 97.7% 95.57%, 98.16% 95.47%, 98.99% 94.91%, 99.6% 93.92%, 99.94% 92.55%, 100% 91.79%, 100% 0
        );
    }
}
