.skip-links__link {
    --color-skip-bg: #260950;
    --color-skip-text: #fff;
    --color-btn-border: #fff;
    display: block;
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    top: -1000px;
    left: -1000px;
    @include font(barlow, bold);
    @include font-size(18px, 24px);
    padding: 16px 0;
    text-align: center;
    background: var(--color-skip-bg);

    &:focus {
        position: static;
        width: auto;
        height: auto;
        overflow: visible;
        text-decoration: none;
        outline: 0;
    }

    .btn-primary {
        padding: 12px 58px 12px 26px;
        border: 1.5px solid var(--color-btn-border);

        @include breakpoint(mobile) {
            width: 100%;
        }
    }

    @include hover(var(--color-skip-text));
}
