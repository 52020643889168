.btn-back-top {
    --color-btn-text: #411e75;
    --color-btn-text-active: #fff;
    --color-btn-bg: #fff;
    --color-btn-bg-hover: #dcdff3;
    --color-btn-bg-active: #411e75;
    --color-btn-border: #411e75;
    --color-btn-border-hover: #ad3192;
    --color-btn-border-active: #411e75;
    --color-btn-outline-focus: #aa52ff;
    display: block;
    width: fit-content;
    @include font-size(16px, 24px);
    color: var(--color-btn-text);
    background-color: var(--color-btn-bg);
    border: 1.5px solid var(--color-btn-border);
    transition: var(--transition-time) all;
    border-radius: 12px 12px 4px 4px;
    padding: 11px 28px 10px 23px;
    margin-left: auto;
    margin-bottom: 32px;
    margin-top: 64px;
    text-decoration: none;

    @include breakpoint(tablet) {
        margin-bottom: 48px;
        margin-top: 48px;
    }

    @include breakpoint(mobile) {
        margin-bottom: 32px;
        margin-top: 32px;
        width: 100%;
        text-align: center;
    }

    &:after {
        content: url("./../icons/arrow-up-purple.svg");
        display: inline-block;
        margin-left: 14px;
        text-decoration: none;
        transform: translateY(0);
        transition: var(--transition-time) all;
    }

    &:hover {
        background-color: var(--color-btn-bg-hover);
        border-color: var(--color-btn-border-hover);

        &:after {
            transform: translateY(-2px);
        }
    }

    &:active {
        color: var(--color-btn-text-active);
        background-color: var(--color-btn-bg-active);
        border-color: var(--color-btn-border-active);

        &:after {
            content: url("./../icons/arrow-up-white.svg");
            transform: translateY(0);
        }
    }

    &:focus-visible {
        outline: 1px solid var(--color-btn-outline-focus);
    }
}
