.slider-pagination {
    display: flex;
    align-items: center;

    @include breakpoint(mobile) {
        justify-content: space-between;
    }

    .slider-counter {
        @include font-size(20px, 30px);
        color: var(--color-primary-1);
        min-width: 65px;
        margin-left: 32px;
        margin-right: 32px;
        text-align: center;

        @include breakpoint(mobile) {
            margin-left: 5px;
            margin-right: 5px;
        }
    }

    .btn-back,
    .btn-next {
        @include breakpoint(mobile) {
            height: 48px;
        }
    }
}
