.event-item {
    --color-date: #92278f;
    --color-divider: #656779;
    --color-border: #411e75;
    --color-heading: #411e75;

    .divider {
        color: var(--color-divider);
        padding-left: 8px;
        padding-right: 8px;
    }

    .date-time-top {
        padding-bottom: 18px;

        .date {
            display: inline-block;
            & > span {
                color: var(--color-date);
            }

            &:before {
                content: "";
                width: 20px;
                height: 20px;
                display: inline-block;
                background-image: url("./../icons/calendar.svg");
                background-size: 19px;
                background-position: center;
                background-repeat: no-repeat;
                vertical-align: text-top;
            }
        }

        .time {
            display: inline-block;
        }
    }

    .event-details {
        h3 {
            color: var(--color-heading);
            margin-bottom: 24px;
        }
        h4 {
            color: var(--color-heading);
            @include font(barlow, semibold);
            margin-bottom: 8px;
        }
        &__loc-and-time {
            display: flex;
            @include breakpoint(mobile) {
                display: block;

                .location {
                    padding-bottom: 24px;
                }
            }
            padding-bottom: 20px;

            .location {
                width: 100%;

                &__location {
                    @include font(barlow, medium);
                }

                &__contact {
                    padding-top: 8px;
                }

                &__url {
                    display: inline-block;
                }
            }

            .date-time {
                width: 100%;

                &__datetime {
                    @include font(barlow, medium);
                }

                .event-label {
                    @include font(barlow, medium);
                }

                .next-event,
                .recurring-event {
                    padding-top: 10px;
                }
            }
        }

        .event-description {
            img {
                border-radius: 8px 8px 0 0;
            }
        }
    }

    .separator-one {
        margin-top: 24px;
        margin-bottom: 24px;
        border-top: 1px solid var(--color-border);
    }
    .separator-two {
        margin-top: 32px;
        margin-bottom: 24px;
        border-top: 1px solid var(--color-border);
    }
}

.events-list {
    .pagination {
        position: relative;

        @include breakpoint(mobile) {
            flex-direction: column;
        }

        .past-events {
            @include breakpoint(mobile) {
                margin-top: 20px;
            }
        }

        nav {
            margin-left: auto;
            margin-right: auto;

            @include breakpoint(mobile) {
                order: -1;
            }
        }
    }
}
