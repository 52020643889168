/*
==========================================================================
   #Slider
========================================================================== */
:root {
    --accordion-transition-time: 0.5s;
    --accordion-border-width: 1px;
    --accordion-arrow-width: 32px;
    --accordion-arrow-border-width: 0;
    --accordion-title-padding-v: 7px;
    --accordion-title-padding-mobile-v: 7px;
    --accordion-title-padding-h: 0;
    --accordion-body-padding-v: 16px;
    --accordion-body-padding-h: 16px;
    --accordion-item-margin-v: 18px;

    // Accordion colors
    --color-accordion-border: #411e75;
    --color-accordion-bg: transparent;
    --color-accordion-text: #411e75;
    --color-accordion-focus-text: #411e75;
    --color-accordion-focus-bg: transparent;
    --color-accordion-arrow-border-left: #411e75;
    --color-accordion-arrow: #fff;
    --color-accordion-arrow-bg: #dcdff3;
    --color-accordion-body-bg: #f0f1fa;
    --color-accordion-body-text: #130527;
    --color-accordion-content-border-top: #411e75;
}

.accordion-item {
    border-top: var(--accordion-border-width) solid
        var(--color-accordion-border);
    border-bottom: var(--accordion-border-width) solid
        var(--color-accordion-border);
    border-right: none;
    border-left: none;
    margin: var(--accordion-item-margin-v) 0;

    & ~ .accordion-item {
        border-top: 0 solid var(--color-accordion-border);
    }

    &__title-container {
        position: relative;
        display: block;
        background-color: var(--color-accordion-bg);
        text-decoration: none;
        color: var(--color-accordion-text);
        font-weight: bold;
        padding: var(--accordion-title-padding-v)
            var(--accordion-title-padding-h);
        transition: background-color var(--transition-time);
        @include font(barlow, reg);
        @include font-size(18px, 18px);
        margin-top: 16px;
        margin-bottom: 16px;
        padding-right: 32px;
        border-radius: 4px;

        @include breakpoint(mobile) {
            margin-top: 13px;
            margin-bottom: 12px;
            padding-top: var(--accordion-title-padding-mobile-v);
            padding-bottom: var(--accordion-title-padding-mobile-v);
        }

        &:focus-visible {
            outline: var(--focus-border-width) solid var(--color-focus);
            outline-offset: 3px;
        }

        &:hover {
            .accordion-item__arrow {
                background-color: var(--color-accordion-arrow-bg);
                border-radius: 50%;
            }
        }

        @include hover(
            var(--color-accordion-focus-text),
            var(--color-accordion-focus-bg)
        );
    }

    .accordion-item__title-container--toggle-on {
        .accordion-item__title {
            @include font(barlow, semibold);
        }
        .accordion-item__arrow {
            background-color: var(--color-accordion-arrow-bg);
            border-radius: 50%;
        }
    }

    &__arrow {
        position: absolute;
        width: var(--accordion-arrow-width);
        height: 32px;
        right: 0;
        top: 0;
        bottom: 0;
        margin: auto;
        border-left: var(--accordion-arrow-border-width) solid
            var(--color-accordion-arrow-border-left);
        @include font-size(10px, 10px);
        text-align: center;

        &:before {
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            margin: auto;
            transform: translateY(-50%);
            transition: transform var(--accordion-transition-time);
            color: var(--color-accordion-arrow);
            content: url("./../icons/arrow-down-fandango.svg");

            @include breakpoint(mobile) {
                content: url("./../icons/arrow-right-fandango.svg");
            }

            .accordion-item__title-container--toggle-on & {
                transform: translateY(-50%) rotate(180deg);
                transition: transform var(--accordion-transition-time);

                @include breakpoint(mobile) {
                    transform: translateY(-50%) rotate(90deg);
                }
            }
        }
    }

    &__body-wrapper {
        background-color: var(--color-accordion-body-bg);
        margin-bottom: 16px;
        padding: var(--accordion-body-padding-v) var(--accordion-body-padding-h);
        @include font(barlow, reg);
        @include font-size(16px, 22px);
        color: var(--color-accordion-body-text);
        border-radius: 8px;
        border-top: none;
    }
}
