.mod-list {
    --color-mod-heading: #411e75;
    --color-title: #000;
    --color-border: #9897cf;
    list-style: none;
    padding-left: 0;
    margin-bottom: 32px;

    @include breakpoint(tablet) {
        margin-bottom: 24px;
    }

    &__heading {
        color: var(--color-primary-1);
        margin-bottom: 8px;
    }

    &__item {
        display: flex;
        text-decoration: none;
        border-bottom: 1px solid var(--color-border);
        padding-top: 16px;
        padding-bottom: 16px;
        margin-bottom: 16px;
        color: var(--color-title);

        &:after {
            content: "";
            background-image: url("./../icons/arrow-right-magneto-curved.svg");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: right center;
            display: block;
            transform: translateX(0);
            transition: transform var(--transition-time);
            margin-left: auto;
            width: 19px;
            height: 19px;
            flex-shrink: 0;
            padding-left: 18px;
        }

        &:hover,
        &:focus {
            text-decoration: underline;
            text-decoration-color: var(--color-title);
            &:after {
                transform: translateX(9px);

                @include breakpoint(mobile) {
                    transform: translateX(4px);
                }
            }
        }
    }

    &__item-img {
        width: 64px;
        height: 64px;
        margin-right: 16px;
        flex-shrink: 0;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-radius: 8px;
        }
    }

    &__item-title {
        color: var(--color-title);
    }
}
