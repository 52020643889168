/*
==========================================================================
   #Error Page
========================================================================== */

.error-page {
    --color-text: #fff;
    background-image: url("./../img/error-page-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    &:after,
    &:hover:after {
        content: "";
        display: block;
        bottom: -150px;
        right: -275px;
        background: url("./../img/error-page-image-bg.png");
        background-repeat: no-repeat;
        background-position: 100% bottom;
        z-index: -1;

        @include breakpoint(tablet) {
            bottom: 0;
            right: 0;
            background: url("./../img/error-page-image-bg-tablet.png");
            background-repeat: no-repeat;
            background-position: 100% bottom;
        }

        @include breakpoint(mobile) {
            bottom: -200px;
            right: -400px;
            background: url("./../img/error-page-image-bg-mobile.png");
            background-repeat: no-repeat;
            background-position: 100% bottom;
        }
    }

    &__body {
        padding-top: 271px;
        padding-left: 170px;

        @include breakpoint(tablet) {
            padding-left: 0;
            padding-right: 0;
        }
        @include breakpoint(mobile) {
            padding-top: 100px;
            padding-left: 0;
            padding-right: 0;
        }
    }

    &__heading {
        color: var(--color-text);
        @include font(barlow, reg);
        @include font-size(42px, 42px);
        margin-bottom: 16px;

        @include breakpoint(mobile) {
            @include font-size(30px, 36px);
        }
    }
    &__desc {
        color: var(--color-text);
        @include font(barlow, reg);
        @include font-size(28px, 31px);
        @include breakpoint(mobile) {
            @include font-size(20px, 22px);
        }
    }
    &__title {
        padding-bottom: 48px;
    }
    &__link-group {
        padding-bottom: 24px;
        a {
            display: block;
        }
    }
    &__link-heading {
        color: var(--color-text);
        @include font(barlow, reg);
        @include font-size(22px, 28px);
        padding-bottom: 8px;
        @include breakpoint(mobile) {
            @include font-size(16px, 19px);
        }
    }
    a {
        color: var(--color-text);
        @include font(barlow, reg);
        @include font-size(16px, 22px);
        margin-bottom: 8px;
        width: fit-content;
        @include breakpoint(mobile) {
            @include font-size(16px, 22px);
        }
    }
}

.error-page-maintenance {
    --color-text: #411e75;
    background-image: url("./../img/error-page-maintenance-bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    &:after,
    &:hover:after {
        content: "";
        display: block;
        bottom: -150px;
        right: -275px;
        background: url("./../img/error-page-maintenance-image.png");
        background-repeat: no-repeat;
        background-position: 100% bottom;
        z-index: -1;

        @include breakpoint(tablet) {
            bottom: 0;
            right: 0;
            background: url("./../img/error-page-maintenance-image-tablet.png");
            background-repeat: no-repeat;
            background-position: 100% bottom;
        }

        @include breakpoint(mobile) {
            bottom: -170px;
            right: -440px;
            background: url("./../img/error-page-maintenance-image-mobile.png");
            background-repeat: no-repeat;
            background-position: 100% bottom;
        }
    }

    &__body {
        padding-top: 297px;
        padding-left: 214px;

        @include breakpoint(tablet) {
            padding-left: 0;
            padding-right: 0;
        }
        @include breakpoint(mobile) {
            padding-top: 140px;
            padding-left: 0;
            padding-right: 0;
        }
    }
    &__heading {
        color: var(--color-text);
        @include font(barlow, reg);
        @include font-size(42px, 42px);
        @include breakpoint(tablet) {
            @include font-size(36px, 40px);
        }
        @include breakpoint(mobile) {
            @include font-size(30px, 36px);
        }
    }
    &__desc {
        color: var(--color-text);
        @include font(barlow, medium);
        @include font-size(28px, 31px);
        @include breakpoint(tablet) {
            @include font-size(24px, 26px);
        }
        @include breakpoint(mobile) {
            @include font-size(22px, 28px);
        }
    }
    &__sub-desc {
        color: var(--color-text);
        @include font(barlow, reg);
        @include font-size(28px, 31px);
        @include breakpoint(tablet) {
            @include font-size(24px, 26px);
        }
        @include breakpoint(mobile) {
            @include font-size(22px, 28px);
        }
    }
    &__sub-desc-redirect {
        margin-top: 48px;
        color: var(--color-text);
        @include font(barlow, reg);
        @include font-size(28px, 31px);
        @include breakpoint(tablet) {
            @include font-size(24px, 26px);
        }
        @include breakpoint(mobile) {
            @include font-size(22px, 28px);
        }
    }
    a {
        color: var(--color-text);
        @include font(barlow, reg);
        @include font-size(28px, 31px);
        @include breakpoint(tablet) {
            @include font-size(24px, 26px);
        }
        @include breakpoint(mobile) {
            @include font-size(22px, 28px);
        }
    }
}
