/*
==========================================================================
   #Wrapper
========================================================================== */
%wrapper,
.wrapper {
    display: block;
    width: 100%;
    max-width: var(--wrapper-width);
    padding: 0 var(--wrapper-padding-h);
    margin: auto;
    box-sizing: border-box;
}

.wrapper-small {
    @extend %wrapper;
    max-width: var(--wrapper-small-width);
}
