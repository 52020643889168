.block-image-content-cta {
    --color-block-bg: #dcdff3;
    --color-heading: #411e75;
    --spacing-heading: 24px;

    @include breakpoint(mobile) {
        --spacing-heading: 18px;
    }
    margin-bottom: 120px;
    margin-top: 133px;

    @include breakpoint(tablet) {
        margin-bottom: 96px;
    }

    @include breakpoint(mobile) {
        margin-top: 104px;
        margin-bottom: 48px;
    }

    &__wrapper {
        background-color: var(--color-block-bg);
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        @include breakpoint(desktop) {
            padding-left: 0;
            display: flex;
        }

        @include breakpoint(tablet) {
            margin-right: -32px;
        }

        @include breakpoint(mobile) {
            margin-left: -24px;
            margin-right: -24px;
        }
    }

    .wrapper {
        @include breakpoint(tablet) {
            padding-right: 0;
        }
        @include breakpoint(mobile) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &__heading {
        margin-bottom: var(--spacing-heading);
        margin-top: 0;
        @include font-size(50px, 52px);
        color: var(--color-heading);

        @include breakpoint(tablet) {
            @include font-size(36px, 36px);
        }

        @include breakpoint(mobile) {
            @include font-size(25px, 30px);
            text-align: left;
        }
    }

    &__container {
        flex-shrink: 0;
        padding: 64px 29px 64px 64px;
        @include breakpoint(desktop) {
            width: 800px;
        }

        @include breakpoint(tablet) {
            padding-top: 8px;
            padding-bottom: 66px;
            padding-right: 64px;
        }

        @include breakpoint(mobile) {
            padding: 2px 24px 29px;
            text-align: center;
        }

        .btn-primary {
            margin-top: 8px;
        }
    }

    &__desc {
        @include breakpoint(mobile) {
            text-align: left;
        }
    }

    &__image {
        flex-shrink: 0;
        margin-top: -24px;
        @include breakpoint(desktop) {
            width: 100%;
            height: 560px;
        }

        @include breakpoint(not-desktop) {
            margin-left: auto;
            margin-top: 0;
        }

        @include breakpoint(tablet) {
            width: 704px;
            height: 444px;
            margin-bottom: 40px;
        }

        @include breakpoint(mobile) {
            width: calc(100% - 25px);
            height: 269px;
        }

        img {
            height: calc(100% + 48px);
            width: 100%;
            object-fit: cover;
            border-top-left-radius: 13px;
            border-bottom-left-radius: 300px;

            @include breakpoint(not-desktop) {
                height: 100%;
            }

            @include breakpoint(tablet) {
                margin-top: -24px;
            }

            @include breakpoint(mobile) {
                margin-top: -30px;
                border-bottom-left-radius: 100px;
            }
        }
    }
}
