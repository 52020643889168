/*
==========================================================================
   #Form elements
========================================================================== */
:root {
    --form-element-border-radius: 6px;
    --form-element-border-width: 1px;
    --form-element-outline-width: 2px;
    --form-element-outline-offset: 3px;
    --form-element-padding-v: 12px;
    --form-element-padding-h: 18px;
    --select-padding-right: 40px;
    --select-arrow-size: 14px 8px;
    --select-arrow-poistion: 18px;
    --textarea-padding-v: 18px;
    --textarea-padding-h: 10px;
    --checkbox-height: 18px;
    --checkbox-width: 18px;
    --checkbox-padding-left: 16px;
    --checkbox-tick-position-left: 6px;
    --checkbox-tick-width: 9px;
    --checkbox-tick-height: 8px;
    --checkbox-border-width: 1px;
    --radio-size-inner: 14px;
    --radio-size-outer: 18px;
    --radio-padding-left: 16px;
    --radio-border-width: 1px;

    // From element colors
    --color-form-element-border: #411e75;
    --color-form-element-disabled-bg: #fff;
    --color-form-element-disabled-border: #a19ba9;
    --color-form-element-text: #411e75;
    --color-form-element-bg: #f0f1fa;
    --color-form-element-hover-bg: #dcdff3;
    --color-form-element-focus-shadow: #aa52ff;
    --color-select-bg: #f0f1fa;

    // Label colors
    --color-label-text: #130527;
    --color-label-disabled: #a19ba9;

    // Radio colors
    --color-radio-bg: #fff;
    --color-radio-checked-bg: #92278f;
    --color-checkbox-checked-disabled: #a19ba9;

    // Checkbox colors
    --color-checkbox-bg: #fff;
    --color-checkbox-checked-bg: #92278f;
    --color-checkbox-tick-disabled: #a19ba9;

    // Form error colors
    --color-form-error-text: #de350b;
    --color-form-error-bg: #fcebe7;
    --color-form-error-border: #de350b;
    --color-form-border: #411e75;
}

/**
 * Placeholder
 */

/* stylelint-disable */

::-webkit-input-placeholder {
    color: inherit;
}

:-moz-placeholder {
    /* Firefox 18- */
    color: inherit;
}

::-moz-placeholder {
    /* Firefox 19+ */
    color: inherit;
}

:-ms-input-placeholder {
    color: inherit;
}
/* stylelint-enable */

/**
 * Label
 */

label {
    color: var(--color-label-text);
}

textarea {
    padding: var(--textarea-padding-v) var(--textarea-padding-h);
    height: auto;
    resize: vertical;
}

%input,
input[type="email"],
input[type="number"],
input[type="password"],
input[type="tel"],
input[type="url"],
input[type="text"],
input[type="search"],
textarea,
select {
    display: inline-block;
    box-sizing: border-box;
    color: var(--color-form-element-text);
    border: var(--form-element-border-width) solid currentColor;
    border-radius: var(--form-element-border-radius);
    padding: var(--form-element-padding-v) var(--form-element-padding-h);
    max-width: 100%;
    background-color: var(--color-form-element-bg);
    transition: background-color var(--transition-time),
        color var(--transition-time), outline var(--transition-time);
    appearance: none; /* Remove ios text input inner shadow */
    @include font-size(16px, 22px);

    @include breakpoint(mobile) {
        width: 100%;
    }

    &:focus {
        outline: var(--form-element-outline-width) solid var(--color-focus);
        outline-offset: var(--form-element-outline-offset);
    }

    .no-touch &:hover {
        background-color: var(--color-form-element-hover-bg);
        outline: 2px solid var(--color-primary-1);
    }
}

/**
 * Select
 */

select {
    appearance: none;
    cursor: pointer;
    background-image: url("./../icons/arrow-down.svg");
    padding-right: var(--select-padding-right);
    background-repeat: no-repeat;
    background-position: right var(--select-arrow-poistion) center;
    background-size: var(--select-arrow-size);

    // Hide default select arrow in IE

    &::-ms-expand {
        display: none;
    }
}

/**
 * Checkbox
 */

input[type="checkbox"] {
    position: absolute;
    left: -9999px;
    opacity: 0;
    + label {
        position: relative;
        display: inline-flex;
        align-items: center;
        cursor: pointer;
        padding-left: calc(
            #{var(--checkbox-width)} + #{var(--checkbox-padding-left)}
        );
        min-height: --checkbox-height;
        margin-bottom: 10px;

        &:after {
            content: "";
            position: absolute;
            display: block;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: var(--checkbox-width);
            height: var(--checkbox-height);
            background-color: var(--color-checkbox-bg);
            border: var(--checkbox-border-width) solid
                var(--color-form-element-border);
            border-radius: 3px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 22px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }
    }

    &:checked {
        + label {
            &:before {
                content: "";
                display: block;
                width: var(--checkbox-tick-width);
                height: var(--checkbox-tick-height);
                position: absolute;
                left: calc(
                    (var(--checkbox-width) - var(--checkbox-tick-width)) / 2
                );
                top: 50%;
                transform: translateY(-50%);
                background-image: url("./../icons/tick.svg");
                background-size: contain;
                background-repeat: no-repeat;
                z-index: z-index(check-box);
            }

            &:after {
                background-color: var(--color-checkbox-checked-bg);
                border-color: var(--color-checkbox-checked-bg);
            }
        }
    }

    &:focus + label:after {
        outline: var(--focus-border-width) solid var(--color-focus);
        outline-offset: var(--form-element-outline-offset);
    }

    &:disabled + label {
        color: var(--color-label-disabled);
        cursor: default;

        &:before {
            color: var(--color-checkbox-tick-disabled);
        }

        &:after {
            border-color: var(--color-form-element-disabled-border);
            background-color: var(--color-form-element-disabled-bg);
        }
    }
}

/**
* Radio
*/

input[type="radio"] {
    position: absolute;
    left: -9999px;
    opacity: 0;
    + label {
        position: relative;
        display: inline-flex;
        align-items: center;
        padding-left: calc(
            #{var(--radio-size-outer)} + #{var(--radio-padding-left)}
        );
        cursor: pointer;
        margin-bottom: 10px;
        margin-right: 15px;
        box-sizing: border-box;
        min-height: var(--radio-size-outer);

        @include breakpoint(mobile) {
            margin-bottom: 22px;

            &:last-of-type {
                margin-bottom: 0;
            }
        }

        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: var(--radio-size-outer);
            height: var(--radio-size-outer);
            box-sizing: border-box;
            background-color: var(--color-radio-bg);
            border: var(--radio-border-width) solid
                var(--color-form-element-border);
            border-radius: 50%;
        }
    }

    &:checked + label {
        &:before {
            content: "";
            position: absolute;
            left: calc(
                (#{var(--radio-size-outer)} - #{var(--radio-size-inner)}) / 2
            );
            top: 50%;
            transform: translateY(-50%);
            height: var(--radio-size-inner);
            width: var(--radio-size-inner);
            z-index: z-index(radio);
            background-color: var(--color-radio-checked-bg);
            transition: all var(--transition-time);
            border-radius: 50%;
        }
    }

    &:focus + label {
        &:after {
            outline: var(--focus-border-width) solid var(--color-focus);
            outline-offset: var(--form-element-outline-offset);
        }
    }

    &:disabled {
        + label {
            color: var(--color-label-disabled);
            cursor: default;

            &:after {
                border-color: var(--color-form-element-disabled-border);
                background-color: var(--color-form-element-disabled-bg);
            }
        }

        &:checked + label:before {
            background-color: var(--color-checkbox-checked-disabled);
        }
    }
}

/**
 * Buttons
 */

%button,
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
    border: 0;
}
