/*
==========================================================================
   #Utilities
========================================================================== */

// #mobile only classes
@include breakpoint(desktop) {
    .desktopHide,
    .mobileOnly {
        display: none !important;
    }
}

@include breakpoint(tablet) {
    .tabletHide,
    .mobileOnly,
    .desktopOnly {
        display: none !important;
    }
}

@include breakpoint(mobile) {
    .mobileHide,
    .desktopOnly {
        display: none !important;
    }
}
