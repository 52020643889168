/*
==========================================================================
   #Table
========================================================================== */
:root {
    --table-border-width: 1px;
    --table-padding-v: 17px;
    --table-padding-h: 17px;
    --table-body-padding-v: 16px;
    --table-body-padding-h: 16px;

    // Table colors
    --color-table-border: #b6b6b6;
    --color-table-tr-odd-bg: #eee;
    --color-table-tr-even-bg: #fff;
    --color-table-th-border: #b6b6b6;
    --color-table-th-bg: #ccc;
    --color-table-th-text: #000;
    --color-table-td-border: #b6b6b6;
    --color-table-td-text: #000;
    --color-table-border: #411e75;
    --color-table-header-bg: #411e75;
    --color-table-body-bg-odd: #f0f1fa;
    --color-table-body-bg-even: #dcdff3;
    --color-table-text: #130527;
    --color-table-header-text: #fff;
}

table {
    width: 100%;
    border: none;
    table-layout: fixed;
    border-spacing: 0;
    border-collapse: separate;
    margin: 0 0 1em;
    border-radius: 12px;
    margin-top: 10px;

    caption {
        display: none;
    }

    @include breakpoint(mobile) {
        display: block;
        overflow-x: auto;

        tbody {
            tr {
                th {
                    min-width: 200px;
                    white-space: nowrap;
                }
            }
        }

        thead {
            th {
                min-width: 200px;
                white-space: nowrap;
            }
        }
    }
}

thead {
    th {
        @include font(barlow, semibold);
        @include font-size(18px, 25px);
        a {
            color: var(--color-table-header-text);
            text-decoration: none;
        }

        &:first-child {
            text-align: left;
        }
    }

    th,
    td {
        padding: var(--table-padding-v) var(--table-padding-h);
    }
}

td {
    color: var(--color-table-td-text);
}

th {
    border: none;
    background: transparent;
    color: var(--color-table-th-text);
    background-color: var(--color-table-header-bg);

    &:first-of-type {
        border-top-left-radius: 12px;
    }

    &:last-of-type {
        border-top-right-radius: 12px;
    }

    &:not(:first-child) {
        text-align: center;
    }
}

tbody {
    tr {
        border-bottom: 1px solid var(--color-table-border);
        &:last-child {
            border-bottom: none;
            td {
                &:first-child {
                    border-radius: 0 0 0 5px;
                    border-top: 0;
                    border-right: 0;
                }
                &:last-child {
                    border-radius: 0 0 5px;
                    border-top: 0;
                    border-left: 0;
                }
            }
        }

        th {
            @include font(barlow, semibold);
            @include font-size(18px, 25px);
            color: var(--color-table-header-text);
            padding: var(--table-padding-v) var(--table-padding-h);
            text-align: left;
        }

        td {
            text-align: center;
            color: var(--color-table-text);
            &:nth-child(even) {
                background: var(--color-table-body-bg-even);
            }
            &:nth-child(odd) {
                background: var(--color-table-body-bg-odd);
            }
            &:first-child {
                text-align: left;
                background-color: var(--color-table-body-bg-odd);
                @include font(barlow, semibold);
                a:not([class]) {
                    color: var(--color-table-text);
                }
            }
        }
    }

    th,
    td {
        padding: var(--table-body-padding-v) var(--table-body-padding-h);
    }
}
