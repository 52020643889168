/*
==========================================================================
   #Elements
========================================================================== */

/**
 * 1. Fluid images for responsive purposes.
 * 2. Offset `alt` text from surrounding copy.
 * 3. Setting `vertical-align` removes the whitespace that appears under `img`
 *    elements when they are dropped into a page as-is. Safer alternative to
 *    using `display: block;`.
 */

img {
    max-width: 100%; /* [1] */
    font-style: italic; /* [2] */
    vertical-align: middle; /* [3] */
    height: auto;
}

/*
 * A better looking default horizontal rule
 */

hr {
    display: block;
    height: 1px;
    border: 0;
    border-top: var(--hr-border-width) solid var(--color-hr);
    margin: 1em 0;
    padding: 0;
}

iframe {
    max-width: 100%;
}
