/*
==========================================================================
   #Page footer
========================================================================== */
.page-footer {
    --footer-text-hover-border-radius: 4px;
    --color-footer-bg: #260950;
    --color-footer-text: #fff;
    --color-footer-text-hover: #260950;
    --color-footer-text-focus: #260950;
    --color-footer-text-hover-bg: #d995d7;
    --color-footer-bottom-text: #fff;
    --color-link-hover: #fff;
    --color-footer-text-underline: #d995d7;
    --color-footer-text-active: #d995d7;
    --color-footer-outline-color: #aa52ff;
    --color-footer-border-color: #fff;
    background: var(--color-footer-bg);
    color: var(--color-footer-text);
    @include font(barlow, light);
    position: relative;
    z-index: 1;

    .wrapper {
        position: relative;

        @include breakpoint(mobile) {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    ul {
        list-style: none;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
    }

    li {
        @include font(barlow, medium);
        margin-bottom: 0;
    }

    &__heading {
        color: var(--color-footer-text);
        margin-top: 0;
    }

    &__main {
        a {
            color: var(--color-footer-text);
            @include font-size(16px, 30px);
            @include hover(var(--color-footer-text-hover));
        }

        .separator {
            color: var(--color-footer-text);
            @include font-size(18px, 27px);
            @include font(barlow, semibold);
            display: block;
            margin-bottom: 10px;

            @include breakpoint(not-desktop) {
                display: block;
                margin-bottom: 12px;
            }
        }
    }

    &__main-top {
        display: flex;
        padding-bottom: 52px;
        padding-top: 32px;

        @include breakpoint(mobile) {
            padding-bottom: 32px;
        }

        .menu {
            @include breakpoint(not-mobile) {
                display: flex;
            }
        }

        @include breakpoint(not-desktop) {
            display: block;
        }
    }

    &__main-bottom {
        padding-top: 39px;
        padding-bottom: 38px;
        max-width: 775px;

        p {
            @include font-size(17px, 21px);
        }
    }

    &__details {
        display: flex;
        width: 575px;
        flex-wrap: wrap;
        column-gap: 104px;
        row-gap: 48px;

        @include breakpoint(desktop) {
            margin-right: 133px;
        }

        @include breakpoint(not-desktop) {
            display: none;
        }
    }

    &__contact {
        padding-top: 23px;

        @include breakpoint(mobile) {
            padding-top: 32px;
        }
    }

    &__contact-number {
        a {
            @include font-size(28px, 31px);
        }
    }

    &__contact-sub-detail {
        @include font-size(16px, 22px);
        @include font(barlow, reg);
    }

    &__desc {
        width: 575px;
        max-width: 100%;

        p {
            @include font-size(16px, 24px);
            @include font(barlow, medium);
        }

        @include breakpoint(mobile) {
            width: 100%;

            p {
                margin-top: 32px;
                margin-bottom: 32px;
            }
        }
    }

    &__logo {
        img {
            height: 74px;
            width: 271px;
        }

        a:before {
            display: none;
        }
    }

    &__menu-main,
    &__menu-main-foundation {
        li {
            &.active > .sub-menu-wrapper {
                display: block;
            }
        }

        ul {
            min-width: 200px;
        }

        a {
            padding-left: 2px;
            padding-right: 2px;
            margin-bottom: 10px;
            border-radius: var(--footer-text-hover-border-radius);

            &:before {
                content: "";
                background-color: var(--color-footer-text-underline);
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 2px;
                z-index: -1;
                transition: height 0.3s ease-in-out;
                border-radius: 2px;
            }

            &:focus {
                &:before {
                    outline: 1px solid var(--color-footer-outline-color);
                    outline-offset: 2px;
                    z-index: -1;
                }
                outline-color: transparent;
            }

            &:active {
                &:before {
                    display: none;
                }
            }
        }

        .level1 {
            @include breakpoint(mobile) {
                padding-top: 32px;
            }

            > a {
                @include font-size(18px, 27px);
                @include font(barlow, semibold);
                color: var(--color-secondary-4);
            }
        }

        .level2 {
            a {
                &:active {
                    background-color: transparent;
                    color: var(--color-footer-text-active);
                    text-decoration: underline;
                    text-decoration-color: var(--color-footer-text-underline);
                    text-decoration-thickness: 3px;
                }
            }

            &.current {
                a {
                    color: var(--color-footer-text-hover);
                    background-color: var(--color-footer-text-hover-bg);

                    &:focus {
                        outline: 1px solid var(--color-footer-outline-color);
                        outline-offset: 2px;
                        z-index: z-index(default);
                    }

                    &:before {
                        display: none;
                    }
                }
            }
        }
    }

    &__menu-main {
        @include breakpoint(tablet) {
            width: 50%;
            float: right;
        }

        @include breakpoint(mobile) {
            border-top: 1.5px solid var(--color-footer-border-color);
        }
    }

    &__menu-main-foundation {
        @include breakpoint(tablet) {
            width: 50%;
            clear: both;
            float: left;
        }
    }

    &__menu-secondary {
        @include breakpoint(mobile) {
            display: inline-block;
        }

        ul {
            display: flex;

            @include breakpoint(mobile) {
                display: inline-block;
            }
        }

        li {
            display: flex;

            @include breakpoint(mobile) {
                display: inline-block;
            }

            > a {
                @include font-size(14px, 20px);
                @include font(barlow, medium);
                text-decoration-color: var(--color-footer-text-underline);
                padding-left: 2px;
                padding-right: 2px;
                margin-bottom: 10px;
                border-radius: var(--footer-text-hover-border-radius);

                &:before {
                    content: "";
                    background-color: var(--color-footer-text-underline);
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 2px;
                    z-index: -1;
                    transition: height 0.3s ease-in-out;
                    border-radius: 2px;
                }

                &:focus {
                    &:before {
                        outline: 1px solid var(--color-footer-outline-color);
                        outline-offset: 2px;
                        z-index: -1;
                    }
                    outline-color: transparent;
                }

                &:hover {
                    color: var(--color-footer-text-hover);
                }

                &:active {
                    color: var(--color-footer-text-active);
                    text-decoration: underline;
                    text-decoration-color: var(--color-footer-text-underline);
                    text-decoration-thickness: 3px;

                    &:before {
                        display: none;
                    }
                }
            }

            &:first-child {
                &:before {
                    content: "|";
                    color: var(--color-footer-text);
                    padding-left: 10px;
                    padding-right: 10px;
                }
            }

            &:not(.last) {
                &:after {
                    content: "|";
                    color: var(--color-footer-text);
                    padding-left: 10px;
                    padding-right: 10px;

                    @include breakpoint(mobile) {
                        padding-left: 7px;
                        padding-right: 7px;
                    }
                }
            }
        }
    }

    &__menu-socials {
        display: flex;

        li {
            display: flex;
            margin-right: 12px;
        }

        a {
            line-height: normal;
            display: block;

            &:hover,
            &:active {
                img {
                    filter: opacity(0.5)
                        drop-shadow(0 0 0 var(--color-footer-text-hover-bg));
                }
                z-index: inherit;
            }

            &:focus {
                box-shadow: 0 0 0 2px var(--color-footer-bg),
                    0 0 0 3px var(--color-footer-outline-color);
                border-radius: 2px;
                outline-color: transparent;
            }

            &:before {
                display: none;
            }
        }

        .menu {
            display: flex;
        }

        .image-title {
            @extend .sr-only;
        }

        img {
            width: 30px;
        }
    }

    &__bottom {
        padding-top: 32px;
        padding-bottom: 32px;
        @include font-size(12px, 22px);
        @include font(barlow, light);

        @include breakpoint(mobile) {
            padding-top: 0;
        }

        .wrapper {
            color: var(--color-footer-bottom-text);
            display: flex;
            justify-content: space-between;

            @include breakpoint(not-desktop) {
                flex-direction: column-reverse;
            }
        }

        a {
            color: var(--color-footer-bottom-text);
            text-decoration: none;
        }
    }

    &__main-bottom__left {
        display: flex;
        @include font-size(14px, 21px);
        @include font(barlow, reg);

        a {
            @include font-size(14px, 21px);
            @include font(barlow, reg);
        }

        @include breakpoint(mobile) {
            padding-top: 32px;
            display: inline-block;
        }
    }

    &__main-bottom__right {
        .navbar-toggler {
            display: none;
        }

        @include breakpoint(tablet) {
            padding-top: 10px;
            padding-bottom: 45px;
        }

        @include breakpoint(mobile) {
            padding-bottom: 32px;
            border-bottom: 1.5px solid var(--color-footer-border-color);
        }
    }

    .footer-details-not-desktop {
        @include breakpoint(tablet) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            margin-bottom: 48px;
        }

        @include breakpoint(desktop) {
            display: none;
        }
    }

    .footer-desc-not-desktop {
        @include breakpoint(tablet) {
            width: 50%;
            float: left;
            padding-right: 85px;
        }

        @include breakpoint(desktop) {
            display: none;
        }
    }

    &-foundation {
        &__details {
            display: flex;
            flex-wrap: wrap;
            row-gap: 10px;
            max-width: 270px;

            @include breakpoint(tablet) {
                max-width: 1198px;
            }

            @include breakpoint(mobile) {
                max-width: 767px;
            }
        }

        &__logos {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 27px;
        }
    }

    .foundation-details-desktop {
        @include breakpoint(not-desktop) {
            display: none;
        }
    }

    .foundation-footer-not-desktop {
        @include breakpoint(mobile) {
            border-top: 1.5px solid var(--color-footer-border-color);
            padding-top: 32px;
        }

        @include breakpoint(desktop) {
            display: none;
        }
    }
}
