/*
==========================================================================
   #3 card module - Contact Us Page - using article code
========================================================================== */
.block-three-card {
    --color-heading: #411e75;
    --color-border: #260950;
    padding: 48px 0;

    h3 {
        color: var(--color-heading);
        margin-bottom: 8px;
        @include breakpoint(mobile) {
            @include font-size(28px, 30.8px);
        }
    }

    .wf-column {
        padding-right: 24px;
        border-right: 1px solid var(--color-border);
        padding-top: 20.5px;
        padding-bottom: 20.5px;

        @include breakpoint(not-desktop) {
            border-right: 0;
            border-bottom: 1px solid var(--color-border);
            padding-right: 0;
        }

        &:nth-child(3n) {
            border-right: 0;
        }

        p {
            margin: 0;
        }
    }
    &__contact {
        margin-bottom: 8px;
        display: block;
    }
}

.header-contact {
    @include font-size(36px, 36px);
    letter-spacing: 0.01em;
    color: var(--color-primary-1);
    margin-bottom: 32px;

    @include breakpoint(mobile) {
        max-width: 272px;
        margin-bottom: 0;
    }
}
