.listing-content {
    --color-hightlight: #ad3192;
    --color-border: #411e75;
    --color-title: #411e75;
    --color-text: #000;
    --color-tag: #92278f;
    --color-readtime: #92278f;

    &__counter {
        @include font-size(18px, 27px);
        padding-bottom: 25px;

        @include breakpoint(not-desktop) {
            display: none;
        }
    }
    &__highlight {
        color: var(--color-hightlight);
    }

    &__lists {
        padding-left: 0;
        list-style: none;
        margin-top: 0;
        margin-bottom: 0;

        li:first-child .listing-content__list {
            @include breakpoint(mobile) {
                padding-top: 0;
            }
        }
    }

    &__list {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 0;
        border-bottom: 1px solid var(--color-border);
        text-decoration: none;

        @include breakpoint(mobile) {
            display: block;
            padding: 32px 0 0;
        }
    }

    a.listing-content__list {
        &:hover {
            .listing-content__list-content-title {
                span {
                    background-size: 100% 100%;
                }

                &:after {
                    padding-left: 25px;
                }
            }
        }

        &:focus-visible {
            outline: 1px solid var(--color-focus);
            outline-offset: 2px;
        }
    }

    a.listing-content__list-content-text {
        display: block;
        border-radius: 4px;
        text-decoration: none;
        &:hover {
            .listing-content__list-content-title {
                span {
                    background-size: 100% 100%;
                }

                &:after {
                    padding-left: 25px;
                }
            }
        }

        &:focus-visible {
            outline: 1px solid var(--color-focus);
            outline-offset: 2px;
        }
    }

    &__list-image {
        width: 304px;
        height: 200px;
        overflow: hidden;
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 8px;

        @include breakpoint(tablet) {
            width: 243px;
            height: 206px;
        }

        @include breakpoint(mobile) {
            display: block;
            width: 100%;
            height: auto;
        }

        a {
            display: flex;
            text-decoration: none;

            &:before {
                display: none;
            }
        }

        img {
            width: 100%;
            height: auto;
            border-radius: 8px;
        }
    }

    &__list-content {
        width: calc(100% - 304px);
        padding: 22px 0 22px 32px;

        @include breakpoint(tablet) {
            padding: 0 0 0 32px;
            width: calc(100% - 243px);
        }

        @include breakpoint(mobile) {
            padding: 16px 0 24px;
            width: 100%;
        }
    }

    &__list-content-breadcrumbs {
        @include font-size(14px, 21px);
        margin-top: 0;
        margin-bottom: 0;
        a {
            display: inline-block;
        }
        ol {
            margin-top: 0;
            margin-bottom: 24px;

            @include breakpoint(tablet) {
                margin-bottom: 16px;
            }
        }

        li.active {
            @include font(barlow, reg);
        }

        li:nth-child(2) {
            &:before {
                @include breakpoint(mobile) {
                    display: none;
                }
            }
        }
    }

    &__list-content-title {
        @extend %h3;
        position: relative;
        text-decoration: none;
        color: var(--color-title);
        display: inline-block;
        margin-bottom: 8px;

        &:after {
            content: url("./../icons/arrow-right-purple-curved.svg");
            position: absolute;
            padding-left: 20px;
            transition: padding-left var(--transition-time);

            @include breakpoint(not-desktop) {
                display: none;
            }
        }

        span {
            background-image: linear-gradient(
                transparent calc(100% - 1px), currentColor 1px
            );
            background-repeat: no-repeat;
            background-size: 0% 100%;
            transition: background-size var(--transition-time);
        }
    }

    &__list-content-intro {
        display: block;
        text-decoration: none;
        @include font-size(16px, 22px);
        color: var(--color-text);
        padding-bottom: 20px;

        @include breakpoint(mobile) {
            padding-bottom: 16px;
        }

        .read-more {
            font-weight: 400;
            color: var(--color-primary-1);
        }
    }

    &__list-content-link {
        @extend %a;
    }

    &__list-content-tag {
        @include font-size(18px, 27px);
        text-transform: uppercase;
        color: var(--color-tag);
        margin-bottom: 16px;
    }

    &__list-content-readtime {
        color: var(--color-readtime);

        &:before {
            content: url("./../icons/clock.svg");
            display: inline-block;
            vertical-align: middle;
            margin-right: 4px;
        }

        & + .listing-content__list-content-date {
            margin-left: 8px;
            padding-left: 8px;
            border-left: 1px solid var(--color-neutral-5);
        }
    }

    &__list-content-date {
        color: var(--color-neutral-6);
    }
}
