.social-share {
    --color-label: #411e75;
    --bg-color-icon: #ad3192;
    --border-color: #411e75;
    --focus-color: #ad3192;
    margin: 40px 0 48px;

    @include breakpoint(tablet) {
        padding: 20px 0 45px;
    }
    @include breakpoint(mobile) {
        padding: 10px 0 30px;
    }

    &__label {
        @include font-size(16px, 24px);
        @include font(barlow, medium);
        color: var(--color-label);
    }

    .a2a_svg {
        padding: 3px;
    }

    .addtoany_list a:hover span,
    .addtoany_list a:focus span {
        opacity: 1;
        background-color: var(--bg-color-icon) !important;
    }

    .addtoany_list a:focus {
        outline: 1px solid var(--focus-color);
    }

    .addtoany_list a {
        padding: 1px;
        margin: 0 3px;
        border-radius: 3px;
    }
}
