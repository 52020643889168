.pagination {
    --color-pagination-bg: transparent;
    --color-pagination-border: transparent;
    --color-pagination-text: #411e75;
    --color-pagination-text-hover: #411e75;
    --color-pagination-bg-hover: transparent;
    --color-pagination-border-hover: #ad3192;
    --color-pagination-text-active: #411e75;
    --color-pagination-bg-active: #dcdff3;
    --color-pagination-focus-border: #aa52ff;
    --color-pagination-border-active: #dcdff3;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 35px;

    @include breakpoint(tablet) {
        margin-top: 32px;
        margin-bottom: 46px;
    }

    @include breakpoint(mobile) {
        margin-top: 42px;
        margin-bottom: 55px;
    }

    ul {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        padding: 0;
        text-align: center;
        margin: 0 auto;
    }

    li {
        list-style: none;
        padding: 0;
        margin-left: 8px;
        margin-right: 8px;

        @include breakpoint(mobile) {
            margin-left: 2px;
            margin-right: 2px;
        }

        &.start,
        &.end {
            display: none;
        }
        &.previous,
        &.next {
            a,
            span {
                border: 0;
                min-width: auto;
                padding: 0;
                background-color: transparent;
            }

            a:hover {
                background-color: transparent;
            }
        }

        &.previous {
            @include breakpoint(mobile) {
                margin-left: 0;
                min-width: 18px;
                text-align: left;
            }

            a:hover {
                .icon-arrow-left {
                    content: url("./../icons/chevron-left-hover.svg");
                }
            }

            a:focus {
                .icon-arrow-left {
                    content: url("./../icons/chevron-left-disabled.svg");
                }
                border-radius: 4px;
                outline-color: var(--color-pagination-focus-border);
            }

            span > span {
                content: url("./../icons/chevron-left-disabled.svg");
            }
        }

        &.next {
            @include breakpoint(mobile) {
                margin-right: 0;
                min-width: 18px;
                text-align: right;
            }

            a:hover {
                .icon-arrow-right {
                    content: url("./../icons/chevron-right-hover.svg");
                }
            }

            a:focus {
                .icon-arrow-right {
                    content: url("./../icons/chevron-right-disabled.svg");
                }
                border-radius: 4px;
                outline-color: var(--color-pagination-focus-border);
            }

            span > span {
                content: url("./../icons/chevron-right-disabled.svg");
            }
        }

        a,
        > span {
            display: inline-flex;
            align-items: center;
            justify-content: center;
            border: 2px solid var(--color-pagination-border);
            border-radius: 50%;
            color: var(--color-pagination-text);
            background: var(--color-pagination-bg);
            padding: 5px 8px;
            width: auto;
            text-align: left;
            text-decoration: none;
            height: 28px;
            min-width: 28px;
            @include font(barlow, reg);
            @include font-size(14px, 21px);

            @include breakpoint(mobile) {
                height: 30px;
                min-width: 30px;
            }
        }

        a {
            &:before {
                display: none;
            }

            &:hover,
            &:focus {
                color: var(--color-pagination-text-hover);
                background: var(--color-pagination-bg-hover);
                border-color: var(--color-pagination-border-hover);
                text-decoration: none;
            }
        }

        &.active > span {
            color: var(--color-pagination-text-active);
            background: var(--color-pagination-bg-active);
            border-color: var(--color-pagination-border-active);
        }

        .icon-arrow-left {
            content: url("./../icons/chevron-left.svg");
        }

        .icon-arrow-right {
            content: url("./../icons/chevron-right.svg");
        }
    }
}

.tooltip {
    display: none !important;
}
