/*
==========================================================================
   #Staff profile detail page
========================================================================== */
.staff-profile-details {
    --color-badge: #fff;
    --color-title: #411e75;
    --color-subtitle: #90278e;
    --color-biography: #411e75;
    --color-label: #411e75;
    --color-card-bg: #dcdff3;
    --color-link: #000;
    --color-value: #000;
    display: flex;
    flex-wrap: wrap;

    @include breakpoint(mobile) {
        display: flex;
    }

    &__badge {
        background: linear-gradient(268.75deg, #411e75 -54%, #90278e 33.08%),
            #90278e;
        border-radius: 0 100px 100px 0;
        padding: 8px 26px 8px 57px;
        color: var(--color-badge);
        max-width: none;
        letter-spacing: -0.1px;
        margin-bottom: 35px;
        position: relative;

        @include breakpoint(not-mobile) {
            width: fit-content;
        }

        @include breakpoint(tablet) {
            margin-bottom: 25px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 0;
            max-width: 100%;
            border-radius: 0;
            bottom: 0;
        }
    }

    &__heading {
        color: var(--color-title);
    }

    &__subheading {
        color: var(--color-subtitle);
        @include font-size(22px, 24px);
        margin-bottom: 24px;

        @include breakpoint(tablet) {
            @include font-size(18px, 20px);
        }

        @include breakpoint(mobile) {
            @include font-size(16px, 19px);
            margin-bottom: 14px;
        }
    }

    &__email {
        word-break: break-all;
        display: inline-block;
    }

    &__bio {
        margin-bottom: 24px;
    }

    &__bio-heading {
        color: var(--color-biography);
        @include breakpoint(tablet) {
            @include font-size(24px, 33px);
        }
    }

    &__card {
        display: inline-flex;
        width: 370px;

        @include breakpoint(tablet) {
            width: 254px;
        }

        @include breakpoint(mobile) {
            order: 1;
            width: 100%;
            margin: auto;
            margin-bottom: 24px;
        }
    }

    &__content {
        display: inline-flex;
        width: calc(100% - 370px);
        padding-right: 33px;

        @include breakpoint(tablet) {
            width: calc(100% - 254px);
            padding-right: 17px;
        }

        @include breakpoint(mobile) {
            width: 100%;
            padding: 0;
            order: 2;
        }
    }

    .cfeed-item__bottom:after {
        display: none;
    }

    .cfeed-item {
        background-color: transparent;
        cursor: default;
        width: 100%;

        &__desc {
            margin-bottom: 8px;
        }

        .label {
            margin-bottom: 2px;
        }

        .value {
            color: var(--color-value);
        }

        .bold-text {
            @include font(barlow, medium);
        }

        .default-img {
            object-fit: contain;
        }

        a {
            position: relative;
            display: inline-block;
            text-decoration: none;
            color: var(--color-link-hover);
            transition: color var(--transition-time);
            z-index: 1;

            &:before {
                content: "";
                background-color: var(--color-link-decoration);
                position: absolute;
                left: -3px;
                bottom: 1px;
                width: calc(100% + 6px);
                height: 1px;
                z-index: -1;
                transition: height 0.3s ease-in-out;
                border-radius: 2px;
            }

            &:focus,
            &:hover {
                color: var(--color-link-hover);
                &:before {
                    height: calc(100% - 2px);
                    background-color: var(--color-link-decoration-hover);
                }
            }

            &:focus-visible {
                outline: 1px solid var(--focus-color);
            }
        }
    }

    .cfeed-item__bottom {
        background-color: var(--color-card-bg);
        border-radius: 0 0 8px 8px;
        padding: 32px 32px 34px;

        @include breakpoint(not-desktop) {
            padding: 24px;
        }
    }

    .label,
    .contact-label {
        @include font-size(16px, 24px);
        @include font(barlow, medium);
        display: block;
        color: var(--color-label);
        letter-spacing: -0.01em;
    }

    .contact-label {
        margin-bottom: 6px;
    }

    .box {
        margin-bottom: 20px;
    }

    .link-box a {
        display: block;
        width: fit-content;
        margin-bottom: 5px;
        word-break: break-all;
        color: var(--color-link);
    }

    &__intrest {
        max-width: 576px;

        @include breakpoint(tablet) {
            max-width: 370px;
        }
    }
}
