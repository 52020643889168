/*
==========================================================================
   #Interactive tool page - insulation calculator
========================================================================== */
.interactive-tool {
    --color-heading: #411e75;
    --color-text: #411e75;
    --color-tool-heading: #fff;
    --color-tool-header-bg: #411e75;
    --color-tool-content-bg: #dcdff3;
    --color-disclamier-bg: #babfe2;
    --color-message-text: #fff;
    --color-form-bg: #f0f1fa;
    --color-input-text: #656779;
    --color-input-bmi-bg: #dcdff3;
    --color-input-bmi-text: #3a3b45;
    --color-answer-btn-bg: #f0f1fa;
    --color-answer-btn-text: #411e75;
    --color-answer-btn-border: #411e75;
    --color-score-on: #de350b;

    .fields-container,
    .item-tag,
    .item-date,
    .news-item > .h2 {
        display: none;
    }

    .h3 {
        color: var(--color-heading);
    }

    fieldset {
        border: 0;
        padding: 0;
        margin: 0;
    }

    h3:not([class]) {
        color: var(--color-heading);
        margin-top: 8px;
    }

    &__tool {
        margin-top: 50px;
        margin-bottom: 38px;
        color: var(--color-text);
    }

    &__tool-header {
        padding: 22px 28px;
        color: var(--color-tool-heading);
        background-color: var(--color-tool-header-bg);
        border-radius: 8px 8px 0 0;
        margin-bottom: 0;
        position: relative;

        @include breakpoint(mobile) {
            @include font-size(20px, 24px);
        }
    }

    &__tool-content {
        background-color: var(--color-tool-content-bg);
        padding: 22px 32px;
        border-radius: 0 0 8px 8px;

        @include breakpoint(mobile) {
            padding: 6px 16px;
        }
    }

    &__tool-content-text,
    &__tool-disclaimer-title {
        @include font(barlow, medium);
    }

    &__tool-disclaimer {
        background: var(--color-disclamier-bg);
        border-radius: 8px;
        padding: 16px 16px 6px;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    &__tool-message {
        --color-link-hover: #babfe2;
        background: linear-gradient(268.75deg, #411e75 -54%, #90278e 123.85%);
        border-radius: 0 0 8px 8px;
        color: var(--color-message-text);
        text-align: center;
        @include font-size(22px, 24px);
        padding: 32px;

        @include breakpoint(mobile) {
            @include font-size(20px, 24px);
            .mobileblock {
                display: block;
                margin-top: 10px;
            }
        }

        a {
            color: var(--color-message-text);
            @include font-size(22px, 24px);
            text-decoration: underline;

            @include breakpoint(mobile) {
                @include font-size(20px, 24px);
            }

            &:hover {
                color: var(--color-link-hover);
            }
        }
    }

    &__tool-unit {
        padding: 12px 24px;
        border-radius: 100px;
        color: var(--color-text);
        background-color: var(--color-white);
        display: inline-block;
        margin-right: 10px;
    }

    &__tool-form {
        background-color: var(--color-form-bg);
        border: 1px solid var(--color-text);
        border-radius: 8px 8px 0 0;
        padding: 32px;

        @include breakpoint(mobile) {
            padding: 16px;
        }
    }

    &__tool-form-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    &__tool-form-label {
        @include font(barlow, semibold);
        @include font-size(22px, 24px);
        display: inline-block;
        margin-bottom: 5px;
        margin-right: 5px;

        @include breakpoint(mobile) {
            @include font-size(20px, 24px);
        }

        label {
            color: var(--color-text);
        }
    }

    &__tool-form-unit {
        color: var(--color-text);
        @include font-size(22px, 24px);
        @include font(barlow, reg);

        @include breakpoint(mobile) {
            @include font-size(20px, 24px);
        }
    }

    &__tool-form-section {
        width: calc((100% - 471px) / 3);

        @include breakpoint(tablet) {
            width: calc(100% / 3);
        }
        @include breakpoint(mobile) {
            width: 100%;
            margin-bottom: 20px;
        }

        &.bmi {
            width: 471px;
            padding-left: 32px;

            @include breakpoint(tablet) {
                width: 100%;
                padding-left: 0;
                padding-top: 40px;
            }
            @include breakpoint(mobile) {
                width: 100%;
                padding-left: 0;
            }

            input {
                background-color: var(--color-input-bmi-bg);
                color: var(--color-input-bmi-text);
            }
        }

        &.bglmmoll input {
            border-radius: 8px 0 0 8px;
            @include breakpoint(not-mobile) {
                border-right: 0;
            }

            @include breakpoint(mobile) {
                border-radius: 8px;
            }
        }

        &.weightkg input {
            border-radius: 0;
            @include breakpoint(not-mobile) {
                border-right: 0;
            }
            @include breakpoint(mobile) {
                border-radius: 8px;
            }
        }

        &.heightcm input {
            border-radius: 0 8px 8px 0;
            @include breakpoint(mobile) {
                border-radius: 8px;
            }
        }

        input {
            width: 100%;
            @include font(barlow, semibold);
            color: var(--color-input-text);
            text-align: center;
            @include font-size(22px, 24px);
            padding: 21px 18px;
            border: 1px solid var(--color-text);
            background-color: var(--color-white);

            @include breakpoint(mobile) {
                border-radius: 8px;
                padding: 13px 12px;
            }

            &:hover,
            &:focus {
                color: var(--color-input-bmi-text);
            }
        }
    }

    &__tool-form-desc {
        min-height: 44px;
        margin-bottom: 7px;

        @include breakpoint(mobile) {
            min-height: auto;
        }
    }

    &__contact p {
        margin-top: 0;
        margin-bottom: 10px;
    }

    &__question-contents {
        border-bottom: 1px solid var(--color-primary-1);
        padding: 32px 0 32px 112px;
        position: relative;
        margin-bottom: 20px;

        @include breakpoint(tablet) {
            padding-left: 80px;
            padding-bottom: 16px;
        }

        @include breakpoint(mobile) {
            padding-left: 0;
            padding-bottom: 16px;
        }

        .question-0-1 {
            margin-top: 48px;

            @include breakpoint(mobile) {
                margin-top: 16px;
            }

            p {
                padding-right: 40px;
            }
        }
    }

    &__question-counter {
        width: 48px;
        height: 48px;
        border-radius: 50%;
        border: 1px solid var(--color-primary-1);
        color: var(--color-primary-1);
        @include font-size(22px, 46px);
        background-color: var(--color-neutral-3);
        text-align: center;
        position: absolute;
        left: 0;
        top: 40px;

        @include breakpoint(mobile) {
            display: none;
        }
    }

    .audio-button {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background-color: var(--color-neutral-1);
        border: 1px solid var(--color-primary-2);
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 1px 3px rgba(38, 9, 80, 0.3);

        .fa-volume-up {
            &:before {
                content: "";
                width: 20px;
                height: 20px;
                display: block;
                background-image: url("./../icons/player.svg");
                background-size: 19px;
                background-position: center;
                background-repeat: no-repeat;
            }
        }

        &:hover,
        &:focus {
            background-color: var(--color-neutral-2);
            .fa-volume-up {
                &:before {
                    background-image: url("./../icons/player-active.svg");
                }
            }
        }

        &:focus-visible {
            outline: 1px solid var(--color-focus);
            outline-offset: 3px;
        }

        &.playing {
            .fa-volume-up {
                &:before {
                    background-image: url("./../icons/pause.svg");
                }
            }
        }
    }

    &__question {
        @include font-size(18px, 27px);
        color: var(--color-neutral-7);
        position: relative;
        margin-bottom: 8px;

        @include breakpoint(mobile) {
            margin-bottom: 16px;
            @include font-size(16px, 22px);
        }

        &:not([lang="en"]) {
            padding-right: 50px;
        }
    }

    .answers {
        margin-top: 8px;
    }

    &__answer-btn {
        padding: 9px 13px;
        color: var(--color-primary-1);
        background-color: var(--color-white);
        border: 1px solid var(--color-primary-1);
        border-radius: 6px;
        min-width: 160px;
        @include font(barlow, medium);
        @include font-size(22px, 30px);
        margin-right: 5px;
        margin-bottom: 16px;

        @include breakpoint(tablet) {
            min-width: 110px;
        }

        @include breakpoint(mobile) {
            min-width: 112px;
            margin-right: 6px;
        }

        &[data-icon] {
            text-align: left;

            @include breakpoint(tablet) {
                min-width: 150px;
            }
        }

        &:hover {
            background-color: var(--color-neutral-1);
            border-color: var(--color-primary-2);
            color: var(--color-primary-1);
        }

        &:active,
        &.selected {
            background-color: var(--color-primary-1);
            border-color: var(--color-primary-1);
            color: var(--color-white);

            .fa-check {
                &:before {
                    content: url("./../icons/check-active.svg");
                }
            }

            .fa-times {
                &:before {
                    content: url("./../icons/cross-active.svg");
                }
            }

            .fa-question-mark {
                &:before {
                    content: url("./../icons/question-mark-active.svg");
                }
            }
        }

        &:focus {
            outline: 0;
        }

        &:focus-visible {
            outline: 1px solid var(--color-focus);
            outline-offset: 2px;
        }

        .fa {
            line-height: 20px;
            vertical-align: text-bottom;

            &:before {
                margin-right: 9px;
                vertical-align: middle;
            }
        }

        .fa-check {
            &:before {
                content: url("./../icons/check-default.svg");
            }
        }

        .fa-times {
            &:before {
                content: url("./../icons/cross-default.svg");
            }
        }

        .fa-question-mark {
            &:before {
                content: url("./../icons/question-mark.svg");
            }
        }
    }

    .result-section:not([lang="en"]) {
        .interactive-tool__score-content {
            @include breakpoint(mobile) {
                padding-top: 80px;
            }
        }
    }

    &__score-content {
        color: var(--color-white);
        background-image: linear-gradient(
            268.75deg, #411e75 -54%, #90278e 123.85%
        );
        border-radius: 8px;
        margin-bottom: 20px;
        text-align: center;
        padding: 32px;
        position: relative;

        @include breakpoint(mobile) {
            padding: 20px 12px 12px;
        }

        .audio-button {
            background-color: transparent;
            border: 0;
            box-shadow: none;
            top: 37px;
            right: 73px;

            @include breakpoint(tablet) {
                right: 27px;
            }

            @include breakpoint(mobile) {
                left: 50%;
                top: 21px;
                transform: translateX(-50%);
            }

            .fa-volume-up {
                &:before {
                    background-image: url("./../icons/player-white.svg");
                    background-size: 28px;
                    width: 28px;
                    height: 28px;
                }
            }
        }
    }

    &__score-result {
        color: var(--color-primary-1);
        background-color: var(--color-neutral-1);
        width: fit-content;
        margin-left: auto;
        margin-right: auto;
        padding: 16px 32px;
        border-radius: 100px;

        @include breakpoint(mobile) {
            padding-left: 12px;
            padding-right: 12px;
            border-radius: 8px;
            width: auto;
        }
    }

    &__score-total {
        @include font-size(24px, 24px);
        margin-bottom: 16px;

        span {
            text-transform: uppercase;
        }
    }

    &__score-bar {
        display: flex;
        align-items: center;
        justify-content: center;

        @include breakpoint(mobile) {
            flex-direction: column;
        }
        ul {
            list-style: none;
            width: 250px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 0 9px;
            padding-left: 0;
            padding-right: 0;
            position: relative;
            z-index: 1;

            @include breakpoint(mobile) {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }

            &:after {
                content: "";
                position: absolute;
                z-index: -1;
                height: 1px;
                width: 100%;
                background-color: var(--color-primary-1);
                top: 50%;
                left: 0;
            }
        }

        li {
            width: 31px;
            height: 31px;
            border-radius: 50%;
            border: 1px solid var(--color-primary-1);
            color: var(--color-primary-1);
            @include font-size(16px, 24px);
            background-color: var(--color-neutral-3);
            display: flex;
            align-items: center;
            justify-content: center;

            @include breakpoint(mobile) {
                width: 24px;
                height: 24px;
            }

            &.on {
                background-color: var(--color-score-on);
                color: var(--color-white);
                @include font(barlow, semibold);
                border-color: var(--color-score-on);
                @include font-size(20px, 24px);

                @include breakpoint(mobile) {
                    width: 32px;
                    height: 32px;
                }
            }
        }

        .hint {
            @include breakpoint(mobile) {
                display: flex;
                width: 100%;
                justify-content: space-between;
                margin-top: 16px;
            }
        }
    }

    &__score-guide {
        max-width: 562px;
        margin-left: auto;
        margin-right: auto;

        .text-medium {
            @include font-size(20px, 24px);
        }
    }

    &__downloads {
        background-color: var(--color-neutral-1);
        padding: 32px 24px;
        border-radius: 8px;
        margin-bottom: 20px;

        @include breakpoint(desktop) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 30px;
        }

        @include breakpoint(mobile) {
            padding-left: 16px;
            padding-right: 16px;
        }

        .download {
            &:first-of-type {
                @include breakpoint(tablet) {
                    margin-bottom: 64px;
                }

                @include breakpoint(mobile) {
                    margin-bottom: 32px;
                }
            }

            p {
                padding-bottom: 30px;
                margin-bottom: 0;
                margin-top: 28px;
                max-width: 384px;
            }
        }

        a {
            color: var(--color-primary-2);
            @extend .btn-secondary;
            padding-right: 35px;
            padding-bottom: 5px;
            padding-top: 0;

            &:after {
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                transition: right var(--transition-time);
            }

            &:hover {
                &:after {
                    right: -6px;
                }
            }

            span {
                display: block;
                position: absolute;
                top: calc(100% + 8px);
                left: 0;
                color: var(--color-primary-1);
                direction: ltr;

                &:before {
                    content: url("./../icons/pdf.svg");
                    margin-right: 6px;
                    display: inline-block;
                    vertical-align: middle;
                }
            }
        }
    }
    .download {
        &__heading {
            @include font-size(22px, 24px);
            color: var(--color-primary-1);
            margin-bottom: 28px;
        }
    }

    .nutrition-calc {
        fieldset.off,
        .result-section.off {
            overflow: hidden;
            opacity: 0;
            height: 1px;
            padding: 0 !important;
            margin: 0 !important;
            display: none;
        }

        label {
            display: block;
            margin-bottom: 10px;
        }

        .link-dark a {
            color: inherit;
            font-weight: 500;
        }

        .summary {
            font-weight: 700;
            position: relative;

            &:not([lang="en"]) {
                padding-right: 49px;
                &[dir="rtl"] {
                    padding-right: 0;
                    padding-left: 49px;

                    .audio-button {
                        left: 0;
                        right: auto;
                    }
                }
            }
        }

        .intro {
            @include breakpoint(not-mobile) {
                &:not([lang="en"]) {
                    padding-right: 49px;

                    &[dir="rtl"] {
                        padding-right: 0;
                        padding-left: 49px;
                    }
                }
            }
        }

        .question-0-1 {
            div[dir="rtl"] .translated-original {
                padding-left: 40px;
                padding-right: 0;
            }
        }

        .interactive-tool {
            &__tool-content {
                @include breakpoint(tablet) {
                    padding: 24px;
                }
            }

            &__question-contents[dir="rtl"] {
                padding-right: 112px;
                padding-left: 0;

                @include breakpoint(tablet) {
                    padding-right: 80px;
                    padding-left: 0;
                }

                @include breakpoint(mobile) {
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            &__tool-header[dir="rtl"] {
                .audio-button {
                    left: 0;
                    right: auto;
                }
            }

            &__question[dir="rtl"] {
                padding-left: 50px;
                padding-right: 0;

                & + .translated-original {
                    text-align: right;
                    direction: ltr;
                }

                .audio-button {
                    left: 0;
                    right: auto;
                }
            }

            &__question-counter[dir="rtl"] {
                right: 0;
                left: auto;
            }

            &__answer-btn[dir="rtl"] {
                @include breakpoint(mobile) {
                    margin-right: 0;
                    margin-left: 6px;
                }

                &[data-icon] {
                    text-align: right;
                }

                .fa:before {
                    margin-left: 9px;
                    margin-right: 0;
                }
            }

            &__score-total[dir="rtl"] {
                .audio-button {
                    @include breakpoint(not-mobile) {
                        right: auto;
                        left: 73px;
                    }

                    @include breakpoint(tablet) {
                        left: 27px;
                    }
                }
            }

            &__downloads {
                .download p {
                    text-align: left;
                }
            }
        }
    }

    .logos {
        display: flex;
        justify-content: space-between;
        padding: 24px 64px;
        align-items: center;
        margin-bottom: 48px;

        @include breakpoint(tablet) {
            padding-left: 0;
            padding-right: 0;
        }

        @include breakpoint(mobile) {
            flex-direction: column;
            padding-left: 0;
            padding-right: 0;
        }

        img {
            @include breakpoint(mobile) {
                display: block;
            }

            &:not(:last-of-type) {
                @include breakpoint(mobile) {
                    margin-bottom: 25px;
                }
            }
        }

        .vcmc {
            width: 123px;

            @include breakpoint(tablet) {
                width: 87px;
            }

            @include breakpoint(mobile) {
                width: 113px;
            }
        }

        .petermac {
            width: 179px;

            @include breakpoint(tablet) {
                width: 143px;
            }

            @include breakpoint(mobile) {
                width: 186px;
            }
        }

        .wcmics {
            width: 245px;

            @include breakpoint(tablet) {
                width: 209px;
            }

            @include breakpoint(mobile) {
                width: 272px;
            }
        }

        .vsg {
            width: 130px;

            @include breakpoint(tablet) {
                width: 94px;
            }

            @include breakpoint(mobile) {
                width: 122px;
            }
        }
    }
}
