/*
==========================================================================
   #Page header
========================================================================== */
:root {
    --header-bg: #fff;
    --logo-bg: #fff;
    --main-nav-bg: #411e75;
    --search--text: #fff;
    --nav-underline: #0c1b29;
}

.site-header {
    position: relative;
    width: 100%;
    z-index: z-index(header);
    background-color: var(--header-bg);

    > .wrapper {
        display: flex;
        align-items: center;
        position: static;

        @include breakpoint(desktop) {
            padding-top: 32px;
            padding-bottom: 27px;
        }

        @include breakpoint(not-desktop) {
            justify-content: space-between;
        }

        @include breakpoint(tablet) {
            padding-top: 18px;
            padding-bottom: 18px;
        }

        @include breakpoint(mobile) {
            padding-top: 23px;
            padding-bottom: 22px;
        }
    }

    .logo {
        width: 428px;
        height: 120px;
        background-color: var(--logo-bg);
        border-bottom-right-radius: 80px;
        box-shadow: 4px 0 9px rgba(0, 0, 0, 0.25);
        position: absolute;
        top: 0;
        left: 0;
        z-index: z-index(logo);
        display: flex;
        align-items: center;
        justify-content: center;
        @include breakpoint(desktop) {
            max-width: 27%;
        }

        @include breakpoint(tablet) {
            width: 235px;
            height: 74px;
            border-bottom-right-radius: 64px;
        }

        @include breakpoint(mobile) {
            width: 167px;
            height: 60px;
            border-bottom-right-radius: 36px;
            transition: width var(--transition-time);
        }

        img {
            width: 286px;

            @include breakpoint(tablet) {
                width: 160px;
            }

            @include breakpoint(mobile) {
                width: 126px;
            }
        }

        .logo-lg {
            display: block;
        }

        .logo-sm {
            display: none;
            width: 36px;
        }
    }

    &.search-expanded {
        .logo {
            @include breakpoint(mobile) {
                width: 67px;
            }
        }
        .logo-lg {
            @include breakpoint(mobile) {
                display: none;
            }
        }

        .logo-sm {
            @include breakpoint(mobile) {
                display: block;
            }
        }
    }

    &__main-nav {
        margin-left: auto;
        @include breakpoint(desktop) {
            background-color: var(--main-nav-bg);
            display: flex;
            justify-content: flex-end;
            position: relative;
        }
    }

    &__secondary-nav {
        .page-navigation-menu {
            @include breakpoint(not-desktop) {
                display: none;
            }
        }
    }
}

.menu__search {
    margin-right: 13px;
    margin-left: 16px;

    @include breakpoint(tablet) {
        margin-left: auto;
        margin-right: 50px;
    }

    @include breakpoint(mobile) {
        display: none;
    }

    button {
        background: none;
        border: 0;
        @include font-size(14px, 21px);
        color: var(--search--text);
        padding: 3px 11px 3px 37px;
        position: relative;
        margin-bottom: 0;
        border-radius: 0;
        border-left: 1px solid var(--color-white);

        @include breakpoint(not-desktop) {
            padding-bottom: 12px;
            padding-top: 28px;
        }

        &:before {
            content: "";
            margin-right: 16px;
            vertical-align: middle;
            background-image: url("./../icons/search.svg");
            background-size: contain;
            background-repeat: no-repeat;
            width: 15px;
            height: 15px;
            display: inline-block;
        }

        &:after {
            content: "";
            display: block;
            left: 6px;
            right: 6px;
            height: 27px;
            background: var(--nav-underline);
            position: absolute;
            top: 100%;
            transform: scaleY(0);
            transition: transform 0.2s;

            @include breakpoint(not-desktop) {
                height: 4px;
            }
        }

        &:hover,
        &:focus {
            background-color: transparent;
            color: var(--search--text);
        }

        &[aria-expanded="true"],
        &:hover,
        &:focus {
            &:after {
                transform: scaleY(1);
            }
        }

        &[aria-expanded="true"] {
            &:before {
                background-image: url("./../icons/cross.svg");
            }

            &:after {
                @include breakpoint(desktop) {
                    height: 8px;
                    top: calc(100% + 19px);
                }
            }
        }

        .icon-search {
            display: none;
        }
    }
}
