.block-testimonials {
    --color-title: #411e75;
    --color-border: #260950;
    margin-bottom: 96px;

    @include breakpoint(mobile) {
        margin-bottom: 40px;
    }

    &__heading {
        color: var(--color-title);
        text-align: center;
        margin-bottom: 32px;

        @include breakpoint(tablet) {
            @include font-size(32px, 38px);
        }
    }

    &__wrapper {
        display: grid;
        gap: 0;
        grid-template-columns: repeat(auto-fit, minmax(212px, 1fr));

        @include breakpoint(not-desktop) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    &__item {
        border-right: 1px solid var(--color-border);
        padding: 48px 24px 24px;
        position: relative;

        &-name {
            margin-bottom: 0;
            color: var(--color-title);
        }

        &:before {
            content: " ";
            display: block;
            left: 24px;
            top: 8px;
            height: 16px;
            width: 22px;
            position: absolute;
            background-image: url("./../img/testimonial-icon.svg");
            background-repeat: repeat-x;
            background-position: center;
            background-size: 100% auto;

            @include breakpoint(not-desktop) {
                left: 0;
            }
        }

        &:last-child {
            border: 0;
            padding-right: 0;
        }

        &:first-child {
            padding-left: 0;

            &:before {
                left: 0;
            }
        }

        @include breakpoint(not-desktop) {
            border-right: 0;
            padding-left: 0;
            padding-right: 0;
            border-bottom: 1px solid var(--color-border);
            margin-bottom: 33px;

            &:last-child {
                margin-bottom: 0;
                padding-bottom: 0;
            }
        }
    }
}
