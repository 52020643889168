.contact-us-form {
    --color-border: #ad3192;
    margin-bottom: 20px;
    &__text {
        width: 370px;
    }

    &__form {
        width: calc(100% - 370px - 124px);
        padding-left: 124px;

        @include breakpoint(not-desktop) {
            padding-left: 0;
            .formRow {
                margin-bottom: 16px;
            }
        }

        @include breakpoint(mobile) {
            .formSpan3,
            .formSpan9,
            .formSpan6 {
                margin-bottom: 16px;
            }
        }
    }

    .header-contact__note {
        border-left: 2px solid var(--color-border);
        padding: 3px 20px;
        margin-top: 20px;

        @include breakpoint(tablet) {
            margin-bottom: 32px;
        }
        @include breakpoint(mobile) {
            margin-bottom: 22px;
        }
    }

    input:focus,
    input:active,
    select:focus,
    select:active,
    textarea:focus,
    textarea:active {
        outline-offset: 0;
        outline: 2px solid var(--color-primary-1);
    }
    .rsform-block-email {
        padding-right: 20px;

        @include breakpoint(mobile) {
            padding-right: 0;
        }
    }
}

.contact-us-page {
    .fields-container,
    .item-tag,
    .item-date,
    .news-item > .h2 {
        display: none;
    }

    @include breakpoint(mobile) {
        .block-cta__btn {
            width: 100%;
        }
    }

    .tab-indicator {
        background-image: url("./../icons/tab-arrow-white.svg");
    }

    .block-tabs-accordion {
        margin-top: 0;
    }
}
