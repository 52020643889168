.block-multi-cards {
    --spacing-block-top: 50px;
    --spacing-block-bottom: 50px;
    --spacing-block-btn: 90px;
    --gap-column: 16px;
    --spacing-img: 10px;
    --spacing-title: 10px;
    --spacing-heading: 16px;
    --spacing-btn: 20px;
    padding-top: var(--spacing-block-top);
    padding-bottom: var(--spacing-block-bottom);
    text-align: center;

    &__heading {
        text-align: center;
        margin-bottom: var(--spacing-heading);
        margin-top: 0;
    }

    &__btn {
        text-align: center;
        margin-top: var(--spacing-block-btn);
    }

    .cfeeds {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(212px, 1fr));
        gap: var(--gap-column);
    }

    .cfeed-item {
        display: block;
        text-decoration: none;

        &__img {
            margin-bottom: var(--spacing-img);
            width: 100%;
            height: 200px;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        &__title {
            margin-top: 0;
            margin-bottom: var(-space-title);
        }

        &__introtext {
            margin-bottom: 0;
        }

        &__btn {
            margin-top: var(--spacing-btn);
        }
    }

    &--boxed {
        --spacing-btn: 24px;
        --spacing-box-h: 20px;
        --spacing-box-top: 20px;
        --spacing-box-bottom: 28px;
        --color-border: #d9d9d9;
        --spacing-block-btn: 26px;

        .cfeed-item {
            border: 1px solid var(--color-border);
            text-align: left;

            &__text-wrapper {
                padding: var(--spacing-box-top) var(--spacing-box-h)
                    var(--spacing-box-bottom);
            }

            &__btn {
                text-decoration: underline;
            }
        }

        &.blocks-4 {
            .cfeed-item__img {
                width: 100%;
                height: 140px;
            }
        }

        .block-multi-cards__btn {
            text-align: left;
        }
    }

    &--counter {
        --spacing-block-btn: 62px;
        --spacing-heading: 44px;
        --counter-size: 50px;
        --counter-top: -28px;
        --color-counter-txt: #0c1b29;
        --color-counter-bg: #d9d9d9;

        .cfeed-item {
            position: relative;
            &__counter {
                position: absolute;
                left: 50%;
                top: var(--counter-top);
                transform: translateX(-50%);
                width: var(--counter-size);
                aspect-ratio: 1;
                line-height: var(--counter-size);
                background-color: var(--color-counter-bg);
                color: var(--color-counter-txt);
                border-radius: 50%;
            }
        }
    }

    &--text {
        .cfeed-item {
            text-align: left;

            &__btn {
                text-decoration: underline;
            }
        }

        .block-multi-cards__btn {
            text-align: left;
        }
    }
}
