.block-cta {
    --color-gradient-bg: linear-gradient(
        0deg, rgba(65, 30, 117, 0.8), rgba(65, 30, 117, 0.8)
    ),
        linear-gradient(268.75deg, #411e75 -54%, #90278e 123.85%);
    border-radius: 8px;
    --text-color: #fff;
    width: 100%;
    text-align: center;
    padding: 45px 31px;
    background: var(--color-gradient-bg);
    color: var(--text-color);

    @include breakpoint(mobile) {
        padding: 33px 15px;
    }

    &__title {
        color: var(--text-color);
        margin-bottom: 32px;
        @include font-size(28px, 30px);

        @include breakpoint(mobile) {
            @include font-size(20px, 22px);
        }
    }

    .btn-hero {
        padding-left: 60px;
        padding-right: 92px;
        background-position: right 60px center;

        &:hover {
            background-position: right 56px center;
        }

        @media screen and (max-width: 325px) {
            padding-left: 30px;
            padding-right: 62px;
            background-position: right 30px center;

            &:hover {
                background-position: right 26px center;
            }
        }
    }
}
