.donation-page {
    --color-intro-bg: #f0f1fa;
    --color-title: #411e75;
    --color-subtitle: #92278f;
    --color-block-bg: #dcdff3;
    --color-border: #411e75;
    --spacing-mobile: 48px;
    --banner-height-desktop: 733px;
    --banner-height-tablet: 490px;
    --banner-height-mobile: 500px;
    --banner-img-size: 800px;
    --banner-content-padding-desktop: 94px 0 84px 0;
    --banner-content-padding-tablet: 74px 0 34px 0;
    --banner-content-padding-mobile: 57px 0 30px 0;
    --banner-text-color: #fff;
    --intro-padding-block-top-bottom: 75px;
    --intro-padding-block-top-bottom-mobile: 32px;
    --intro-margin-block-bottom: 40px;
    --intro-margin-block-bottom-mobile: 28px;
    --story-padding-block-top: 40px;
    --story-padding-block-top-mobile: 28px;
    --story-padding-block-bottom: 98px;
    --story-padding-block-bottom-tablet: 64px;
    --story-img-size: 588px;
    --story-img-size-tablet: 200px;
    --story-spacing-heading: 53px;
    --story-spacing-heading-tablet: 43px;
    --story-spacing-heading-mobile: 32px;
    --story-spacing-desc: 40px;
    --story-spacing-desc-tablet: 32px 16px 25px;
    --story-spacing-desc-mobile: 32px 16px 16px;
    --story-image-bg: #fff;

    .btn-primary {
        padding-left: 122px;
        padding-right: 122px;
        background-position: right 122px center;

        &:hover {
            background-position: right 115px center;
            padding-left: 115px;
        }

        @include breakpoint(mobile) {
            padding-left: 74px;
            padding-right: 74px;
            background-position: right 74px center;

            &:hover {
                background-position: right 67px center;
                padding-left: 67px;
            }
        }

        @media screen and (max-width: 320px) {
            padding-left: 44px;
            padding-right: 44px;
            background-position: right 44px center;

            &:hover {
                background-position: right 36px center;
                padding-left: 37px;
            }
        }
    }

    .btn-hero {
        padding-right: 60px;

        @media screen and (max-width: 320px) {
            padding-left: 30px;
            padding-right: 30px;
        }

        &:hover {
            background-position: right 52px center;
            padding-left: 52px;
        }
    }

    &__title {
        color: var(--color-title);
        text-align: center;

        @include breakpoint(tablet) {
            @include font-size(32px, 38px);
        }
    }

    &-banner {
        position: relative;
        @include breakpoint(desktop) {
            min-height: var(--banner-height-desktop);
        }

        &:after {
            content: " ";
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            right: calc(-100vw + 1180px);
            z-index: -2;
            background-repeat: repeat-x;
            background-size: auto 100%;
            left: 0;
            @include breakpoint(desktop) {
                background-image: url("./../img/donation-page-banner-bg-repeat.jpg");
                left: calc(-100vw + 1180px);
            }
        }
        &:before {
            content: " ";
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            z-index: -2;
            background-repeat: repeat-x;
            background-size: auto 100%;
            @include breakpoint(desktop) {
                background-image: url("./../img/donation-page-banner-bg-repeat.jpg");
                left: calc(-100vw + 1180px);
            }
        }

        &__wrapper {
            &:before {
                content: " ";
                display: block;
                position: absolute;
                height: 100%;
                z-index: -1;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                right: 0;
                top: 0;

                @include breakpoint(desktop) {
                    background-image: url("./../img/donation-page-banner-bg.jpg");
                    left: 0;
                    width: 100vw;
                }

                @include breakpoint(tablet) {
                    background-image: url("./../img/donation-page-banner-bg-tablet.jpg");
                    left: -30px;
                    width: 100vw;
                }

                @include breakpoint(mobile) {
                    background-image: url("./../img/donation-page-banner-bg-mobile.jpg");
                    left: -24px;
                    width: 100vw;
                }
            }

            @include breakpoint(not-desktop) {
                &:after {
                    content: " ";
                    display: block;
                    position: absolute;
                    z-index: -1;
                    background-repeat: no-repeat;
                    background-size: 100% 100%;
                    bottom: 0;

                    @include breakpoint(tablet) {
                        height: 632px;
                        width: 742px;
                        right: -30px;
                        background-image: url("./../img/donation-page-banner-shape-tablet.svg");
                    }

                    @include breakpoint(mobile) {
                        height: 320px;
                        width: 467px;
                        right: -24px;
                        background-image: url("./../img/donation-page-banner-shape-mobile.svg");
                    }
                }
            }
        }

        &__content {
            color: var(--banner-text-color);

            &-title {
                color: var(--banner-text-color);

                @include breakpoint(mobile) {
                    @include font-size(30px, 40px);
                }
            }

            @include breakpoint(desktop) {
                width: 510px;
                padding: var(--banner-content-padding-desktop);
                @include font-size(22px, 24px);

                &-embed {
                    margin-top: 44px;
                }
            }

            @include breakpoint(tablet) {
                padding: var(--banner-content-padding-tablet);
                @include font-size(18px, 20px);

                &-embed {
                    margin-top: 30px;
                }
            }

            @include breakpoint(mobile) {
                padding: var(--banner-content-padding-mobile);
                @include font-size(18px, 20px);

                &-embed {
                    margin-top: 15px;
                }
            }
        }

        &__img {
            @include breakpoint(desktop) {
                position: absolute;
                top: 94px;
                right: calc((100vw - var(--wrapper-width) + 20px) / -2);
                left: 594px;
                height: calc(100% - 94px);
                overflow: hidden;

                img {
                    border-radius: 300px 0 0;
                    object-fit: cover;
                    width: 100%;
                    height: 100%;
                }
            }

            @include breakpoint(tablet) {
                width: calc(100% + 30px);
                height: 200px;

                picture {
                    right: -32px;
                    position: absolute;
                    width: calc(100% + 30px);
                }

                img {
                    object-fit: cover;
                    width: 100%;
                    height: 200px;
                    border-top-left-radius: 100px;
                }
            }

            @include breakpoint(mobile) {
                width: calc(100% + 24px);
                height: 159px;

                picture {
                    right: -24px;
                    position: absolute;
                    width: calc(100% + 24px);
                }

                img {
                    object-fit: cover;
                    border-top-left-radius: 100px;
                    width: 100%;
                    height: 159px;
                }
            }
        }
    }

    &__intro {
        background-color: var(--color-intro-bg);
        padding-top: var(--intro-padding-block-top-bottom);
        padding-bottom: var(--intro-padding-block-top-bottom);
        margin-bottom: var(--intro-margin-block-bottom);
        position: relative;

        @include breakpoint(mobile) {
            margin-bottom: var(--intro-margin-block-bottom-mobile);
            padding-top: var(--intro-padding-block-top-bottom-mobile);
            padding-bottom: var(--intro-padding-block-top-bottom-mobile);
        }

        &:before,
        &:after {
            content: " ";
            display: block;
            height: 100%;
            position: absolute;
            top: 0;
            background: inherit;
            z-index: -1;
        }

        &:before {
            left: calc(-100vw + 1180px);
            right: 0;

            @include breakpoint(not-desktop) {
                left: calc(-100vw - 768px);
            }
        }

        &:after {
            right: calc(-100vw + 1180px);
            left: 0;

            @include breakpoint(not-desktop) {
                right: calc(-100vw - 768px);
            }
        }

        @include breakpoint(tablet) {
            padding-left: 32px;
            padding-right: 32px;
        }

        @include breakpoint(mobile) {
            padding-left: 27px;
            padding-right: 27px;
        }

        &-wrapper {
            text-align: center;
            max-width: 750px;
            margin: auto;
        }

        &-bump {
            position: absolute;
            bottom: -14px;
            left: calc(50% - 86px);
        }

        h3 {
            color: var(--color-subtitle);
            @include font-size(22px, 24px);

            @include breakpoint(tablet) {
                @include font-size(18px, 20px);
            }

            @include breakpoint(mobile) {
                @include font-size(16px, 19px);
                margin-bottom: 14px;
            }
        }
    }

    &__stories {
        padding-top: var(--story-padding-block-top);
        padding-bottom: var(--story-padding-block-bottom);

        @include breakpoint(tablet) {
            padding-bottom: var(--story-padding-block-bottom-tablet);
        }

        @include breakpoint(mobile) {
            padding-top: var(--story-padding-block-top-mobile);
            padding-bottom: var(--spacing-mobile);
        }

        &-title {
            margin-bottom: var(--story-spacing-heading);

            @include breakpoint(tablet) {
                margin-bottom: var(--story-spacing-heading-tablet);
            }

            @include breakpoint(mobile) {
                margin-bottom: var(--story-spacing-heading-mobile);
            }
        }

        &__wrapper {
            @include breakpoint(tablet) {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 21px;
                grid-auto-rows: 1fr;

                &:last-child > .story-item {
                    margin-bottom: 0;
                }
            }
        }
    }

    /* STORIES BLOCK */
    .story-item {
        background: var(--color-block-bg);
        margin-bottom: 32px;

        &__last {
            @include breakpoint(desktop) {
                margin-bottom: 0;
            }

            @include breakpoint(mobile) {
                margin-bottom: 0;
            }
        }

        @include breakpoint(tablet) {
            border-radius: 0 0 12px 12px;
        }

        @include breakpoint(mobile) {
            margin-bottom: 25px;
        }

        &__wrapper {
            padding-left: 0;
            padding-right: 0;

            &.image_right {
                grid-template-columns: 1fr minmax(
                    min(var(--story-img-size), 50%), 1fr
                );

                img {
                    border-radius: 0 12px 80px 0;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    @include breakpoint(not-desktop) {
                        height: 200px;
                        border-radius: 12px 80px 0 0;
                        object-fit: cover;
                    }
                }
            }

            &.image_left {
                img {
                    border-radius: 80px 0 0 12px;
                    width: 100%;
                    height: 100%;
                    object-fit: cover;

                    @include breakpoint(not-desktop) {
                        height: 200px;
                        border-radius: 12px 80px 0 0;
                    }
                }
            }

            @include breakpoint(desktop) {
                display: grid;
                grid-template-columns: minmax(min(var(--story-img-size), 50%), 1fr)
                    1fr;
            }

            @include breakpoint(mobile) {
                display: flex;
                flex-direction: column;
            }
        }

        &__text {
            padding: var(--story-spacing-desc);

            strong {
                @include font-size(18px, 25px);
            }

            @include breakpoint(tablet) {
                padding: var(--story-spacing-desc-tablet);
            }

            @include breakpoint(mobile) {
                padding: var(--story-spacing-desc-mobile);
            }
        }

        &__title {
            color: var(--color-subtitle);

            @include breakpoint(tablet) {
                @include font-size(30px, 31px);
            }
        }

        &__img {
            background: var(--story-image-bg);
            display: block;

            &-image_right {
                @include breakpoint(desktop) {
                    order: 1;
                }
            }

            @include breakpoint(tablet) {
                height: 200px;
            }
        }

        &__btn {
            text-align: center;
            margin-bottom: 32px;

            @include breakpoint(mobile) {
                margin-bottom: 25px;
            }
        }
    }

    /* CAROUSEL BLOCK OVERRIDES */
    .block-cards-carousel {
        &__donation {
            margin-top: 0;
            padding-top: 64px;
            border-top: 1px solid var(--color-border);

            @include breakpoint(mobile) {
                padding-top: var(--spacing-mobile);
            }

            .cfeed-item {
                &__bottom {
                    &:after {
                        display: none;
                    }
                    padding: 44px 32px 32px;
                }
            }
        }

        &__top {
            text-align: center;
            max-width: 771px;
            margin: auto auto 64px;

            .btn-primary {
                margin-top: 32px;
            }
        }

        &__desc {
            @include font-size(22px, 24px);

            > p:last-child {
                margin-bottom: 0;
            }
        }

        &__heading {
            margin-bottom: 16px;
        }
    }
}
