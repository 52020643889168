:root {
    --spacing-y-3xs: 4px;
    --spacing-y-xxs: 8px;
    --spacing-y-xs: 12px;
    --spacing-y-s: 16px;
    --spacing-y-m: 24px;
    --spacing-y-l: 32px;
    --spacing-y-xl: 48px;
    --spacing-y-xxl: 64px;
    --spacing-y-3xl: 80px;

    @include breakpoint(mobile) {
        --spacing-y-3xs: 4px;
        --spacing-y-xxs: 8px;
        --spacing-y-xs: 12px;
        --spacing-y-s: 16px;
        --spacing-y-m: 20px;
        --spacing-y-l: 24px;
        --spacing-y-xl: 32px;
        --spacing-y-xxl: 48px;
        --spacing-y-3xl: 56px;
    }
}
/* stylelint-disable unit-allowed-list, unit-no-unknown */
$spacing: (
    3xs var(--spacing-y-3xs),
    xxs var(--spacing-y-xxs),
    xs var(--spacing-y-xs),
    s var(--spacing-y-s),
    m var(--spacing-y-m),
    l var(--spacing-y-l),
    xl var(--spacing-y-xl),
    xxl var(--spacing-y-xxl),
    3xl var(--spacing-y-3xl)
);
/* stylelint-enable unit-allowed-list, unit-no-unknown */
.mg-b {
    @each $y in $spacing {
        &-#{nth($y, 1)} {
            margin-bottom: #{nth($y, 2)} !important;
        }
    }
}

.mg-t {
    @each $y in $spacing {
        &-#{nth($y, 1)} {
            margin-top: #{nth($y, 2)} !important;
        }
    }
}

.mg-y {
    @each $y in $spacing {
        &-#{nth($y, 1)} {
            margin-bottom: #{nth($y, 2)} !important;
            margin-top: #{nth($y, 2)} !important;
        }
    }
}

.pd-b {
    @each $y in $spacing {
        &-#{nth($y, 1)} {
            padding-bottom: #{nth($y, 2)} !important;
        }
    }
}

.pd-t {
    @each $y in $spacing {
        &-#{nth($y, 1)} {
            padding-top: #{nth($y, 2)} !important;
        }
    }
}

.pd-y {
    @each $y in $spacing {
        &-#{nth($y, 1)} {
            padding-bottom: #{nth($y, 2)} !important;
            padding-top: #{nth($y, 2)} !important;
        }
    }
}
