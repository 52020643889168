/*
==========================================================================
   #Variables
   Only add Global variables(Variables that are being shared between different modules) here. Local variables should be added into the related module
========================================================================== */

:root {
    /*
    * page head
    */
    --head-height: auto;

    /*
    * Wrapper
    */
    --wrapper-width: 1200px;
    --wrapper-small-width: 916px;
    --wrapper-padding-h: 10px;

    /*
    * Drop-down arrow transition time
    */
    --arrow-transition-time: 0.2s;

    /*
    * Global transition time
    */
    --transition-time: 0.2s;
    --transition-time-slow: 0.5s;
    --transition-time-fast: 5ms;

    /*
    * Global focus width
    */
    --focus-border-width: 1px;
    --outline-offset: 2px;

    /*
    * hr
    */
    --hr-border-width: 1px;

    /*
    * side menu width
    */
    --side-menu-width: 270px;
}

@include breakpoint(tablet) {
    :root {
        --wrapper-padding-h: 30px;
        --head-height: 96px;
    }
}

@include breakpoint(mobile) {
    :root {
        --wrapper-padding-h: 24px;
        --head-height: 96px;
    }
}

$transition-time: 0.2s;
$transition-time-fast: 5ms;
