body {
    .ui-widget.ui-autocomplete {
        background-color: var(--color-neutral-2);
        padding: 9px 8px 4px;
        border-radius: 4px;
        border: 0;
        width: 241px !important;
        margin-top: 12px;

        @include breakpoint(mobile) {
            width: 235px !important;
        }

        .ui-menu-item-wrapper {
            color: var(--color-secondary-2);
            @include font-size(14px, 17px);
            border-radius: 4px;
            padding: 6px;
            margin-bottom: 8px;
            border: 0;

            &.ui-state-active {
                color: var(--color-white);
                background-color: var(--color-primary-2);
            }
        }
    }
}
