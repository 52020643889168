.indexedsearch-form {
    --color-bg: #f0f1fa;
    --color-focus: #ad3192;
    --color-focus-outline: #aa52ff;

    &__form {
        margin-bottom: 57px;

        @include breakpoint(mobile) {
            margin-bottom: 48px;
        }
    }

    &__heading {
        @include font-size(24px, 24px);
        @include font(barlow, medium);
        margin-bottom: 18px;
        display: block;
    }
    &__row {
        display: flex;
        align-items: center;
        position: relative;

        @include breakpoint(mobile) {
            padding-bottom: 44px;
        }
    }

    &__fields {
        display: flex;
        align-items: center;
        background-color: var(--color-bg);
        border: 1px solid var(--color-primary-1);
        border-right: 0;
        border-top-left-radius: 6px;
        border-bottom-left-radius: 6px;
        width: calc(100% - 198px);

        @include breakpoint(mobile) {
            width: calc(100% - 70px);
        }
    }

    &__btn[type="submit"] {
        width: 198px;
        margin-left: auto;
        @include font-size(20px, 24px);
        border-radius: 0 100px 100px 0;
        padding-top: 14px;
        padding-bottom: 14px;
        padding-left: 73px;
        background-image: url("./../icons/search.svg");
        background-position: 42px center;
        background-repeat: no-repeat;

        @include breakpoint(mobile) {
            font-size: 0;
            color: transparent;
            height: 40px;
            width: 70px;
            padding-left: 27px;
            background-position: center;
            margin-bottom: 0;
        }

        &:focus {
            background-color: var(--color-focus);
            outline-color: var(--color-focus-outline);
            outline-offset: 3px;
        }
    }

    &__input[type="text"] {
        border: 0;
        flex: 1;
        padding-top: 14px;
        padding-bottom: 14px;

        @include breakpoint(mobile) {
            padding-top: 8px;
            padding-bottom: 8px;
        }
    }

    &__checkbox {
        border-left: 1px solid var(--color-primary-1);
        padding-left: 16px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-right: 32px;

        @include breakpoint(mobile) {
            position: absolute;
            top: 56px;
            border-left: 0;
            padding-left: 0;
        }

        input[type="checkbox"] + label {
            margin-bottom: 0;
        }
    }
}

.advanced-filter {
    margin-top: 24px;

    @include breakpoint(mobile) {
        margin-top: 30px;
    }

    &__toggle {
        display: inline-flex;
        cursor: pointer;
        text-decoration: underline;
        @include font-size(16px, 19px);
        color: var(--color-primary-1);
        background-color: transparent;
        padding: 0;
        text-decoration-color: var(--color-primary-1);
        text-underline-position: below;
        text-underline-offset: 3px;
        outline-offset: 3px;

        &:after {
            content: url("./../icons/arrow-down-purple.svg");
            margin-left: 16px;
        }

        &[aria-expanded="true"] {
            &:after {
                content: url("./../icons/arrow-up-purple.svg");
            }
        }

        &:hover,
        &:focus {
            text-decoration: underline;

            &:after {
                filter: hue-rotate(90deg);
            }
        }

        @include hover(var(--color-primary-2), transparent);
    }

    &__fields {
        display: none;
        margin-top: 25px;

        @include breakpoint(mobile) {
            margin-top: 16px;
        }

        &[aria-expanded="true"] {
            display: block;
        }
    }

    &__row {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 30px;

        @include breakpoint(mobile) {
            display: block;
        }
    }

    label {
        display: block;
        margin-bottom: 14px;
    }

    select {
        width: 100%;

        @include breakpoint(mobile) {
            margin-bottom: 16px;
        }
    }

    &__reset.btn {
        margin-top: 26px;
        display: block;
        margin-left: auto;
        width: fit-content;
        background-color: transparent;
        color: var(--color-primary-1);
        @include font-size(15px, 22px);
        padding: 0;
        position: relative;
        text-decoration: underline;
        outline-offset: 3px;

        @include breakpoint(mobile) {
            margin-top: 4px;
        }

        &:focus,
        &:hover {
            background-color: transparent;
            color: var(--color-primary-2);

            &:before {
                content: url("./../icons/reset-hover.svg");
            }
        }

        &:before {
            content: url("./../icons/reset.svg");
            margin-right: 12px;
        }
    }
}
