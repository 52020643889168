.block-image-content {
    --spacing-block-top: 50px;
    --spacing-block-bottom: 50px;
    --gap-column: 32px;
    --spacing-heading: 10px;
    --spacing-desc: 16px;
    --img-size: 440px;
    padding-top: var(--spacing-block-top);
    padding-bottom: var(--spacing-block-bottom);

    &__wrapper {
        display: grid;
        grid-template-columns: minmax(min(var(--img-size), 50%), 1fr) 1fr;
        gap: var(--gap-column);

        @include breakpoint(mobile) {
            display: flex;
            flex-direction: column;
        }

        &.img-right {
            grid-template-columns: 1fr minmax(min(var(--img-size), 50%), 1fr);
        }
    }

    &__heading {
        margin-top: 0;
        margin-bottom: var(--spacing-heading);
    }

    &__img {
        width: 100%;
        height: 100%;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &--right {
            @include breakpoint(mobile) {
                order: -1;
            }
        }
    }

    &__desc {
        margin-top: 0;
        margin-bottom: var(--spacing-desc);
    }
}
