.block-cards-carousel {
    --spacing-block-top: 62px;
    --spacing-block-bottom: 95px;
    --spacing-block-text: 32px;
    --spacing-heading: 29px;
    --heading-border-color: #352087;
    --color-heading: #411e75;

    @include breakpoint(tablet) {
        --spacing-heading: 56px;
        --spacing-block-top: 34px;
        --spacing-block-bottom: 90px;
    }

    @include breakpoint(mobile) {
        --spacing-heading: 24px;
        --spacing-block-top: 43px;
        --spacing-block-bottom: 45px;
    }
    margin-top: var(--spacing-block-top);
    margin-bottom: var(--spacing-block-bottom);

    &__heading {
        margin-bottom: var(--spacing-heading);
        margin-top: 0;
        @include font-size(36px, 36px);
        color: var(--color-heading);

        @include breakpoint(mobile) {
            @include font-size(24px, 26px);
        }
    }

    .cfeeds {
        display: flex !important;
    }

    .cfeed-item {
        width: 473px !important;
        height: 100%;
        margin-right: 0;
        text-decoration: none;

        @include breakpoint(tablet) {
            width: 342px !important;
        }

        @include breakpoint(mobile) {
            width: 260px !important;
        }

        &__bottom {
            padding-top: 36px;

            @include breakpoint(mobile) {
                padding-top: 28px;
            }
        }

        &__img {
            img {
                height: 206px;

                @include breakpoint(mobile) {
                    height: 167px;
                }
            }
        }

        &__tag {
            margin-bottom: 8px;
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 60px;

        @include breakpoint(tablet) {
            margin-top: 56px;
        }

        @include breakpoint(mobile) {
            margin-top: 24px;
        }
    }

    .slick-track {
        display: flex;
    }

    .slick-list {
        overflow: visible;
    }

    .slick-initialized .slick-slide {
        display: block;
        float: none;
        height: inherit !important;
        margin-right: 32px;

        @include breakpoint(tablet) {
            margin-right: 20px;
        }

        @include breakpoint(mobile) {
            margin-right: 16px;
        }
    }

    .slick-active {
        outline: 1px solid var(--color-primary-2);
        filter: drop-shadow(0 4px 6px rgba(65, 30, 117, 0.2));
    }

    .indicator-line {
        position: relative;
        background: var(--color-primary-1);
        height: 1.5px;
        margin-top: 24px;

        .active-indicator {
            display: inline-block;
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: calc(100% / var(--indicator-count));
            height: 4px;
            background-color: var(--color-primary-2);
            transition: left var(--transition-time);
            transition-timing-function: cubic-bezier(0.34, 0.61, 0.52, 0.55);
            border-radius: 4px;
        }
    }
}
