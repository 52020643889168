.site-header__secondary-nav {
    --color-bg: #260950;
    --color-border--hover: #fff;
    --color-border--active: #d995d7;
    background-color: var(--color-bg);
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding-right: 30px;

    @include breakpoint(tablet) {
        padding-top: 12.5px;
        padding-bottom: 12.5px;
        padding-right: 32px;
    }

    @include breakpoint(mobile) {
        padding-top: 14.5px;
        padding-bottom: 14.5px;
        padding-right: 15px;
    }

    .page-navigation-menu {
        --color-nav-level1-text: #fff;
        --color-nav-level1-bg: transparent;
        --color-nav-level1-text--hover: #0c1b29;
        --color-nav-bg--hover: #b4f1ff;
        --color-nav-bg--active: #fe6d73;
        --nav-level1-p-vt: 16px;
        --nav-level1-p-vb: 12px;
        --nav-level1-p-hr: 20px;
        --nav-level1-p-hl: 20px;
        --nav-level1-font: 14px;
        --nav-level1-line-h: 21px;

        ul {
            list-style-type: none;
            a {
                text-decoration: none;
            }
        }

        ul.menu {
            padding: 0;
            margin: 0;
            width: 100%;
            display: flex;
        }

        li ul {
            @include breakpoint(desktop) {
                z-index: 99999;
                display: none;
                position: absolute;
                width: var(--nav-width) !important;
                padding: 0;
            }
        }

        .level1 {
            display: block;
            list-style: none none;
            position: relative;

            &.active {
                > a {
                    &:after {
                        left: 50%;
                        width: 40px;
                        background-color: var(--color-border--active);
                        height: 2px;
                        transform: scaleX(1) translateX(-50%);
                        transition: none;
                    }

                    &:hover {
                        &:after {
                            width: calc(100% - 40px);
                            height: 2px;
                            transform: translateX(-50%);
                            transition: none;
                        }
                    }
                }
            }

            ul {
                @include breakpoint(desktop) {
                    border-left: 2px solid var(--color-nav-level2-border);
                    border-bottom: 2px solid var(--color-nav-level2-border);
                    border-right: 2px solid var(--color-nav-level2-border);
                }
            }

            > a {
                text-align: center;
                @include font-size(
                    var(--nav-level1-font),
                    var(--nav-level1-line-h)
                );
                padding: var(--nav-level1-p-vt) var(--nav-level1-p-hr)
                    var(--nav-level1-p-vb) var(--nav-level1-p-hl);
                position: relative;
                color: var(--color-nav-level1-text);
                text-transform: capitalize;
                border-radius: 8px;

                @include breakpoint(not-desktop) {
                    color: var(--color-nav-level2-text);
                }

                &:before {
                    display: none;
                }

                &:after {
                    content: "";
                    position: absolute;
                    width: calc(100% - 40px);
                    transform: scaleX(0);
                    height: 1px;
                    bottom: 0;
                    left: 20px;
                    top: 80%;
                    background-color: var(--color-border--hover);
                    transform-origin: bottom right;
                    transition: transform 0.25s ease-out;
                }

                &:hover,
                &:focus {
                    &:after {
                        transform: scaleX(1);
                        transform-origin: bottom left;
                    }
                }

                &:focus {
                    outline: 0;

                    &:before {
                        content: "";
                        display: block;
                        position: absolute;
                        border: 1px solid var(--color-focus);
                        top: 14px;
                        bottom: 3px;
                        left: 12px;
                        right: 12px;
                        border-radius: 80px;
                        background-color: transparent;
                        height: auto;
                        width: auto;
                    }
                }

                &:active {
                    &:after {
                        transform: scaleX(1);
                        height: 2px;
                    }
                }
            }
        }
    }
}
