.block-article-related-topics {
    --heading-text-color: #411e75;
    --desc-text-color: #130527;
    padding-top: 64px;
    border-top: 1.5px solid var(--color-primary-1);
    margin-top: 64px;

    @include breakpoint(tablet) {
        padding-top: 32px;
        margin-top: 48px;
    }

    @include breakpoint(mobile) {
        padding-top: 32px;
        margin-top: 80px;
    }

    &__heading {
        color: var(--heading-text-color);
        @include font-size(36px, 36px);
        margin-top: 0;
        margin-bottom: 37px;
    }

    .cfeed-item {
        &:hover,
        &:focus {
            span.read-more {
                display: none;
            }

            span.read-more-hover {
                color: var(--heading-text-color);
                display: inline-block;
            }
        }

        &__img {
            img {
                height: 206px;
            }
        }

        &__desc {
            color: var(--desc-text-color);
            @include font(barlow, reg);
            @include font-size(16px, 22px);

            h1,
            h2,
            h3,
            h4,
            h5,
            h6,
            p {
                display: inline;
                color: var(--desc-text-color);
                @include font(barlow, reg);
                @include font-size(16px, 22px);
                strong {
                    font-weight: normal;
                }
            }

            span.read-more {
                color: var(--heading-text-color);
                display: inline-block;
            }

            span.read-more-hover {
                display: none;
            }
        }
    }
}
