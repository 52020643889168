/*
==========================================================================
   #Heading
========================================================================== */
:root {
    // Heading colors
    --color-h1: #130527;
    --color-h2: #3a3b45;
    --color-h3: #3a3b45;
    --color-h4: #3a3b45;
    --color-h5: #130527;
    --color-h6: #3a3b45;
}

%heading {
    @include font(barlow, reg);
    border: 0 none;
    padding: 0;
}

%h1,
h1,
.h1 {
    @extend %heading;
    @include font-size(42px, 42px);
    @include font(barlow, reg);
    color: var(--color-h1);
    margin-top: 0;
    margin-bottom: 24px;

    @include breakpoint(tablet) {
        @include font-size(36px, 40px);
    }

    @include breakpoint(mobile) {
        @include font-size(30px, 30px);
        margin-bottom: 20px;
    }
}

%h2,
h2,
.h2 {
    @extend %heading;
    @include font-size(28px, 32px);
    color: var(--color-h2);
    margin-top: 0;
    margin-bottom: 16px;

    @include breakpoint(mobile) {
        @include font-size(24px, 26px);
    }
}

%h3,
h3,
.h3 {
    @extend %heading;
    @include font-size(22px, 30px);
    color: var(--color-h3);
    margin-top: 0;
    margin-bottom: 16px;

    @include breakpoint(mobile) {
        @include font-size(20px, 22px);
    }
}

%h4,
h4,
.h4 {
    @extend %heading;
    @include font-size(18px, 25px);
    color: var(--color-h4);
    margin-top: 0;
    margin-bottom: 16px;
}

%h5,
h5,
.h5 {
    @extend %heading;
    @include font-size(14px, 21px);
    color: var(--color-h5);
    margin-top: 0;
    margin-bottom: 16px;
    text-transform: uppercase;
}

%h6,
h6,
.h6 {
    @extend %heading;
    @include font-size(14px, 21px);
    color: var(--color-h6);
    margin-top: 0;
    margin-bottom: 16px;
    text-transform: uppercase;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    a,
    a:not([class]),
    a:focus,
    a:not([class]):focus,
    a:visited,
    a:not([class]):visited {
        color: inherit;
        text-decoration: none;
        font-family: inherit;
        font-size: inherit;
    }

    a:hover {
        color: inherit;
    }
}

/*
==========================================================================
   #Paragraph
========================================================================== */

p {
    margin-top: 10px;
    margin-bottom: 16px;
}

.large-text {
    @include font-size(22px, 28px);

    @include breakpoint(mobile) {
        @include font-size(16px, 24px);
    }
}
