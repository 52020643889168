// /*
// ==========================================================================
//    #Table
// ========================================================================== */

.accordion-item {
    tbody {
        tr {
            td {
                border-bottom: 1px solid var(--color-table-border);
                &:first-child {
                    border-left: 1px solid var(--color-table-border);
                }

                &:last-child {
                    border-right: 1px solid var(--color-table-border);
                }
            }
            &:last-child {
                td {
                    &:first-child {
                        border-radius: 0 0 0 5px;
                        border: 1px solid var(--color-table-border);
                        border-top: 0;
                        border-right: 0;
                    }
                    &:last-child {
                        border-radius: 0 0 5px;
                        border: 1px solid var(--color-table-border);
                        border-top: 0;
                        border-left: 0;
                    }
                }
            }
        }
    }
}
