/*
==========================================================================
   #Navigation
========================================================================== */
.page-navigation-mega-menu {
    --color-navbg--level1: transparent;
    --color-navtext--level1: #fff;
    --color-navtext--level2: #411e75;
    --color-navtext--level3: #260950;
    --color-navbg--level1--hover: transparent;
    --color-navbg--level1-border--hover: #fff;
    --color-navbg--level1-border--active: #d995d7;
    --color-navbg--level2: #fafafb;
    --color-navbg--level2--hover: #fff;
    --color-navbg--level2--focus: #aa52ff;
    --color-navbg--level2-bg--hover: #dcdff3;
    --color-navbg--level2-border--hover: #411e75;
    --color-navbg--level2-border--active: #ad3192;
    --color-navbg--level3: #f0f1fa;
    --color-navbg--level3--hover: #fafafb;
    --color-navbg--level3-border--active: #ad3192;
    --color-megaMenu-bg: #fff;
    --color-nav-parent-text: #ad3192;
    background-color: var(--color-bg);
    padding-right: 0;
    max-width: 77%;

    @include breakpoint(not-desktop) {
        display: none;
    }

    ul {
        list-style-type: none;
        padding-top: 0;
        padding-left: 0;

        a {
            text-decoration: none;
            font-weight: bold;
        }

        &.menu {
            padding: 0;
            margin: 0;
            display: flex;
            justify-content: space-between;
            width: 100%;
            position: static;
        }
    }

    li {
        margin-bottom: 0;
    }

    .accessible-megamenu.focused {
        .accessible-megamenu-panel {
            &.open {
                display: block;
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .delay-hover {
        > .accessible-megamenu-panel {
            &.open {
                display: block;
                visibility: visible;
                opacity: 1;
            }
        }
    }

    .sub-menu-wrapper {
        display: none;
    }

    /* Level 1 */

    .level1 {
        list-style: none none;
        background: var(--color-navbg--level1);

        &.active {
            > a {
                span:after {
                    width: 60%;
                    height: 2px;
                    transform: scaleX(1) translateX(35%);
                    background-color: var(--color-navbg--level1-border--active);
                    transition: none;
                }

                &:hover {
                    span:after {
                        transition: none;
                    }
                }
            }
        }

        > a {
            display: block;
            text-align: center;
            @include font-size(20px, 28px);
            @include font(barlow, medium);
            color: var(--color-navtext--level1);
            padding: 16px 44px 20px;
            letter-spacing: 0.05px;
            position: relative;
            border-radius: 4px;

            @media screen and (min-width: 1200px) and (max-width: 1565px) {
                padding-left: 24px;
                padding-right: 24px;
            }

            &:before {
                display: none;
            }

            span {
                position: relative;
                display: inline-block;

                &:after {
                    content: "";
                    position: absolute;
                    width: 100%;
                    transform: scaleX(0);
                    height: 1px;
                    bottom: 0;
                    left: 0;
                    top: 100%;
                    background-color: var(--color-navbg--level1-border--hover);
                    transform-origin: bottom right;
                    transition: transform 0.25s ease-out;
                }
            }
            &:hover,
            &:focus {
                span:after {
                    width: 100%;
                    transform: scaleX(1);
                    transform-origin: bottom left;
                }
            }

            &:focus {
                outline: 0;

                span:before {
                    content: "";
                    display: block;
                    position: absolute;
                    border: 1px solid var(--color-focus);
                    top: 0;
                    bottom: -4px;
                    left: -4px;
                    right: -4px;
                    border-radius: 4px;
                }
            }

            &:active {
                span:after {
                    height: 2px;
                    width: 100%;
                    transform: scaleX(1);
                }

                &:hover {
                    transition: none;
                }
            }
        }
    }

    // Level 2

    .level2 {
        width: 383px;
        &.delay-hover {
            .sub-menu-wrapper.open {
                display: block;
                visibility: visible;
                opacity: 1;
            }
        }

        & > .sub-menu-wrapper {
            min-width: 764px;
            transition: opacity 400ms linear;
            display: none;
            flex-flow: column wrap;
            position: absolute;
            left: 385px;
            top: 0;
            bottom: 0;
            width: 783px;
            min-height: 450px;
            background-color: var(--color-navbg--level3);
            z-index: 100;
            padding: 18px 21px 24px 41px;
        }

        &.active {
            &.deeper {
                > a {
                    background: var(--color-navbg--level2-bg--hover);
                }
            }

            > a {
                span:after {
                    width: 60%;
                    transform: scaleX(1);
                    border-bottom: 2px solid
                        var(--color-navbg--level2-border--active);
                }

                &:hover,
                &:focus {
                    span {
                        background-size: 0 100%;
                    }
                }
            }
        }

        a {
            color: var(--color-navtext--level2);
            padding-bottom: 3px;
            display: inline-block;
            width: 100%;

            &:before {
                display: none;
            }

            span {
                position: relative;
                border-radius: 4px;
                background-image: linear-gradient(
                    transparent calc(100% - 1px), var(--color-navbg--level2-border--hover) 1px
                );
                background-repeat: no-repeat;
                background-size: 0 100%;
                transition: background-size var(--transition-time);

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top: 100%;
                    border-bottom: 1px solid
                        var(--color-navbg--level2-border--hover);
                    transform: scaleX(0);
                    transform-origin: left;
                    transition: all var(--transition-time);
                }
            }

            &:hover,
            &:focus {
                span {
                    background-size: 100% 100%;
                }
            }
        }

        > a {
            @include font-size(20px, 24px);
            @include font(barlow, medium);
            padding-top: 20px;
            padding-bottom: 20px;
            vertical-align: middle;
            display: inline-block;
            border-radius: 8px 30px 30px 8px;
        }

        .parent-title {
            padding-left: 0;
            margin-bottom: 23px;
            padding-top: 0;
        }

        > .accessible-megamenu-panel {
            border-radius: 8px;
            transition: all 1s;
            &.open {
                display: block;
                visibility: visible;
                opacity: 1;
            }
        }

        &.deeper {
            > a {
                padding-right: 77px;

                &:after {
                    content: "";
                    position: absolute;
                    right: 67px;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 10px;
                    height: 16px;
                    display: inline-block;
                    background: url("./../icons/arrow-right-violet.svg");
                    background-size: contain;
                    background-repeat: no-repeat;
                    flex-shrink: 0;
                    margin-left: 10px;
                }

                &.focus,
                &.open {
                    background: var(--color-navbg--level2-bg--hover);
                }

                &.focus,
                &:focus,
                &:focus-visible {
                    outline: 1px solid var(--color-navbg--level2--focus);
                }
            }
        }

        &:not(.deeper) {
            > a.focus,
            > a:focus {
                outline: 0;
                span {
                    outline: 1px solid var(--color-navbg--level2--focus);
                    outline-offset: 4px;
                }
            }
        }

        ul {
            display: flex;
            flex-flow: column wrap;
            max-height: calc(100% - 40px);
            column-gap: 20px;
        }
    }

    .level3 {
        width: 33%;
        &.active {
            > a {
                span:after {
                    width: 60%;
                    transform: scaleX(1);
                    border-bottom: 2px solid
                        var(--color-navbg--level3-border--active);
                }

                &:hover,
                &:focus {
                    span {
                        background-size: 0 100%;
                    }
                }
            }
        }

        a {
            color: var(--color-navtext--level3);
            @include font-size(16px, 22px);
            @include font(barlow, reg);
            display: block;
            padding-top: 8px;
            padding-bottom: 8px;
            width: fit-content;
        }

        > a.focus,
        > a:focus-visible,
        > a:focus {
            outline: 0;
            &:after {
                transform: scaleX(1);
            }

            span {
                outline: 1px solid var(--color-focus);
                outline-offset: 4px;
                background-size: 100% 100%;
            }
        }

        .sub-menu-wrapper {
            display: none;
        }
    }

    .level4,
    .level5 {
        display: none;
    }

    .parent-title {
        @include font-size(18px, 25px);
        color: var(--color-nav-parent-text);
        padding-top: 20px;
        margin-bottom: 11px;
        padding-left: 19px;
    }
}

.accessible-megamenu-panel {
    width: 100%;
    margin-left: 0;
    position: absolute;
    left: 0;
    top: 100%;
    opacity: 0;
    visibility: hidden;
    background: var(--color-megaMenu-bg);
    z-index: 10;
    padding-top: 23px;
    padding-bottom: 23px;
    border-bottom-right-radius: 64px;
    border-bottom-left-radius: 64px;

    .wrapper {
        display: block;
        padding-right: 0;
        padding-left: 0;
    }

    > .wrapper {
        position: relative;
        height: 100%;
        min-height: 450px;
    }

    ul {
        padding: 0;
    }

    .level2 {
        > a {
            position: relative;
            padding-left: 19px;
        }
    }
}

#bump {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    z-index: z-index(bump);
    pointer-events: none;

    @include breakpoint(not-desktop) {
        display: none;
    }
}
