.flex {
    display: flex;
}

.bold {
    @include font(barlow, semibold);
}

.item-readtime {
    --color-readtime: #92278f;
    color: var(--color-readtime);

    &:before {
        content: url("./../icons/clock.svg");
        display: inline-block;
        vertical-align: middle;
        margin-right: 4px;
    }

    & + .item-date {
        margin-left: 8px;
        padding-left: 8px;
        border-left: 1px solid var(--color-neutral-5);
    }
}

.item-date {
    color: var(--color-neutral-6);
}

.item-tag {
    @extend .h5;
    color: var(--color-secondary-1);
}

.violet {
    color: var(--color-primary-1);
}
