.block-simple-banner {
    --banner-height-desktop: 600px;
    --banner-special-height-desktop: 80vh;
    --banner-special-height-desktop-large: 80vh;
    --banner-height-tablet: 490px;
    --banner-special-height-tablet: 483px;
    --banner-height-mobile: 500px;
    --text-width: 675px;
    --text-padding: 154px 0 40px 0;
    --text-special-padding: 154px 0 40px 0;
    --text-padding-mobile: 57px 0 40px 0;
    --spacing-title: 25px;
    --spacing-btn: 39px;
    --text-color: #fff;
    --color-btn-hero-border: #fff;
    --color-btn-hero-focus-outline: #0ff;
    --color-text-russian-violet: #260950;
    --color-text-blue-violet: #411e75;
    --color-text-dark-magento: #92278f;
    --color-text-fandango: #ad3192;
    --color-text-dark-purple: #411e75;
    --color-text-mid-purple: #692281;
    --color-text-light-purple: #92278f;
    --color-text-pink: #ad3192;
    --color-text-white: #fff;
    --color-text-black: #000;
    position: relative;
    min-height: var(--banner-height-desktop);
    background: url("./../img/hero-banner-bg.png");
    background-repeat: no-repeat;
    background-size: cover;

    @include breakpoint(tablet) {
        min-height: var(--banner-height-tablet);
    }

    @include breakpoint(mobile) {
        min-height: var(--banner-height-mobile);
    }

    .wrapper {
        position: relative;
        z-index: 2;
    }

    &__text-wrapper {
        width: var(--text-width);
        max-width: 100%;
        padding: var(--text-padding);
        position: relative;
        z-index: 1;

        @include breakpoint(tablet) {
            max-width: 550px;
        }

        @include breakpoint(mobile) {
            padding: var(--text-padding-mobile);
        }
    }

    &__title {
        margin-top: 0;
        margin-bottom: var(--spacing-title);
        color: var(--text-color);
        @include font-size(78px, 78px);
        @include font(barlow, semibold);

        @include breakpoint(tablet) {
            @include font-size(48px, 48px);
        }

        @include breakpoint(mobile) {
            @include font-size(38px, 38px);
        }
    }

    &__introtext {
        color: var(--text-color);

        @include breakpoint(desktop) {
            @include font-size(22px, 26.4px);
            padding-right: 50px;
        }

        @include breakpoint(tablet) {
            max-width: 350px;
            @include font-size(22px, 22px);
        }

        @include breakpoint(mobile) {
            @include font-size(16px, 19px);
        }
    }

    &__btn {
        margin-top: var(--spacing-btn);

        @include breakpoint(mobile) {
            .btn-hero {
                padding: 8px 44px 8px 17px;
            }
        }

        .btn-hero {
            &:focus {
                outline-color: var(--color-btn-hero-focus-outline);
            }
        }
    }

    &__embed {
        position: relative;
        z-index: 2;
        @include breakpoint(desktop) {
            margin-left: calc((100vw - var(--wrapper-width)) / 2);
        }
        @include breakpoint(not-desktop) {
            margin-left: var(--wrapper-padding-h);
            margin-right: var(--wrapper-padding-h);
        }
    }

    &__bottom_description {
        color: var(--text-color);
        margin-top: 29px;
        font-size: 14px;

        @include breakpoint(desktop) {
            max-width: 510px;
        }

        @include breakpoint(mobile) {
            margin-top: 15px;
        }

        p {
            margin-bottom: 0;
        }

        a {
            color: inherit;

            &:before {
                background-color: var(--text-color);
            }
        }
    }

    &.style1 {
        z-index: 1;
        .block-simple-banner__img {
            position: absolute;
            bottom: 0;
            right: 0;
            background: url("./../img/hero-banner-image-bg-1.png");
            background-repeat: no-repeat;
            background-size: 997px 100%;
            background-position: left calc(100% - 65px) top 50px;
            z-index: -1;
            text-align: right;

            @include breakpoint(desktop) {
                top: 40px;
                left: calc((100vw - var(--wrapper-width)) / 2 + 395px);
            }

            @include breakpoint(tablet) {
                background: url("./../img/hero-banner-image-bg-1-tablet.png");
                left: 307px;
                top: 40px;
                bottom: 0;
                background-position: left 2px top 50px;
                background-repeat: no-repeat;
                background-size: 100% 100%;
            }

            @include breakpoint(mobile) {
                background: url("./../img/hero-banner-image-bg-1-mobile.png");
                background-repeat: no-repeat;
                background-size: 200px 140px;
                background-position: calc(100% - 65px) bottom;
                width: 278px;
                height: 183px;
            }

            img {
                position: relative;
                mask-image: url("./../img/hero-banner-image-shape-1.svg");
                mask-size: cover;
                mask-repeat: no-repeat;
                mask-position: left top;
                object-fit: cover;

                @include breakpoint(desktop) {
                    width: 900px;
                    height: 100%;
                }

                @include breakpoint(tablet) {
                    mask-image: url("./../img/hero-banner-image-shape-1-tablet.svg");
                    height: 100%;
                    width: calc(100% - 30px);
                }

                @include breakpoint(mobile) {
                    width: 100%;
                    height: 100%;
                    mask-size: 280px 183px;
                    mask-image: url("./../img/hero-banner-image-shape-1-mobile.svg");
                    mask-position: right bottom;
                }
            }
        }
    }

    &.style2 {
        @include breakpoint(mobile) {
            min-height: auto;
        }
        .block-simple-banner__img {
            position: absolute;
            top: 94px;
            right: 0;
            left: calc((100vw - var(--wrapper-width)) / 2 + var(--text-width));
            height: calc(100% - 94px);
            z-index: 1;

            @include breakpoint(mobile) {
                display: block;
                position: relative;
                top: 0;
                left: 16px;
                width: calc(100vw - 16px);
                height: 144px;
            }

            &:after {
                content: "";
                background-repeat: no-repeat;
                position: absolute;
                @include breakpoint(desktop) {
                    background-image: url("./../img/hero-banner-image-bg-2.png");
                    background-position: left bottom;
                    bottom: 0;
                    left: -100px;
                    width: 200px;
                    height: 266px;
                    z-index: -1;
                }
                @include breakpoint(tablet) {
                    background-image: url("./../img/hero-banner-image-bg-2-tablet.png");
                    background-position: left bottom;
                    bottom: 0;
                    left: -100px;
                    width: 311px;
                    height: 142px;
                    z-index: -1;
                }
                @include breakpoint(mobile) {
                    background-image: url("./../img/hero-banner-image-bg-2-mobile.png");
                    background-position: 100% top;
                    background-size: 320px 316px;
                    top: -200px;
                    right: 0;
                    width: 100%;
                    height: 200px;
                }
            }

            img {
                border-top-left-radius: 300px;
                height: 100%;
                object-fit: cover;
                object-fit: cover;
                width: 100%;

                @include breakpoint(tablet) {
                    border-top-left-radius: 150px;
                }

                @include breakpoint(mobile) {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-top-left-radius: 100px;
                }
            }
        }
    }

    &.donation-banner {
        padding-bottom: 33px;

        @include breakpoint(mobile) {
            padding-bottom: 16px;
        }

        &.style2 {
            .block-simple-banner__img {
                max-height: calc(678px - 100px);
                &:after {
                    display: none;
                }

                img {
                    border-top-right-radius: 13px;
                    border-bottom-right-radius: 13px;
                    border-bottom-left-radius: 13px;
                }
            }
        }

        .block-simple-banner {
            &__title {
                @include font-size(60px, 60px);

                @include breakpoint(tablet) {
                    max-width: 614px;
                }

                @include breakpoint(mobile) {
                    @include font-size(32px, 32px);
                }
            }

            &__img {
                @include breakpoint(not-desktop) {
                    display: none;
                }
            }

            &__text-wrapper {
                padding-bottom: 7px;
                padding-top: 100px;

                @include breakpoint(tablet) {
                    padding-top: 68px;
                }

                @include breakpoint(mobile) {
                    padding-top: 54px;
                }
            }

            &__introtext {
                @include font-size(20px, 23px);
                font-style: italic;
                max-width: none;

                @include breakpoint(mobile) {
                    @include font-size(18px, 21px);
                }

                p {
                    margin-top: 0;
                }
            }
        }
    }

    &.special-occasion {
        min-height: var(--banner-special-height-desktop);

        @include breakpoint(tablet) {
            min-height: var(--banner-special-height-tablet);

            .block-simple-banner__text-wrapper {
                padding-top: 86px;
            }
        }

        @include breakpoint(mobile) {
            min-height: var(--banner-special-height-tablet);

            .block-simple-banner__text-wrapper {
                padding-top: 86px;
            }
        }

        picture:before {
            position: absolute;
            content: " ";
            display: block;
            width: 100%;
            height: 100%;
            opacity: 1;
        }

        &.overlay-70 picture:before {
            background: linear-gradient(
                to right, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)
            );
        }
        &.overlay-60 picture:before {
            background: linear-gradient(
                to right, rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.1)
            );
        }
        &.overlay-40 picture:before {
            background: linear-gradient(
                to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.1)
            );
        }
        &.overlay-20 picture:before {
            background: linear-gradient(
                to right, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.1)
            );
        }

        .block-simple-banner__title {
            display: inline;
            color: var(--text-color);
            box-decoration-break: clone;
            box-decoration-break: clone;
            box-decoration-break: clone;
            box-decoration-break: clone;
            padding-right: 20px;
            padding-left: 20px;
            font-size: 60px;
            line-height: 85px;
            border-radius: 100px;

            &.russian-violet {
                background: linear-gradient(
                    var(--color-text-russian-violet) 0 0
                )
                    0 / 100% 75px no-repeat;
            }

            &.blue-violet {
                background: linear-gradient(var(--color-text-blue-violet) 0 0) 0 /
                    100% 75px no-repeat;
            }
            &.dark-magento {
                background: linear-gradient(var(--color-text-dark-magento) 0 0)
                    0 / 100% 75px no-repeat;
            }
            &.mid-purple {
                background: linear-gradient(var(--color-text-mid-purple) 0 0) 0 /
                    100% 75px no-repeat;
            }
            &.fandango {
                background: linear-gradient(var(--color-text-fandango) 0 0) 0 /
                    100% 75px no-repeat;
            }

            &.dark-purple {
                background: linear-gradient(var(--color-text-dark-purple) 0 0) 0 /
                    100% 75px no-repeat;
            }

            &.light-purple {
                background: linear-gradient(var(--color-text-light-purple) 0 0)
                    0 / 100% 75px no-repeat;
            }

            &.pink {
                background: linear-gradient(var(--color-text-pink) 0 0) 0 / 100%
                    75px no-repeat;
            }

            &.white {
                background: linear-gradient(var(--color-text-white) 0 0) 0 /
                    100% 75px no-repeat;
                color: var(--color-text-black);
            }

            @include breakpoint(tablet) {
                font-size: 48px;
                line-height: 67px;
            }

            @include breakpoint(mobile) {
                font-size: 38px;
                line-height: 60px;
            }
        }

        .block-simple-banner__introtext {
            margin-top: 28px;

            @include breakpoint(tablet) {
                max-width: 440px;
            }
        }

        .block-simple-banner__btn {
            .btn-hero:after {
                display: none;
            }

            .btn-hero:before {
                display: none;
            }

            a {
                min-width: 304px;
                text-align: center;
                padding: 18px;
                background-color: var(--color-text-russian-violet);

                @include breakpoint(mobile) {
                    min-width: 168px;
                }
            }
        }

        @include breakpoint(mobile) {
            min-height: auto;
        }
        .block-simple-banner__img {
            position: absolute;
            top: 0;
            right: 0;
            left: 0;
            height: 100%;
            width: 100%;
            z-index: 1;

            img {
                height: 100%;
                object-fit: cover;
                object-fit: cover;
                width: 100%;
            }

            @include breakpoint(not-desktop) {
                display: block;
            }
        }
    }
}
