.block-tabs-accordion {
    --color-border: #411e75;
    --color-heading: #411e75;
    margin-top: 50px;
    @include breakpoint(mobile) {
        margin-top: 30px;
    }

    .block-tabs__wrapper {
        background: none;
        padding: 0;
    }

    .block-tabs__container {
        width: 100%;
        padding: 0;
    }

    &__container {
        margin-top: 60px;
        margin-bottom: 20px;
    }

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        padding-top: 10px;
    }

    &__content {
        width: calc(100% - 473px);
        padding-right: 30px;

        @include breakpoint(not-desktop) {
            width: 100%;
            padding-right: 0;
        }

        p {
            margin-bottom: 10px;
        }
    }

    &__content-btn {
        display: inline-block;
    }

    &__content-heading {
        @include font-size(18px, 25px);
        @include font(barlow, semibold);
        color: var(--color-heading);
    }

    .header-contact {
        margin-bottom: 12px;

        @include breakpoint(mobile) {
            @include font-size(24px, 26.4px);
            margin-bottom: 42px;
        }
    }

    hr {
        border: 1px solid var(--color-border);
        height: auto;
        margin: 35px 0 25px;
    }

    &__img {
        width: 473px;
        @include breakpoint(tablet) {
            width: 218px;
        }

        img {
            width: 100%;
            height: auto;
        }
    }

    &__content-section {
        @include breakpoint(tablet) {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
        }
    }

    &__content-section-text {
        @include breakpoint(tablet) {
            width: calc(100% - 218px);
            padding-right: 30px;
        }

        @include breakpoint(mobile) {
            .header-contact {
                margin-bottom: 10px;
            }
        }
    }

    &__accordion-item {
        .accordion-item:first-child {
            border-top: 0;
        }

        .accordion-item__body {
            padding-top: 20px;
        }
    }
}
