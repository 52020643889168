.grid-listing {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    gap: 30px;

    @include breakpoint(tablet) {
        grid-template-columns: repeat(6, 1fr);
        gap: 32px 24px;
    }

    @include breakpoint(mobile) {
        grid-template-columns: 1fr;
        gap: 32px;
    }

    .result-cell {
        &:nth-last-child(-n + 3) {
            @include breakpoint(tablet) {
                grid-column: span 2;
            }

            .cfeed-item__img {
                img {
                    @include breakpoint(tablet) {
                        height: 130px;
                    }
                }
            }

            .cfeed-item__badge {
                @include breakpoint(tablet) {
                    display: none;
                }
            }
        }

        &:nth-child(-n + 3) {
            @include breakpoint(tablet) {
                grid-column: span 3;
            }
        }

        &.first {
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            @include breakpoint(desktop) {
                grid-column: 1 / 3;
                grid-row: 1 / 3;
                display: flex;
                flex-direction: column;
            }

            &:hover {
                border-top-right-radius: 80px;
            }

            @include breakpoint(tablet) {
                grid-column: span 6;
            }

            .cfeed-item__img img {
                height: 472px;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;

                @include breakpoint(mobile) {
                    height: 178px;
                }
            }

            a.cfeed-item__bottom {
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                @include breakpoint(desktop) {
                    flex: 1 1 auto;
                }

                &:after {
                    @include breakpoint(desktop) {
                        position: relative;
                        margin-top: 16px;
                        bottom: auto;
                    }
                }

                &:focus-visible {
                    outline: 1px solid var(--color-primary-2);
                }
            }
        }
    }

    .cfeed-item {
        &__img img {
            height: 206px;
        }

        &__bottom {
            padding-top: 36px;

            @include breakpoint(mobile) {
                padding-top: 28px;
            }
        }

        &__tag {
            margin-bottom: 8px;
        }
    }
}
