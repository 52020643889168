/*
==========================================================================
   #Colors
========================================================================== */
:root {
    // Basic colors
    --color-white: #fff;
    --color-neutral-1: #f0f1fa;
    --color-neutral-2: #dcdff3;
    --color-neutral-3: #babfe2;
    --color-neutral-4: #9ea3c7;
    --color-neutral-5: #656779;
    --color-neutral-6: #3a3b45;
    --color-neutral-7: #130527;
    --color-primary-1: #411e75;
    --color-primary-2: #ad3192;
    --color-secondary-1: #92278f;
    --color-secondary-2: #260950;

    // Focus color
    --color-focus: #aa52ff;

    // Link colors
    --color-link: #000;
    --color-link-hover: #000;
    --color-link-decoration: #ad3192;
    --color-link-decoration-hover: #d995d7;

    // Error page colors
    --color-error-h1: #000;
    --color-error-h2: #000;

    // System Error Messages colors
    --color-system-alert-msg-text: #00875a;
    --color-system-alert-msg-bg: #d2e5df;
    --color-system-alert-msg-border: #00875a;
    --color-system-alert-error-text: #de350b;
    --color-system-alert-error-bg: #ffd7e2;
    --color-system-alert-error-border: #de350b;
    --color-system-alert-notice-text: #6554c0;
    --color-system-alert-notice-bg: #fffaed;
    --color-system-alert-notice-border: #6554c0;

    // hr
    --color-hr: #ccc;
}
