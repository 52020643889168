/*
==========================================================================
   #Layout
========================================================================== */
.two_column_l {
    .page-content-left,
    .page-content-left-bottom {
        @include breakpoint(desktop) {
            float: left;
            width: var(--side-menu-width);
        }
    }

    .page-content-left {
        position: relative;

        @include breakpoint(not-desktop) {
            display: none;
        }

        > * {
            margin-bottom: 30px;
        }
    }

    .page-content-main {
        @include breakpoint(desktop) {
            float: right;
            width: calc(100% - #{var(--side-menu-width)});
            padding-left: 50px;
        }
    }

    .page-content-left-bottom {
        @include breakpoint(tablet) {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
        }

        @include breakpoint(mobile) {
            margin-top: 30px;
        }

        > div {
            @include breakpoint(tablet) {
                width: calc(50% - 10px);
            }
        }
    }
}

.two_column_r {
    .page-content-right {
        position: relative;
        @include breakpoint(desktop) {
            float: right;
            width: var(--side-menu-width);
            margin-top: 36px;
        }

        &.no-banner {
            @include breakpoint(desktop) {
                margin-top: 42px;
            }
        }

        &.has-content-type {
            @include breakpoint(desktop) {
                margin-top: 73px;
            }

            &.no-banner {
                @include breakpoint(desktop) {
                    margin-top: 79px;
                }
            }
        }

        > * {
            margin-bottom: 30px;
        }
    }

    .page-content-main {
        @include breakpoint(desktop) {
            float: left;
            width: calc(100% - #{var(--side-menu-width)});
            padding-right: 133px;
        }
    }

    .page-content-right-bottom {
        @include breakpoint(tablet) {
            display: flex;
            justify-content: space-between;
            margin-top: 40px;
        }

        @include breakpoint(mobile) {
            margin-top: 30px;
        }

        > div {
            @include breakpoint(tablet) {
                width: calc(50% - 10px);
            }
        }
    }
}

.page-content {
    overflow: hidden;

    &.has-padding-top {
        padding-top: 83px;
        @include breakpoint(tablet) {
            padding-top: 72px;
        }
        @include breakpoint(mobile) {
            padding-top: 30px;
        }
    }
}
