/*
==========================================================================
   #2 column card with image on the side - Contact Us Page
========================================================================== */
.block-two-col-img {
    --color-bg: #dcdff3;
    --color-title: #411e75;
    --color-text: #000;
    background: var(--color-bg);
    padding: 64px 0 76px 64px;
    border-radius: 8px;
    color: var(--color-text);
    margin-top: 55px;
    margin-bottom: 105px;
    @include breakpoint(not-desktop) {
        margin-right: -30px;
        padding-left: 30px;
    }
    @include breakpoint(tablet) {
        margin-bottom: 85px;
    }
    @include breakpoint(mobile) {
        padding-left: 24px;
        padding-bottom: 10px;
        width: calc(100% + 48px);
        margin-left: -24px;
        margin-bottom: 77px;
        border-radius: 0;
    }

    &__title {
        @include font-size(50px, 52.5px);
        letter-spacing: 0.01em;
        color: var(--color-title);
        margin-bottom: 22px;
        @include breakpoint(tablet) {
            @include font-size(32px, 38.4px);
            max-width: 609px;
        }
        @include breakpoint(mobile) {
            @include font-size(24px, 26px);
        }
    }

    .block-simple-banner__text-wrapper {
        padding: 0;
    }

    &__wrapper {
        display: flex;
        flex: wrap;
        @include breakpoint(not-desktop) {
            flex-flow: column-reverse;
            margin-right: -30px;
            position: relative;
        }
    }

    &__text {
        width: calc(100% - 398px);
        @include breakpoint(not-desktop) {
            width: 100%;
            padding-right: 94px;
            padding-left: 34px;
            margin-top: -25px;
        }
        @include breakpoint(mobile) {
            padding-left: 0;
            padding-right: 48px;
            margin-top: -35px;
        }
    }

    &__img {
        width: 398px;
        position: relative;
        @include breakpoint(not-desktop) {
            width: 100%;
        }
    }

    &__col {
        display: flex;
        flex: wrap;
        gap: 17px;
        @include breakpoint(tablet) {
            gap: 0;
        }
        @include breakpoint(mobile) {
            display: block;
        }
    }

    &__col-wrapper {
        width: 341px;
        @include breakpoint(tablet) {
            width: calc(100% / 2);
            padding-bottom: 40px;
        }
        @include breakpoint(mobile) {
            width: 100%;
            padding-bottom: 40px;
        }
    }

    &__col-wrapper:first-child {
        .block-two-col-img__col-text {
            padding-right: 17px;
            @include breakpoint(tablet) {
                padding-right: 24px;
            }
            @include breakpoint(mobile) {
                padding-right: 0;
            }
        }
    }

    &__col-title {
        color: var(--color-title);
        @include font-size(22px, 24.2px);
        margin-bottom: 0;
    }

    &__col-text {
        @include font-size(16px, 22.4px);
        margin-top: 0;
    }

    &__col-btn {
        @include breakpoint(tablet) {
            position: absolute;
            bottom: 0;
        }
    }

    &__img-pic {
        position: absolute;
        width: 1180px;
        top: -90px;
        height: 444px;

        @include breakpoint(not-desktop) {
            display: block;
            position: relative;
            margin-left: auto;
        }

        @include breakpoint(tablet) {
            width: 704px;
            height: 444px;
        }

        @include breakpoint(mobile) {
            width: 100%;
            height: 269px;
        }
        img {
            height: 100%;
            width: 100%;
            object-fit: cover;
            border-top-left-radius: 13px;
            border-bottom-left-radius: 300px;

            @include breakpoint(not-desktop) {
                height: 100%;
            }

            @include breakpoint(mobile) {
                border-bottom-left-radius: 100px;
            }
        }
    }
}
