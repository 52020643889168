/*
==========================================================================
   #Hamburger Menu
========================================================================== */
.site-header__main-nav {
    --menu-hamburger-tb-width: 492px;
    --menu-hamburger-mb-width: 227px;
    --color-main-menu-bg: #411e75;
    --color-top-menu-border: #411e75;
    --color-menu-top-text-l1: #260950;
    --color-menu-top-bg-l1: #fff;
    --color-menu-top-line-l1: #d995d7;
    --color-menu-top-text-l1-hover: #fff;
    --color-menu-top-text-l1-focus: #fff;
    --color-menu-top-text-l1-active: #fff;
    --color-menu-top-bg-l1-hover: #260950;
    --color-menu-top-bg-l1-focus: #260950;
    --color-menu-top-bg-l1-active: #260950;
    --color-menu-top-border-l1: #411e75;
    --color-menu-top-border-l1-focus: #aa52ff;
    --color-menu-top-underline-l1: #fff;

    @include breakpoint(not-desktop) {
        position: absolute;
        right: 0;
        transform: translateX(100%);
        transition: all var(--transition-time);
        z-index: z-index(burger-menu);
        overflow-y: auto;
        overflow-x: hidden;
        padding-bottom: 60px;
        display: none;
    }

    @include breakpoint(tablet) {
        height: calc(100vh - 102px);
        padding-bottom: 80px;
        top: 54px;
    }

    @include breakpoint(mobile) {
        height: 100vh;
        padding-bottom: 200px;
        display: flex;
        flex-direction: column;
        top: 56px;
    }

    &.active {
        visibility: visible;
        transition: all var(--transition-time);
        transform: translateX(0);
        display: block;
    }

    @include breakpoint(tablet) {
        width: var(--menu-hamburger-tb-width);
        box-sizing: content-box;
    }

    @include breakpoint(mobile) {
        width: var(--menu-hamburger-mb-width);
    }

    .btn-menu {
        display: block;
        margin-right: 20px;
        margin-top: 20px;
        right: 0;

        @include breakpoint(mobile) {
            margin-left: 20px;
        }
    }
}

.page-hamburger-secondary-menu {
    ul {
        list-style: none;
        padding-left: 0;
        margin-top: 0;
        margin-bottom: 0;
        border-left: 1px solid var(--color-top-menu-border);
        border-right: 1px solid var(--color-top-menu-border);
    }

    .level1 {
        a {
            display: block;
            text-decoration: none;
            flex: 1 0 0;
            position: relative;

            &:focus,
            &:focus-visible {
                outline: 0;
            }
        }

        > a {
            background: var(--color-menu-top-bg-l1);
            color: var(--color-menu-top-text-l1);
            @include font-size(16px, 22px);
            @include font(barlow, medium);
            padding: 16px 18px;
            border-bottom: 1px solid var(--color-menu-top-border-l1);

            span {
                position: relative;

                &:after {
                    content: "";
                    display: block;
                    left: 0;
                    width: 100%;
                    height: 1px;
                    background: var(--color-menu-top-underline-l1);
                    position: absolute;
                    top: 100%;
                    transform: scaleX(0);
                    transition: transform 0.2s;
                    transform-origin: left;
                }
            }

            &:hover {
                background: var(--color-menu-top-bg-l1-hover);
                color: var(--color-menu-top-text-l1-hover);
            }

            &:hover,
            &:focus-visible {
                &:before {
                    width: 4px;
                }
            }

            &:focus-visible {
                background-color: var(--color-menu-top-bg-l1-focus);
                color: var(--color-menu-top-text-l1-focus);
                &:after {
                    content: "";
                    height: 100%;
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: -1px;
                    border: 1px solid var(--color-menu-top-border-l1-focus);
                    z-index: 1;
                }
            }

            &:active {
                &:before {
                    width: 2px;
                }
            }

            &:before {
                content: "";
                width: 0;
                height: 100%;
                position: absolute;
                left: 0;
                top: 0;
                background-color: var(--color-menu-top-line-l1);
                transition: width var(--transition-time);
                z-index: 1;
                border-radius: 0;
            }
        }

        &.current > a {
            color: var(--color-menu-top-text-l1-active);
            background-color: var(--color-menu-top-bg-l1-active);

            &:before {
                width: 6px;
            }

            span:after {
                transform: scaleX(1);
            }
        }
    }
}

.page-hamburger-main-menu {
    @include breakpoint(desktop) {
        display: none;
    }

    @include breakpoint(not-desktop) {
        display: block;

        .top-menu + .menu {
            margin-top: 0;
        }
    }

    .menu-dropdown {
        background-color: var(--color-main-menu-bg);
        border-bottom-left-radius: 8px;
    }

    .level1 {
        > ul {
            @include breakpoint(tablet) {
                margin-right: 10px;
            }
        }

        &.last {
            > a {
                border-bottom: 0;
            }

            > .icon-indicator {
                border-bottom: 0;
            }
        }
    }

    @include breakpoint(mobile) {
        a {
            padding-left: 39px;
        }

        .level2 {
            a {
                padding-left: 39px;
            }
        }

        .level3 {
            a {
                padding-left: 39px;
            }
        }
    }

    .icon-indicator {
        @include breakpoint(desktop) {
            display: none;
        }
    }
}

.drawer-open {
    .page-head-hamburger-menu {
        display: none;
    }
}
