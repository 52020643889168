/*
==========================================================================
   #Page
========================================================================== */

html {
    @include font(barlow, reg);
    @include font-size(16px, 22px);
    color: var(--base-text-color);
    text-size-adjust: 100%;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
}

body {
    margin: 0;
    padding: 0;
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
}
