.block-tabs {
    --color-block-bg: #dcdff3;
    --color-heading: #411e75;
    --color-dot: #0a1dc9;
    --color-tab-border: #411e75;
    --color-tab: #411e75;
    --color-tab-active: #90278e;
    --color-tab-hover: #ad3192;
    --spacing-heading: 24px;

    @include breakpoint(mobile) {
        --spacing-heading: 18px;
    }
    margin-bottom: 64px;
    margin-top: 100px;

    &__wrapper {
        background-color: var(--color-block-bg);
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        border-bottom-right-radius: 8px;

        @include breakpoint(desktop) {
            padding-left: 0;
            display: flex;
        }
    }

    .wrapper {
        @include breakpoint(tablet) {
            padding-right: 0;
        }
        @include breakpoint(mobile) {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &__heading {
        margin-bottom: var(--spacing-heading);
        margin-top: 0;
        @include font-size(50px, 52px);
        color: var(--color-heading);

        @include breakpoint(tablet) {
            @include font-size(36px, 36px);
        }

        @include breakpoint(mobile) {
            @include font-size(25px, 30px);
        }
    }

    &__container {
        flex-shrink: 0;
        padding: 64px 29px 64px 64px;
        @include breakpoint(desktop) {
            width: 800px;
        }

        @include breakpoint(tablet) {
            padding-top: 8px;
            padding-bottom: 66px;
            padding-right: 64px;
        }

        @include breakpoint(mobile) {
            padding: 2px 24px 29px;
        }
    }

    &__image {
        flex-shrink: 0;
        margin-top: -24px;
        @include breakpoint(desktop) {
            width: 589px;
            height: 560px;
        }

        @include breakpoint(not-desktop) {
            margin-left: auto;
            margin-top: 0;
        }

        @include breakpoint(tablet) {
            width: 704px;
            height: 444px;
        }

        @include breakpoint(mobile) {
            width: calc(100% - 25px);
            height: 269px;
        }

        img {
            height: calc(100% + 48px);
            width: 100%;
            object-fit: cover;
            border-top-left-radius: 13px;
            border-bottom-left-radius: 300px;

            @include breakpoint(not-desktop) {
                height: 100%;
            }

            @include breakpoint(tablet) {
                margin-top: -24px;
            }

            @include breakpoint(mobile) {
                margin-top: -30px;
                border-bottom-left-radius: 100px;
            }
        }
    }

    .tab {
        &__introtext {
            margin-bottom: 24px;

            @include breakpoint(mobile) {
                margin-bottom: 16px;
            }
        }

        &__btn {
            @include breakpoint(mobile) {
                width: 100%;
            }
        }
    }

    .tabs-container {
        position: relative;
    }

    .tablist {
        display: flex;
        align-items: center;
        margin-bottom: 24px;
        padding-bottom: 22px;
        border-bottom: 2px solid var(--color-tab-border);

        @include breakpoint(mobile) {
            margin-right: calc(-1 * var(--wrapper-padding-h));
            padding-bottom: 0;
            border-bottom: 0;
        }

        .slick-track {
            display: flex;
            padding-top: 2px;

            @include breakpoint(not-mobile) {
                border-bottom: 5px solid transparent;
            }

            @include breakpoint(mobile) {
                border-bottom: 2px solid var(--color-tab-border);
            }
        }

        .slick-slide {
            display: block;
            float: none;
        }

        .slick-list {
            display: flex;
        }

        .tab-button {
            background-color: transparent;
            color: var(--color-tab);
            position: static;
            z-index: 1;
            border: 0;
            padding: 0 13px 4px;
            width: 20%;
            margin-bottom: 0;
            margin-right: 0;
            @include font-size(18px, 25px);
            border-radius: 2px;

            @include breakpoint(tablet) {
                padding-left: 14px;
                padding-right: 14px;
            }

            @include breakpoint(mobile) {
                position: relative;
                padding-bottom: 22px;
                width: 130px;
                padding-left: 2px;
                padding-right: 2px;
                line-height: 1;
                margin-right: 13px;
            }

            &:hover {
                color: var(--color-tab-hover);
            }

            &:not(:focus-visible) {
                outline: 0;
            }

            &[aria-selected="true"] {
                color: var(--color-tab-active);
                @include font(barlow, semibold);

                &:after {
                    @include breakpoint(mobile) {
                        content: "";
                        position: absolute;
                        left: 50%;
                        bottom: -2px;
                        height: 19px;
                        width: 44px;
                        background-image: url("./../icons/tab-arrow.svg");
                        transform: translateX(-50%);
                    }
                }
            }
        }
    }

    .tab-indicator {
        position: absolute;
        left: calc(100% / (var(--tab-count) * 2) - 22px);
        bottom: 0;
        height: 19px;
        width: 44px;
        background-image: url("./../icons/tab-arrow.svg");
        transition: left 1000ms cubic-bezier(0, 0, 0.33, 0.99);
        transition-timing-function: cubic-bezier(0, 0, 0.33, 0.99);

        @include breakpoint(mobile) {
            display: none;
        }
    }

    .tab-panel {
        &:focus {
            outline: 0;
        }

        &:focus-visible {
            outline: 1px solid var(--color-focus);
            outline-offset: 2px;
        }
    }

    .slider-pagination {
        margin-top: 32px;
    }
}
