.menu-side {
    --color-title-text: #fff;
    --color-title-bg: #411e75;
    --color-side-menu-border: #411e75;
    margin-bottom: 32px;

    .level2 {
        display: none;

        &.active {
            display: flex;
        }

        > .icon-indicator {
            display: none;
        }
        &.current > a,
        > a {
            color: var(--color-title-text);
            background-color: var(--color-title-bg);
            pointer-events: none;
            border-top-right-radius: 8px;
            border-top-left-radius: 2px;
            @include font(barlow, medium);
            @include font-size(18px, 25px);
            padding: 14.5px 18px;

            &:before {
                display: none;
            }

            span {
                &:after {
                    display: none;
                }
            }
        }

        > ul {
            border: 1px solid var(--color-side-menu-border);
            border-bottom: 0;
            border-bottom-right-radius: 8px;
        }
    }

    .level3.last {
        &:not(.parent) {
            > a {
                border-bottom-right-radius: 8px;
            }
        }

        &.parent {
            > .icon-indicator:not(.open) {
                border-bottom-right-radius: 8px;
            }
        }
    }
}
