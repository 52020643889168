/*
==========================================================================
   #Default
========================================================================== */
// #High-level base settings
:root {
    --base-font-size: 16px;
    --base-line-height: 22px;
    --base-text-color: #000;
    --base-background-color: #fff;
}
