.site-header__search {
    --color-btn: #fff;
    --color-btn-hover: #260950;
    --color-btn-bg-hover: #d995d7;
    --color-btn-border: #d995d7;
    --color-input-text: #fff;
    --color-input-border-hover: #fff;
    --color-input-border-focus: #d995d7;

    @include breakpoint(desktop) {
        position: relative;
        margin-left: 16px;
        padding-left: 32px;
        &:before {
            content: "";
            height: 26px;
            background-color: var(--color-white);
            width: 1px;
            display: inline-block;
            position: absolute;
            left: 0;
            top: 2px;
        }
    }

    @include breakpoint(tablet) {
        transform: translateX(0);
        transition: transform var(--transition-time);
    }

    @include breakpoint(mobile) {
        margin-right: 11px;
    }

    &:hover,
    &.expanded {
        @include breakpoint(tablet) {
            transform: translateX(-17px);
        }

        .js-search-query {
            width: 166px;
            color: var(--color-input-text);
            border-color: var(--color-input-border-hover);

            @include breakpoint(mobile) {
                width: 142px;
            }

            &:focus {
                border-color: var(--color-input-border-focus);
                outline-width: 1px;
            }
        }

        .btn {
            border-color: var(--color-btn-border);
            padding-left: 12px;

            &:before {
                display: none;
            }

            &:after {
                display: none;
            }
        }
    }

    .search__wrapper {
        display: flex;
    }

    .js-search-query {
        width: 20px;
        background-image: url("./../icons/search.svg");
        background-repeat: no-repeat;
        background-size: 14px 14px;
        background-position: left 5px center;
        background-color: transparent;
        padding: 4px 0 4px 26px;
        @include font-size(14px, 18px);
        transition: width var(--transition-time-slow);
        border: 0;
        border-bottom: 1px solid transparent;
        border-radius: 0;
        color: transparent;

        @include breakpoint(mobile) {
            padding-left: 17px;
            background-size: 12px;
            background-position: 0;
            @include font-size(16px, 18px);
        }

        &[type="text"]:hover {
            background-color: transparent;
        }

        &:focus {
            border-width: 1px;
            outline-offset: 1px;
            background-image: url("./../icons/search-magneto.svg");
        }
    }

    .btn {
        background: none;
        @include font-size(14px, 21px);
        @include font(barlow, medium);
        color: var(--color-btn);
        padding: 3px 12px 3px 0;
        position: relative;
        border-radius: 0 100px 100px 0;
        border: 1px solid transparent;
        margin-left: 8px;
        transition: padding-left var(--transition-time-slow);

        @include breakpoint(mobile) {
            display: none;
        }

        &:hover,
        &:focus,
        &:active {
            background-color: var(--color-btn-bg-hover);
            color: var(--color-btn-hover);
            padding-left: 12px;
        }

        &:focus {
            outline-offset: 1px;
            &:after {
                display: none;
            }

            &:before {
                border: 0;
            }
        }
    }
}
