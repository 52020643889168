.block-internal-banner {
    --color-banner-heading: #fff;
    --color-banner-desc: #fff;
    background-image: url("./../img/internal-banner-bg.png");
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    padding-top: 56px;
    padding-bottom: 52px;
    position: relative;

    @include breakpoint(tablet) {
        padding-bottom: 30px;
    }

    @include breakpoint(mobile) {
        padding-bottom: 19px;
    }

    &.has-image {
        padding-top: 85px;
        padding-bottom: 88px;

        @include breakpoint(tablet) {
            padding-bottom: 60px;
            padding-top: 70px;
            min-height: 263px;
        }

        @include breakpoint(mobile) {
            display: flex;
            flex-direction: column;
            padding-top: 27px;
            padding-bottom: 17px;
        }
    }

    &__text-wrapper {
        max-width: 800px;

        @include breakpoint(tablet) {
            max-width: 520px;
        }

        &.has-image {
            max-width: 545px;

            @include breakpoint(tablet) {
                max-width: 356px;
            }
        }
    }

    &__icon {
        width: 31px;
        margin-right: 20px;

        @include breakpoint(mobile) {
            display: none;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        margin-bottom: 17px;
    }

    &__heading {
        color: var(--color-banner-heading);
        margin-bottom: 0;
    }

    &__introtext {
        color: var(--color-banner-desc);
        @include font-size(22px, 24px);

        @include breakpoint(tablet) {
            @include font-size(18px, 20px);
        }

        @include breakpoint(mobile) {
            @include font-size(16px, 19px);
        }
    }

    &__img {
        position: absolute;
        top: 19px;
        right: 0;
        width: 692px;
        height: 275px;
        max-width: calc(100vw - ((100vw - 1200px) / 2) - 545px - 162px);

        @include breakpoint(tablet) {
            width: 345px;
            height: 260px;
            max-width: none;
        }

        @include breakpoint(mobile) {
            position: relative;
            width: 296px;
            height: 244px;
            top: 0;
            left: calc(100vw - 296px);
            order: -1;
            max-width: none;
            margin-bottom: 33px;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            border-top-left-radius: 13px;
            border-bottom-left-radius: 200px;
        }
    }
}
