.djslider-slider {
    .djslider {
        --color-desc: #130527;
        --color-bg: #babfe2;
        height: auto !important;
        max-width: none !important;
        position: relative;
        background-color: var(--color-bg);
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;

        .slider-container {
            position: relative;
        }

        .dj-image {
            width: 100%;
            height: 472px !important;
            object-fit: cover;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;

            @include breakpoint(mobile) {
                height: 178px !important;
            }
        }

        li {
            height: 100% !important;
        }

        .slide-desc {
            position: relative;
            padding-left: 32px;
            box-sizing: border-box;

            @include breakpoint(not-mobile) {
               min-height: 78px;
            }

            @include breakpoint(mobile) {
                padding-left: 16px;
                padding-right: 16px;
            }
        }

        .slide-desc-in {
            width: 404px !important;

            @include breakpoint(mobile) {
                width: 100% !important;
            }
        }

        .slide-desc-text-default,
        .slide-desc-bg-default {
            background-color: transparent;
            padding-left: 0;
        }

        .slide-title {
            margin-bottom: 16px;
        }

        .slide-title,
        .slide-text {
            color: var(--color-desc);
            @include font-size(16px, 22px);
            @include font(barlow, reg);
        }

        .navigation-container {
            position: absolute;
            top: 488px !important;
            right: 32px;
            margin: 0 !important;
            background-color: var(--color-desc-bg);

            @include breakpoint(mobile) {
                position: relative;
                top: auto !important;
                right: auto;
                padding-bottom: 16px;
                width: 240px;
                margin-left: auto !important;
                margin-right: auto !important;
            }

            img.prev-button,
            img.next-button {
                position: relative;
            }
        }

        .slider-counter {
            margin-left: 16px;
            margin-right: 16px;

            @include breakpoint(mobile) {
                margin-left: 11px;
                margin-right: 11px;
            }

            .load-button {
                border: 0;
                background-color: transparent;
                &:not(.load-button-active) {
                    display: none;
                }
            }
        }

        .btn-back,
        .btn-next {
            @include breakpoint(mobile) {
                min-width: 75px;
                background-position: center center;
                background-size: 9px 18px;
            }

            span {
                @include breakpoint(mobile) {
                    display: none;
                }
            }

            &:hover {
                @include breakpoint(mobile) {
                    background-position: center;
                }
            }
        }
    }
}

// Style the gallery
body.mfp-zoom-out-cur {
    cursor: pointer;
}
.module-gallery {
    margin-top: 64px;
    max-width: 776px;
    margin-left: auto;
    margin-right: auto;

    @include breakpoint(not-desktop) {
        margin-top: 48px;
    }

    &__heading {
        @extend %h3;
        margin-bottom: 10px;
        color: var(--color-primary-1);
    }
}

.djslider-gallery {
    .djslider {
        height: auto !important;
        width: auto !important;
        background-color: transparent;
        margin-top: 32px;
        margin-bottom: 40px;

        .slider-container {
            position: relative;
        }
    }
    ul {
        width: 100% !important;
        display: grid;
        grid-template-columns: repeat(6, 1fr);
        grid-gap: 32px 28px;
        grid-gap: 32px;

        @include breakpoint(tablet) {
            grid-gap: 20px;
        }

        @include breakpoint(mobile) {
            grid-gap: 14px 10px;
        }
    }

    li {
        width: 100% !important;
        height: 328px !important;
        margin: 0 !important;
        grid-column: span 2;

        @include breakpoint(tablet) {
            height: 222px !important;
        }

        @include breakpoint(mobile) {
            grid-column: span 3;
            height: 150px !important;
        }

        &:nth-of-type(5n - 1),
        &:nth-of-type(5n) {
            grid-column: span 3;

            @include breakpoint(mobile) {
                grid-column: span 6;
            }
        }

        &:nth-of-type(5n - 1) {
            @include breakpoint(mobile) {
                grid-column: span 3;
            }
        }
    }

    .image-link {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 8px;

        img.dj-image {
            height: 100% !important;
            object-fit: cover;
            border-radius: 8px;
            border: 1px solid transparent !important;
        }

        &:hover {
            img.dj-image {
                border: 1px solid var(--color-primary-2) !important;
                box-shadow: 0 4px 6px rgb(65 30 117 / 20%);
            }
        }

        &:focus-visible {
            outline: 1px solid var(--color-focus);
        }
    }

    .djslider-default ul.djslider-in > li {
        overflow: visible;
        padding: 2px !important;
    }
}

// style the popup
.mfp-bg {
    background: rgba(58, 59, 69, 0.6);
}

.mfp-gallery {
    --color-desc: #130527;
    --color-bg: #babfe2;
    --color-close-bg: #dcdff3;
    --color-close-border: #ad3192;
    --color-close-border-hover: #411e75;
    --color-close-bg-hover: #babfe2;

    .mfp-image-holder .mfp-figure .mfp-close {
        cursor: pointer;
    }

    .mfp-image-holder .mfp-close {
        background-color: var(--color-close-bg);
        font-size: 0;
        line-height: 0;
        width: 32px;
        height: 32px;
        opacity: 1;
        padding-right: 0;
        top: 14px;
        right: 16px;
        cursor: pointer;
        border-radius: 50%;
        border: 1px solid transparent;

        &:before {
            content: "";
            position: absolute;
            width: 21px;
            height: 21px;
            left: 50%;
            top: 50%;
            transform: translate(-50%, -50%);
            background-image: url("./../icons/cross-fandango.svg");
            background-size: 7px;
            background-repeat: no-repeat;
            background-position: center;
            border: 1.5px solid var(--color-close-border);
            border-radius: 50%;
            box-sizing: border-box;
        }

        &:hover,
        &:active,
        &:focus-visible {
            &:before {
                background-image: url("./../icons/cross-violet.svg");
                background-color: var(--color-close-bg-hover);
                border-color: var(--color-close-border-hover);
            }
        }

        &:active {
            &:before {
                border-width: 2px;
            }
        }

        &:focus-visible {
            outline: 1.5px solid var(--color-focus);
            outline-offset: -3px;
        }
    }

    img.mfp-img {
        width: 704px;
        height: 472px;
        object-fit: cover;
        padding: 0;
        border-top-right-radius: 8px;
        border-top-left-radius: 8px;

        @include breakpoint(mobile) {
            width: 271px;
            height: 343px;
        }
    }

    .mfp-container {
        width: 704px;
        height: auto;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        padding-left: 0;
        padding-right: 0;

        @include breakpoint(mobile) {
            width: 271px;
        }
    }

    .mfp-bottom-bar {
        background-color: var(--color-bg);
        margin-top: 0;
        position: relative;
        min-height: 120px;
        padding-top: 16px;
        padding-left: 32px;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;

        @include breakpoint(mobile) {
            padding-bottom: 88px;
        }

        .mfp-title,
        small {
            color: var(--color-desc);
            @include font-size(16px, 22px);
            @include font(barlow, reg);
            padding-right: 0;
            @include breakpoint(not-mobile) {
                max-width: 332px;
            }
        }

        p {
            margin-bottom: 0;
        }
    }

    .mfp-counter {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 65.32px;
        height: 42px;
        top: 36px;
        right: 145px;
        color: var(--color-primary-1);
        @include font-size(20px, 30px);

        @include breakpoint(mobile) {
            width: 80px;
            top: auto;
            bottom: 19px;
            left: 50%;
            transform: translateX(-50%);
        }
    }

    .mfp-arrow {
        top: auto;
        bottom: 36px;
        width: 98px;
        height: 48px;
        margin-top: 0;
        opacity: 1;

        @include breakpoint(mobile) {
            width: 78px;
            bottom: 16px;
            background-position: center;
        }

        &:after {
            display: none;
        }

        &:before {
            border: 0;
            opacity: 1;
            margin-top: 0;
            margin-left: 0;
            padding-top: 10px;
        }
    }

    .mfp-arrow-left {
        @extend .btn-back;
        right: 226px;
        left: auto;

        @include breakpoint(mobile) {
            left: 18px;
        }

        &:before {
            @include breakpoint(not-mobile) {
                content: "Back";
            }
            padding-left: 46px;
        }
    }

    .mfp-arrow-right {
        @extend .btn-next;
        right: 32px;

        @include breakpoint(mobile) {
            right: 18px;
        }

        &:before {
            @include breakpoint(not-mobile) {
                content: "Next";
            }
            padding-left: 16px;
        }
    }
}
