/*
==========================================================================
   #Emergency Contact Block - using custom HTML
========================================================================== */
.block-emergency-contact {
    --color-block-bg: #411e75;
    --color-block-text: #fff;
    background-color: var(--color-block-bg);
    color: var(--color-block-text);
    @include font-size(16px, 22.4px);
    padding: 18px 0;

    p {
        @extend %wrapper;
        position: relative;
        padding-left: 35px;

        @include breakpoint(tablet) {
            padding: 0 30px 0 65px;
        }
        @include breakpoint(mobile) {
            padding: 0 24px 0 59px;
        }
    }

    p:first-child {
        &:before {
            content: url("./../icons/warning-contact.svg");
            width: 24px;
            height: 24px;
            vertical-align: middle;
            position: absolute;
            left: 8px;

            @include breakpoint(tablet) {
                left: 32px;
            }
            @include breakpoint(mobile) {
                left: 26px;
            }
        }
    }
}
